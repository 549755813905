(function () {
    'use strict';

    angular
        .module('agentPortal')
        .factory('authenticationNavigationService', authenticationNavigationService);

    authenticationNavigationService.$inject = ['$rootScope', '$state', 'portalService', 'ambassadorInformationSessionStorage', '$q', 'jwtHelper', 'storage', '$anchorScroll', '$stateParams', 'statePersister'];

    function authenticationNavigationService($rootScope, $state, portalService, ambassadorInformationSessionStorage, $q, jwtHelper, storage, $anchorScroll, $stateParams, statePersister) {
        var service = {
            checkToken: checkToken,
            onStateChangeHandleBack: onStateChangeHandleBack,
            onStateChangeSuccess: onStateChangeSuccess,
            onStateChangeNavigation: onStateChangeNavigation,
            redirectAmbassadorForAction: redirectAmbassadorForAction
        };

        return service;

        /*
            Checks local storage for a jwt token,  validates that the token if found
            is not expired,  authenticates and initializes agent/ambassador if existing.
        */
        function checkToken(event) {
            var deferred = $q.defer();
            var token = localStorage.getItem('idToken');
            if (token) {
                if (!jwtHelper.isTokenExpired(token)) {
                    // The user has a token in local storage and is authenticated
                    initializeAgent(deferred)
                } else {
                    // Token is expired, force login
                    forceLogout(deferred, event);
                }
            } else {
                // There is no token in local storage, they will be directed to login page
                forceLogout(deferred, event);
            }

            return deferred.promise;
        }

        /*
            Clears all data in storage and redirects to the login page.
        */
        function forceLogout(deferred, event) {
            if (event && event.preventDefault) {
                event.preventDefault();
            }

            // get any redirect storage items (in json format)
            var redirectStored = localStorage.getItem('returnUrl');

            // clears all storage items - meant for tokens and such
            storage.clearAll();

            // if a redirect storage item existed, put it back in for login process to use
            if (redirectStored) {
                localStorage.setItem('returnUrl', redirectStored);
            }

            portalService.logout();
            deferred.resolve(false);
            $state.go('login');
        }

        /*
            Initializes an Agent with the portal service
        */
        function initializeAgent(deferred) {
            portalService.initializeAgent().then(function (data) {
                if (portalService.getCurrentAgentIsAmbassador()) {
                    ambassadorInformationSessionStorage.setSession(ambassadorInformationSessionStorage.convertLocationDataIntoAmbassadorInformation({ ambassadorsAgentCode: data.agentCode }));
                }
                deferred.resolve(data);
            });
        }

        /*
               This function handels the preventing going back in the browser if an explicit config param is present, or if it is the receipt page.
        */
        function onStateChangeHandleBack(transition) {
            $rootScope.preventBack = false;

            var toState = transition.to();
            var toParams = transition.params('to');
            var fromState = transition.from();

            if (toState) {
                $rootScope.preventBack = ((toState.data && toState.data.preventBack) || (toParams && toParams.page === 'receipt'));
            }

            if (fromState && fromState.data && fromState.data.preventBack && $rootScope.previousLocation === toState) {
                if (toState.url.indexOf("policies/view") > 0 && $rootScope.previousLocation.url.indexOf("receipt")) {
                    return;
                }

                transition.preventDefault();
                $state.go('dashboard');
            }
        }

        /*
            This function will set any route parameters passed into the Ambassador session storage, set the previous location to the page
            they were coming from, and a call to $anchorScroll.

            `transition` is the object that describes a ui.router transition.
            https://ui-router.github.io/ng1/docs/latest/classes/transition.transition-1.html
        */
        function onStateChangeSuccess(transition) {
            // Save any route params into session storage.
            if (portalService.getCurrentAgentIsAmbassador()) {
                ambassadorInformationSessionStorage.convertLocationDataIntoAmbassadorInformation($stateParams);
            }
            // URL of the page that is being navigated away from
            $rootScope.previousLocation = transition.from();

            /*
                When called, it scrolls to the element related to the specified hash or (if omitted) to the current value of $location.hash(), according to 
                the rules specified in the HTML5 spec.
            */
            $anchorScroll();
        }

        /*
            Routes an ambassador from Sales Force based on the action to an ambassador state.
        */
        function redirectAmbassadorForAction(stateParams) {
            var state;

            // Add more agent routes as work for them is done.
            switch (stateParams.action) {
                case 'purchase':
                    state = 'quickquote';
                    break;
                default:
                    state = 'quickquote';
            }

            $state.go(state, $stateParams);
        }

        /*
            Main entry point for state routing,  on each state change request this will verify the token and route according to roles of
            the authenticated agent.
        */
        function onStateChangeNavigation(transition) {
            var fromParams = transition.params('from');
            var toState = transition.to();

            if (!fromParams) {
                fromParams = { skipTokenCheck: false };
            }

            if (toState && toState.url && toState.data && toState.data.requiresLogin === true) {
                localStorage.setItem('returnUrl', JSON.stringify(toState.url));
            }

            // if it is a login, logout, or request where the token/roles routing have already been validated just return and let
            // the page route as normal.
            if (fromParams.skipTokenCheck || toState.name === 'logout' || toState.name === 'login' || toState.name === 'tramsproducts' || toState.name === 'loginForgot') {
                return;
            }
            else {
                //? event.preventDefault();
            }

            checkToken(transition).finally(finishNavigation);

            function finishNavigation() {

                fromParams.skipTokenCheck = true;

                if (toState.data) {

                    // Remove the State from memory if the route does not request that it is persisted
                    if (!toState.data.persistState) {
                        statePersister.destroy();
                    }

                    // Do not allow agent to type in /admin/policyManagement if they are not qualified to be on page.
                    if (toState.data.canPayWithInvoice && !portalService.getCurrentAgentCanInvoice()) {
                        $state.go('dashboard');
                        return;
                    }
                }

                // If none of the conditions are met and we did not return, route to requested state
                $state.go(toState.name, transition.params('to'));
            }
        };
    }

})();
