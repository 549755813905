
(function () {
    'use strict';

    angular
      .module('components.claims')
      .directive('claimCreate', claimCreate);

    claimCreate.$inject = [];

    function claimCreate() {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/claims/claimcreate.html',
            controller: ClaimCreateController,
            controllerAs: 'createCtrl',
            bindToController: true
        };

        return directive;
    }

    ClaimCreateController.$inject = ['$location', 'policySearch', 'claimconfiguration', 'fnol', 'claim'];

    function ClaimCreateController($location, policySearch, claimConfiguration, fnol, claim) {
        var vm = this;
        vm.configuration = null;
        vm.fnol = null;
        vm.claim = null;

        policySearch.searchSubject.subscribe(function (search) {
            this.search = search;
            claimConfiguration.reset();
        }.bind(vm));

        claimConfiguration.claimConfigurationSubject.subscribe(function (configuration) {
            this.configuration = configuration;
            fnol.reset(this.search);
        }.bind(vm));

        fnol.fnolSubject.subscribe(function (fnol) {
            this.fnol = fnol;
            claim.reset();
        }.bind(vm));

        claim.claimSubject.subscribe(function (claim) {
            this.claim = claim;
        }.bind(vm));
    }

})();
