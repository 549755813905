(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name manageAgentsController
     *
     * # viewAgentsController
     *
     * @description
     * controller for agents view and  on manage agents page
     */
    angular.module('agentPortal')
        .controller('agentDetailController', ['$q', '$filter', '$stateParams', '$timeout', 'agentService', 'settings', 'utilService','portalService', '$scope', 'storage', '$state', 'intentService', agentDetailController]);

    function agentDetailController($q, $filter, $stateParams, $timeout, agentService, settings, utilService, portalService, $scope, storage, $state, intentService) {
        var vm = this;

        vm.agentCode = $stateParams.agentCode;
        vm.title = 'Agent Details';
        vm.dateFormat = '';
        vm.editMode = false;
        vm.agent = {};
        vm.agent.address = {};
        vm.agent.phoneTypes = [{ value: 'phone', displayName: 'Phone' }, { value: 'mobile', displayName: 'Mobile' }]
        vm.agent.otherAddress = { country : 'US' };
        vm.agent.phones = [{phone:"",phoneType:""}];
        vm.currentAgent = {};
        vm.drpAgentCode = null;
        vm.states = [];

        /**
         * @description
         * initialization - loads fresh agent information from server
         */
        function init() {
            portalService.getAgentByInternalId().then(function (agent) {
                vm.currentAgent = agent;

                if (!vm.currentAgent.isSuperUser) {
                    $state.go('dashboard');
                }

                portalService.loadDRPAgentForAgency(vm.currentAgent.agencyCode)
                    .then(function (drpAgentCode) {
                        if (drpAgentCode) {
                            //Setting DRP agent code
                            vm.drpAgentCode = drpAgentCode;
                        }

                        initMasks();

                        if (vm.agentCode) {
                            vm.loadAgent();
                        }
                        else {
                            vm.editMode = true;
                        }

                        portalService.loadStates().then(function (stateData) {
                            vm.states = stateData;
                        });
                    });
            });
        };
        /**
       * @description
       * open expire agent cofirm dialogs..
       */
        vm.expireAgent = function () {
            vm.modalTitle = "Expire Agent";
            vm.yesButtonText = "Yes";
            vm.noButtonText = "No";
            vm.expireAgentMessage = "Expiring an agent removes their ability to log in and sell policies. You can always undo this later. Are you sure you want to remove " + vm.agent.name + "?";
            $('#popupExpireAgent').modal('toggle');
        };

        /**
        * @description
        * expire agent to call apiservice
        */
        vm.confirmExpireAgents = function () {
            if (vm.agentCode) {
                agentService.expireAgent(vm.agentCode).then(function (result) {
                    if (result) {
                        init();
                    }
                });
            }
        };

        /**
         * @description
         * open activate agent cofirm dialogs..
         */
        vm.activateAgent = function () {
            vm.modalTitle = "Reactivate Agent";
            vm.yesButtonText = "Yes";
            vm.noButtonText = "No";
            vm.expireAgentMessage = "Are you sure you want to reactivate " + vm.agent.name + "? This will allow them to log in and sell policies.";
            $('#popupActivateAgent').modal('toggle');
        };

        /**
        * @description
        * activate agent to call apiservice
        */
        vm.confirmReactivateAgents = function () {
            if (vm.agentCode) {
                agentService.activateAgent(vm.agentCode).then(function (result) {
                    if (result) {
                        init();
                    }
                });
            };
        };

        /**
         * @description
         * initalizes mask for phone number field
         */
        function initMasks() {
            vm.phoneMask = settings.masks.phone;
            vm.dateMask = settings.masks.date;
            vm.datePlaceholder = settings.date.displayFormat;
        }

        /**
         * @description
         * returns page title
         */
        vm.pageTitle = function () {
            if (vm.agentCode) {
                if (vm.editMode) {
                    return 'Edit Agent Details';
                }
                else {
                    return 'Agent Details';
                }
            }
            else {
                return 'Add Agent';
            }
        };

        /**
         * @description
         * add other address...
         */
        vm.addOtherAddress = function () {
            vm.agent.otherAddress = {};
        };

        /**
      * @description
      * validate zip code....
      */
        vm.validateZipCode = function (postalCode) {
            if (!postalCode) {
                return false;
            }

            var regexp2 = /^\d{5}$/;
            if (!regexp2.test(postalCode)) {
                $scope.agentForm.otherAddressPostalCode.$setValidity('validZip', false);
                return false;
            }

            $scope.agentForm.otherAddressPostalCode.$setValidity('validZip', true);

            return true;
        };
       
        /**
         * @description
         * sets scope to enable editing of agent's Agent information
         */
        vm.editAgent = function () {
            vm.updatedAgent = angular.copy(vm.agent);

            // datepicker expects a Date obj.
            vm.updatedAgent.birthDate = moment(vm.agent.birthDate).toDate();
            vm.editMode = true;
        };

        /**
         * @description
         * sets up scope to cancel the editing of agent's Agent information
         */
        vm.goBack = function () {
            $state.go('adminManageAgents');
        };

        /**
         * @description
         * sets up scope to cancel the editing of agent's Agent information
         */
        vm.cancelEdit = function () {
            if (vm.agentCode) {
                vm.editMode = false;
            }
            else
            {
                $state.go('adminManageAgents');
            }
            
            intentService.resetIntent();
        };
        /**
       * @description
       * opens the date picker control.
       */
        vm.openDatePicker = function ($event, openFlag) {
            $event.preventDefault();
            $event.stopPropagation();
            vm[openFlag] = true;
        }
        /**
         * @description
         * saves agent's edited Agent information back to the server
         */
        vm.saveAgent = function () {
            intentService.setIntent("Saving Agent Information ...");

            var updatedProfile = angular.copy(vm.updatedAgent);

            // change birthdate from a date to a string.
            updatedProfile.birthDate = !updatedProfile.birthDate ? null : moment(updatedProfile.birthDate).format('YYYY-MM-DD');

            agentService.saveAgentDetail(updatedProfile)
                .then(
                function (agent) { vm.onSaveAgentSuccess(agent); },
                    function (reason) {
                        vm.onSaveAgentError(reason);
                });
        };

        vm.onSaveAgentSuccess = function (agent) {
            intentService.resetIntent();

            if (vm.agent.agentCode) {
                // Successful Update 
                vm.loadAgent();
            }
            else {
                // Successful Create 
                $state.go('adminManageAgentsView', { agentCode: agent.agentCode });
            }
        };

        vm.onSaveAgentError = function (reason) {
            intentService.resetIntent();

            console.warn("Error in saving agent: %o", reason);

            var errMessage = reason && reason.text ? reason.text : 'Failed while trying to save the agent information.';
            utilService.showPopup("Error", errMessage);
        };

        /**
         * @description
         * loads agent's fresh information from the server
         */
        vm.loadAgent = function loadAgent() {
            agentService.getAgent(vm.agentCode).then(function (agent) {
                vm.agent = agent;
                vm.cancelEdit();
            });
        };

        /**
         * @description
         * returns true or false for Edit button disable
         */
        vm.disableEdit = function ()
        {
            if (vm.agent.agentCode == undefined) {
                return true;
            }
            if (vm.drpAgentCode && vm.drpAgentCode.toLowerCase() == vm.agent.agentCode.toLowerCase()) {
                //For DRP Agent
                return true;
            }

            return false;
        }

        /**
         * @description
         * returns true or false for Expire/Reactivate button disable
         */
        vm.disableAction = function () {
            if (vm.agent.agentCode == undefined) {
                return true;
            }

            if (vm.drpAgentCode && vm.drpAgentCode.toLowerCase() == vm.agent.agentCode.toLowerCase()) {
                //For DRP Agent
                return true;
            }

            if (vm.agent.agentCode.toLowerCase() == vm.currentAgent.agentCode.toLowerCase()) {
                //For Current User
                return true;
            }

            return false;
        }
     
        init();
    }
})();