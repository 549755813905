(function () {
    'use strict';

    angular
        .module('app')
        .directive('datePicker', datePicker);

    datePicker.$inject = ['dateFilter'];

    function datePicker(dateFilter) {
        return {
            restrict: 'EAC',
            require: '?ngModel',
            link: function (scope, element, attrs, ctrl) {
                ctrl.$parsers.push(function (viewValue, modelValue) {
                    return dateFilter(viewValue, attrs.uibDatepickerPopup);
                });
            }
        };
    }

})();
