/* global $:false */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .factory('coverageservice', coverageservice);

    coverageservice.$inject = [];

    function coverageservice() {
        var service = {
            toggleCoverageInList: toggleCoverageInList,
            getCoveragePremium: getCoveragePremium,
            reconcileData: reconcileData,
            sortByName: sortByName,
            sumOptionalPremiums: sumOptionalPremiums,
            toggleCoverageAndCalculatePremiumChange: toggleCoverageAndCalculatePremiumChange
        };
        return service;

        function toggleCoverageInList(coverage, list) {
            var index = -1;
            for (var i = 0; i < list.length; i++) {
                if (list[i].ratingId === coverage.ratingId) {
                    list[i].selected = coverage.selected;
                    list[i].selectedLimit = coverage.selectedLimit;
                    index = i;
                    break;
                }
            }

            if (index === -1) {
                list.push(coverage);
            }
        }

        function getCoveragePremium(coverage) {
            if (coverage.premiumsByDay && !$.isEmptyObject(coverage.premiumsByDay)) {
                return 'Varies by Dates';
            }
            else if (coverage.premiumsByLimit && !$.isEmptyObject(coverage.premiumsByLimit)) {
                return 'Varies by Limit';
            }
            else {
                return '+$' + coverage.premium;
            }
        }

        function reconcileData(newCoverages, oldCoverages) {
            var coveragesToAdd = [];
            if (oldCoverages) {
                for (var j = 0; j < oldCoverages.length; j++) {
                    var hasSameCoverage = false;
                    for (var i = 0; i < newCoverages.length; i++) {
                        if (newCoverages[i].ratingId === oldCoverages[j].ratingId) {
                            // copy data to new coverage
                            newCoverages[i].selected = oldCoverages[j].selected;
                            newCoverages[i].selectedLimit = oldCoverages[j].selectedLimit;
                            newCoverages[i].showUpgradeDescription = oldCoverages[j].showUpgradeDescription;
                            newCoverages[i].showDescription = oldCoverages[j].showDescription;
                            newCoverages[i].previousLimit = oldCoverages[j].previousLimit;
                            newCoverages[i].daySpan = oldCoverages[j].daySpan;
                            hasSameCoverage = true;
                            break;
                        }
                    }

                    if (!hasSameCoverage) {
                        coveragesToAdd.push(oldCoverages[j]);
                    }
                }
            }

            for (var k = 0; k < coveragesToAdd.length; k++) {
                newCoverages.push(coveragesToAdd[k]);
            }
        }

        function sumOptionalPremiums(coverages) {
            var optionalAmount = 0;
            if (coverages) {
                for (var i = 0; i < coverages.length; i++) {
                    var coverage = coverages[i];
                    if (coverage.selected) {
                        if (coverage.daySpan) {
                            optionalAmount += coverage.premiumsByDay[coverage.daySpan];
                        }
                        else if (coverage.selectedLimit && coverage.premiumsByLimit &&
                            Object.keys(coverage.premiumsByLimit).length > 0) {

                            optionalAmount += coverage.premiumsByLimit[coverage.selectedLimit];
                        }
                        else {
                            optionalAmount += coverage.premium;
                        }
                    }
                }
            }

            return optionalAmount;
        }

        function sortByName(coverages) {
            if (coverages) {
                coverages.sort(function (a, b) {
                    if (a.name !== b.name) {
                        if (a.name > b.name) { return 1; }
                        if (a.name < b.name) { return -1; }
                    }
                    return 0;
                });
            }
        }

        function toggleCoverageAndCalculatePremiumChange(coverage, coverages, fieldValueChanged,
            dataValid, rentalCarPickupDate, rentalCarReturnDate) {
            if (!fieldValueChanged) {
                coverage.selected = !coverage.selected;
            }

            if (coverage.ratingId === 'CRCC') {
                if (dataValid && rentalCarPickupDate && rentalCarReturnDate &&
                    coverage.premiumsByDay && !$.isEmptyObject(coverage.premiumsByDay)) {
                    return calculateCarRentalDays(coverage, coverages, rentalCarPickupDate, rentalCarReturnDate);
                }

                return 0;
            }
            else {
                return adjustOptionalPremium(coverage, coverages);
            }
        }

        function calculateCarRentalDays(coverage, coverages, rentalCarPickupDate, rentalCarReturnDate) {
            var pickup = new Date(rentalCarPickupDate);
            var returnDate = new Date(rentalCarReturnDate);
            var oneDay = 24 * 60 * 60 * 1000;
            var diffDays = Math.round(Math.abs((returnDate.getTime() - pickup.getTime()) / (oneDay))) + 1;
            var adjustedAmount = 0;

            if (coverage.daySpan) {
                adjustedAmount -= coverage.premiumsByDay[coverage.daySpan];
            }

            if (coverage.selected) {
                coverage.daySpan = diffDays;
                adjustedAmount += coverage.premiumsByDay[diffDays];
                toggleCoverageInList(coverage, coverages);
            }
            else {
                toggleCoverageInList(coverage, coverages);
                coverage.daySpan = 0;
            }

            return adjustedAmount;
        }

        function adjustOptionalPremium(coverage, coverages) {
            var coverageHasLimits = coverage.limits && coverage.limits.length > 1;
            var adjustedAmount = 0;

            // optional coverage with no extra requirements was selected. Update the premium and sidebar
            if (coverage.selected) {
                if (coverage.previousLimit !== coverage.selectedLimit) {
                    adjustedAmount -= coverageHasLimits ?
                    coverage.premiumsByLimit[coverage.previousLimit] : coverage.premium;
                }

                adjustedAmount += coverageHasLimits ?
                    coverage.premiumsByLimit[coverage.selectedLimit] : coverage.premium;
                toggleCoverageInList(coverage, coverages);
            }
            else {
                adjustedAmount -= coverageHasLimits ?
                    coverage.premiumsByLimit[coverage.previousLimit] : coverage.premium;
                toggleCoverageInList(coverage, coverages);
            }

            if (coverageHasLimits) {
                coverage.previousLimit = coverage.selectedLimit;
            }

            return adjustedAmount;
        }
    }
})();
