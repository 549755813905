(function () {
    'use strict';

    angular
        .module('app')
        .directive('policyBuyerForm', policyBuyerForm);

    policyBuyerForm.$inject = [];

    function policyBuyerForm() {
        return {
            templateUrl: 'app/vacationguard/directives/policyBuyerForm.html',
            restrict: 'E',
            scope: {
                form: '=',
                traveler: '=',
                fulfillmentMethod: '='
            },
            link: function (scope, elem, attrs, ctrl) {
                if (!scope.traveler) {
                    scope.traveler = {
                        address: { }
                    };
                }

                scope.onNoEmailAddressChange = function onNoEmailAddressChange(noEmailAddress) {
                    if (noEmailAddress) {
                        scope.traveler.email = null;
                    }
                };
            }
        };
    }

})();
