(function () {
    'use strict';

    angular
        .module('app.components')
        .directive('claimFnol', claimFnol)
        .controller('ModalInstanceCtrl', ['$uibModalInstance', 'content', function ($modalInstance, content) {
            var vm = this;
            this.content = content;

            vm.ok = function () {
                $modalInstance.close(true);
            };

            vm.cancel = function () {
                $modalInstance.dismiss('cancel');
            };
        }]);

    claimFnol.$inject = ['$parse', 'moment'];
    function claimFnol($parse, moment) {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/claims/claimfnol.html',
            bindToController: true,
            controllerAs: 'fnolCtrl',
            controller: FnolController,
            link: link
        };
        return directive;

        function link(scope, element, attrs) {
            var fnolCtrl = scope.fnolCtrl;

            scope.$watch(function () {
                return attrs.bhFnol;
            }, function (fnol) {
                fnolCtrl.fnol = $parse(fnol)(scope);

                scope.fnol.$setUntouched();
                scope.fnol.$setPristine();
            }, true);

            scope.$watch(function () {
                return attrs.bhConfiguration;
            }, function (configuration) {
                fnolCtrl.configuration = $parse(configuration)(scope);

                if (fnolCtrl.configuration) {
                    // Set min max dates for date of loss picker
                    fnolCtrl.datePickers.dateOfLoss.options = {
                        maxDate: new Date(fnolCtrl.configuration.expirationDate),
                        minDate: new Date(fnolCtrl.configuration.effectiveDate),
                        initDate: new Date(fnolCtrl.configuration.effectiveDate)
                    };

                    if (fnolCtrl.configuration.coverages && fnolCtrl.configuration.coverages.length === 1) {
                        fnolCtrl.fnol.coverage = fnolCtrl.configuration.coverages[0].coverageId;
                    } else {
                        fnolCtrl.fnol.coverage = '';
                    }
                }
            }, true);

            scope.$watch('fnolCtrl.fnol.coverage', function (coverageId) {
                if (coverageId) {
                    var coverage = fnolCtrl.configuration.coverages[coverageId];

                    if (coverage.riskGroups && coverage.riskGroups.length === 1) {
                        fnolCtrl.riskGroup = coverage.riskGroups[0];
                    }

                    if (coverage.risks && coverage.risks.length === 1) {
                        fnolCtrl.fnol.risk = coverage.risks[0].riskId;
                    }

                    if (coverage.coveredTravelers && coverage.coveredTravelers.length === 1) {
                        coverage.coveredTravelers[0].selected = true;
                    }
                }
            });

            scope.$watch('filteredRisks', function (risks) {
                if (risks && risks.length === 1) {
                    fnolCtrl.fnol.risk = risks[0].riskId;
                } else {
                    fnolCtrl.fnol.risk = '';
                }
            });

            scope.$watch('fnolCtrl.fnol.risk', function (riskId) {
                fnolCtrl.requiredFields = [];

                if (fnolCtrl.fnol.coverage) {
                    fnolCtrl.requiredFields = fnolCtrl.configuration.coverages[fnolCtrl.fnol.coverage].risks
                        .filter(function (risk) {
                            return risk.riskId === riskId;
                        })
                        .map(function (risk) {
                            return risk.requiredFields;
                        })[0]
                        .map(function (field) {
                            return field.replace(/ /gi, '_').toLowerCase();
                        });
                }
            });
        }
    }

    FnolController.$inject = ['$uibModal', '$state', 'filterFilter', 'fnol'];
    function FnolController($modal, $state, filter, fnol) {
        var vm = this;

        vm.fnol = {};

        vm.datePickers = {
            dateOfLoss: {
                open: false
            }
        };

        vm.onCountryChanged = onCountryChanged;
        vm.openDatePicker = openDatePicker;
        vm.openFraudWarning = openFraudWarning;
        vm.resetForm = resetForm;
        vm.submitForm = submitForm;

        function onCountryChanged() {
            vm.fnol.stateProvinceOfLoss = null;
            vm.fnol.cityOfLoss = null;
        }

        function openDatePicker($event, source) {
            $event.preventDefault();
            $event.stopPropagation();

            // Keep the current state of the selected date picker so we can toggle it on click
            var isOpen = vm.datePickers[source].open;

            for (var key in vm.datePickers) {
                if (vm.datePickers.hasOwnProperty(key)) {
                    vm.datePickers[key].open = false;
                }
            }

            vm.datePickers[source].open = !isOpen;
        }

        function openFraudWarning(canSubmit) {
            var modalInstance = $modal.open({
                templateUrl: 'app/vacationguard/components/claims/fraudwarning.html',
                controller: 'ModalInstanceCtrl',
                controllerAs: 'fraudCtrl',
                resolve: {
                    content: function () {
                        return vm.configuration.fraudWarning;
                    }
                }
            });

            modalInstance.result.then(function (selectedItem) {
                vm.fnol.fraud = true;

                if (canSubmit) {
                    submitForm(true, vm.fnol.fraud);
                }
            });
        }

        function resetForm(form) {
            fnol.reset();

            vm.fnol.readonly = false;

            // Reset form and inputs
            form.$setUntouched();
            form.$setPristine();
        }

        function submitForm(formValid, fraudRead) {
            if (formValid === true) {
                if (!fraudRead) {
                    openFraudWarning(true);
                    return;
                }

                vm.fnol.creating = true;
                vm.fnol.errorMessage = null;
                vm.fnol.readonly = true;

                vm.fnol.affectedTravelers = vm.configuration.coverages[vm.fnol.coverage].coveredTravelers
                    .filter(function (traveler) {
                        return traveler.selected === true;
                    })
                    .map(function (traveler) {
                        return traveler.travelerId;
                    });

                fnol.set(vm.fnol);

                fnol.submit(vm.fnol)
                    .then(null, function (error) {
                        vm.fnol.errorMessage = error;
                        vm.fnol.readonly = false;
                    })
                    .finally(function () {
                        vm.fnol.creating = false;
                    });
            }
        }
    }

})();
