(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policyTravelers', policyTravelers);

    policyTravelers.$inject = [];
    function policyTravelers() {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/policies/policytravelers.html',
            scope: {
                travelers: '=bhTravelers'
            },
            bindToController: true,
            controllerAs: 'travelersCtrl',
            controller: TravelersController
        };
        return directive;
    }

    TravelersController.$inject = [];
    function TravelersController() {

    }

})();
