(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name customerDetailController
     *
     * # customerDetailController
     *
     * @description
     * controller to support activities on customer details page
     */

    angular.module('agentPortal')
        .controller('customerDetailController', ['$q', '$stateParams', '$state', '$filter', '$uibModal', '$timeout', 'customersService', 'policiesService', 'quotesService', 'utilService', 'settings', 'statePersister', 'portalService', customerDetailController]);

    function customerDetailController($q, $stateParams, $state, $filter, $modal, $timeout, customersService, policiesService, quotesService, utilService, settings, statePersister, portalService) {
        var vm = this;

        vm.customerId = $stateParams.customerId;
        vm.title = 'Customer';
        vm.dateFormat = '';

        vm.customer = {};

        vm.customerPolicies = {};
        vm.customerQuotes = {};
        vm.customerQuotes.selected = [];

        vm.customerPolicies.packageOptions = [];
        vm.customerQuotes.packageOptions = [];

        vm.customerPolicies.dateFilters = [];
        vm.customerQuotes.dateFilters = [];

        vm.customerPolicyCount = null;

        vm.customerPolicies.filteredPackageIds = '';
        vm.customerQuotes.filteredPackageIds = '';

        vm.customerPolicies.filteredDate = 'All';
        vm.customerQuotes.filteredDate = 'All';

        vm.customerPolicies.filteredStatus = '';
        vm.customerQuotes.filteredStatus = '';

        vm.customerPolicies.refreshDataFlag = false;
        vm.customerQuotes.refreshDataFlag = false;

        vm.customerPolicies.reloadDataFlag = false;
        vm.customerQuotes.reloadDataFlag = false;

        let orderByMap = {
            quoteNumber: 'quote_number', 
            primaryTraveler: 'primary_traveler', 
            travelers: 'travelers', 
            product: 'product', 
            quoteDate: 'quote_date', 
            agentName: 'agent_name', 
            destination: 'destination', 
            departureDate: 'departure_date', 
            tripCost: 'trip_cost' 
        }

        /**
         * @description
         * initialization stuff, loading the customer record from service, setting up date formats and filters etc.
         */
        function init() {
            var promises = [];

            promises.push(portalService.getAgentByInternalId().then(function (agent) {
                vm.agent = agent;
                portalService.loadAgencyProductsForDropdown(true).then(function (packageOptions) {
                    vm.customerPolicies.packageOptions = packageOptions;
                    vm.customerQuotes.packageOptions = packageOptions;
                });
            }));

            promises.push(portalService.getPackages().then(function (packages) {
                vm.packages = packages;
            }));

            $q.all(promises).then(function () {
                initializeGrids();

                vm.customerPolicies.dateFilters = policiesService.getDateFilters();
                vm.customerQuotes.dateFilters = quotesService.getDateFilters();

                vm.customerPolicies.statusList = policiesService.getStatusList();

                vm.load(vm.customerId);
                vm.dateFormat = settings.date.format;

                vm.ready = true;
            });
        }

        /**
         * @description
         * retrieves customer by given id ...
         */
        vm.load = function () {
            customersService.getById(vm.customerId).then(function (customer) {
                vm.customer = customer;
                if (vm.customer && vm.customer.gender) {
                    vm.customer.gender = capatilizeFirstLetter(vm.customer.gender);
                }
            });
        };

        /**
         * @description
         * Capatalizes first letter of a string
         */
        function capatilizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }


        /**
         * @description
         * navigates the user to purchase page for the available product packages...
         */
        vm.goToPurchase = function () {
            if (vm.customer.address) {
                statePersister.persist(vm.customer.address.stateOrProvince);
            }
            $state.go('quickquoteCustomer', { customerId: vm.customerId });
        };

        /**
         * @description
         * removes the customer from the agents view...
         */
        vm.removeCustomer = function () {
            customersService.removeCustomer(vm.customerId, 'customers', vm.customer.firstName, vm.customer.lastName);
        };

        /**
         * @description
         * loads customer's policies from the server
         */
        vm.customerPolicies.loadData = function () {
            var dateRange = utilService.getDateRange(vm.customerPolicies.filteredDate);

            var dateSelected;
            if (dateRange != null) {
                dateSelected = {
                    startDate: dateRange.startDate.format('MM/DD/YYYY'),
                    endDate: dateRange.endDate.format('MM/DD/YYYY')
                };
            }
            else {
                dateSelected = {
                    startDate: null,
                    endDate: null
                };
            }
            vm.customerPolicies.gridConfig.dateSelected = dateSelected;
            var deferredPromise = $q.defer();
            var customFilters = vm.customerPolicies.getCustomFilters();

            policiesService.loadPolicySummaries(customFilters[3].value, customFilters[2].value,
                    customFilters[0].value, vm.customerPolicies.gridConfig.currentPage,
                    vm.customerPolicies.gridConfig.orderby, vm.customerPolicies.gridConfig.reverse ? 'asc' : 'desc',
                    dateSelected, vm.customerId, vm.customerPolicies.searchText)
                .then(function (results) {
                        vm.customerPolicies.gridConfig.totalRecords = results.totalRecords || 0;

                        if (results.summaries) {
                            vm.customerPolicyCount = results.totalRecords

                            for (var i = 0; i < results.summaries.length; i++) {
                                var policy = results.summaries[i];
                                policiesService.setPolicyActions(policy, vm.cancelPolicy);
                            }

                            deferredPromise.resolve(results.summaries);
                        }
                    }, function (error) {
                        deferredPromise.reject(error);
                });

            return deferredPromise.promise;
        };

        /**
         * @description
         * loads customer's quotes from the server
         */
        vm.customerQuotes.loadData = function () {
            var dateRange = utilService.getDateRange(vm.customerQuotes.filteredDate);

            var dateSelected;
            if (dateRange != null) {
                dateSelected = {
                    startDate: dateRange.startDate.format('MM/DD/YYYY'),
                    endDate: dateRange.endDate.format('MM/DD/YYYY')
                };
            }
            else {
                dateSelected = {
                    startDate: null,
                    endDate: null
                };
            }
            vm.customerQuotes.gridConfig.dateSelected = dateSelected;
            var deferredPromise = $q.defer();

            var customFilters = vm.customerQuotes.getCustomFilters()

            quotesService.refreshQuoteSummaries(
                customFilters[0].value,
                customFilters[1].value,
                vm.customerQuotes.gridConfig.currentPage,
                orderByMap[vm.customerQuotes.gridConfig.orderby],
                vm.customerQuotes.gridConfig.reverse ? 'desc' : 'asc',
                dateSelected,
                vm.customerId,
                vm.customerQuotes.searchText)
                .then(function (results) {
                        vm.customerQuotes.gridConfig.totalRecords = results.pagination.totalRows || 0;

                        for (var i = 0; i < results.quotes.length; i++) {
                            var quote = results.quotes[i];
                            setQuoteActions(quote);
                        }

                        deferredPromise.resolve(results.quotes);
                    }, function (error) {
                        deferredPromise.reject(error);
                    }
            );

            return deferredPromise.promise;
        };

        /**
         * @description
         * sets actions for quotes grid's rows 
         */
        function setQuoteActions(quote) {
            quote.actions = [];

            var selectedPackage = vm.packages.filter(function (p) {
                return p.ratingId == quote.ratingId;
            })[0];

            quote.actions.push({ label: "Edit", click: vm.customerQuotes.editQuote, icon: "fa fa-pencil-square-o", href: "#" });
            quote.actions.push({ label: "Purchase", click: vm.customerQuotes.editQuote, icon: "fa fa-shopping-cart", href: "#" });

            if (selectedPackage && selectedPackage.canQuoteBeEmailed) {
                quote.actions.push({ label: "Send", click: vm.customerQuotes.sendQuote, icon: "fa fa-paper-plane-o", href: "#" });
            }

            quote.actions.push({ label: "Remove", click: vm.customerQuotes.confirmRemoveQuote, icon: "fa fa-trash-o", href: '#' });
        };

        /**
         * @description
         * reloads customer's quotes from the server
         */
        vm.customerQuotes.reloadData = function () {
            vm.customerQuotes.reloadDataFlag = true;
        };

        /**
         * @description
         * reloads customer's policies from the server
         */
        vm.customerPolicies.reloadData = function () {
            vm.customerPolicies.reloadDataFlag = true;
        };

        /**
        * @description
        * takes to purchase path
        */
        vm.customerQuotes.editQuote = function (quote) {
            // product rating id isn't available on this quote object, so purchaseNavigationService can't
            // be used here
            $state.go("purchasePackageQuote", { ratingId: quote.ratingId, quoteId: quote.quoteNumber });
        }

        /**
         * @description
         * returns filters for policies grid ...
         */
        vm.customerPolicies.getCustomFilters = function () {
            return [
                { key: "packageId", value: vm.customerPolicies.filteredPackageIds },
                { key: "date", value: vm.customerPolicies.filteredDate },
                { key: "status", value: vm.customerPolicies.filteredStatus },
                { key: "agentCode", value: vm.agent.isSuperUser ? (vm.filteredAgent ? vm.filteredAgent.agentCode : null) : vm.agent.agentCode }
            ];
        };

        /**
         * @description
         * returns custom filters for policies/quotes grids ...
         */
        vm.customerQuotes.getCustomFilters = function () {
            return [
                { key: "agentCode", value: vm.agent.agentCode },
                { key: "packageId", value: vm.customerQuotes.filteredPackageIds },
                { key: "date", value: vm.customerQuotes.filteredDate }
            ];
        };

        /**
         * @description
         * refreshes policies grid data i.e., whenever filter selection changes
         */
        vm.customerPolicies.refreshData = function () {
            vm.customerPolicies.refreshDataFlag = true;
        };

        /**
         * @description
         * refreshes quotes grid data i.e., whenever filter selection changes
         */
        vm.customerQuotes.refreshData = function () {
            vm.customerQuotes.refreshDataFlag = true;
        };

        /**
         * @description
         * filter change event for policies grid, basically invokes function to refresh grids 
         */
        vm.customerPolicies.filterChanged = function () {
            vm.customerPolicies.refreshData();
        };

        var timeout;
        vm.customerPolicies.searchChanged = function () {
            if (vm.agent.isSuperUser) {
                if (vm.customerPolicies.timer) {
                    $timeout.cancel(vm.customerPolicies.timer);
                }
                vm.customerPolicies.timer = $timeout(function () {
                    vm.customerPolicies.filterChanged();
                }, 700);
            }
            else {
                vm.customerPolicies.filterChanged();
            }
        }

        /**
         * @description
         * filter change event for quotes grid, basically invokes function to refresh grid
         */
        vm.customerQuotes.filterChanged = function () {
            vm.customerQuotes.refreshData();
        };

        vm.customerQuotes.searchChanged = function () {
            if (vm.agent.isSuperUser) {
                if (vm.customerQuotes.timer) {
                    $timeout.cancel(vm.customerQuotes.timer);
                }
                vm.customerQuotes.timer = $timeout(function () {
                    vm.customerQuotes.filterChanged();
                }, 700);
            }
            else {
                vm.customerQuotes.filterChanged();
            }
        }


        /**
         * @description
         * clears filter on policies grid
         */
        vm.customerPolicies.clearFilter = function () {
            vm.customerPolicies.searchText = "";
            vm.customerPolicies.refreshData();
        };

        /**
         * @description
         * clears filter on quotes grid
         */
        vm.customerQuotes.clearFilter = function () {
            vm.customerQuotes.searchText = "";
            vm.customerQuotes.refreshData();
        };

        /**
         * @description
         * removes selected quotes from the quotes grid by calling backend APIs for soft-delete
         */
        vm.customerQuotes.removeQuotes = function (entityIds) {
            if (entityIds.length == 0)
                return;

            quotesService.removeQuotes(entityIds).then(function () {
                vm.customerQuotes.refreshSelectionToExclude(entityIds);
                vm.customerQuotes.reloadData();
            });
        };

        /**
         * @description
         * refreshes selection to exclude selected (i.e., just removed) quote IDs
         */
        vm.customerQuotes.refreshSelectionToExclude = function (entityIds) {
            var newSelection = [];
            for (var index = 0; index < vm.customerQuotes.selected.length; index++) {
                var current = vm.customerQuotes.selected[index];
                if (entityIds.indexOf(current) === -1)
                    newSelection.push(current);
            }
            vm.customerQuotes.selected = newSelection;
        };

        $('#delconfirm').on('hidden.bs.modal', function (e) {
            vm.customerQuotes.selectedDeleteIds = [];
        });

        /**
         * @description
         * prompts user to confirm prior to removing the selected quote(s)
         */
        vm.customerQuotes.confirmRemoveQuote = function (quote) {
            vm.customerQuotes.selectedDeleteIds = [quote.providerQuoteId];
            $('#delconfirm').modal('toggle');
        };

        /**
         * @description
         * shows email-quote dialog so the quote can be sent to anyone with email ID.
         */
        vm.customerQuotes.sendQuote = function (quote) {
            $modal.open({
                templateUrl: 'app/quotes/sendQuoteModal.html',
                backdrop: true,
                windowClass: 'modal',
                controller: 'sendQuoteController',
                resolve: {
                    quote: function () {
                        return quote;
                    }
                }
            });
        };

        function initializeGrids() {
            /**
         * @description
         * grid configuration for policies grid
         */
            if (vm.agent.isSuperUser) {
                vm.customerPolicies.gridConfig = {
                    noDataMessage: "No policies found",
                    allowMultiSelect: false,
                    hasActions: true,
                    columns: [
                        { header: "Policy Id", binding: "policyNumber", href: 'policies/view/{{row.policyNumber}}', preferredWidth: '10%' },
                        { header: "Primary Traveler", binding: "primaryTravelerName", preferredWidth: '20%' },
                        { header: "Purchase Date", binding: "purchaseDateForDisplay", preferredWidth: '1%' },
                        { header: "Product", binding: "productNameWithSubtitle", preferredWidth: '20%' },
                        { header: "Departure Date", binding: "departureDateForDisplay", preferredWidth: '1%' },
                        { header: "Submission Channel", binding: "submissionChannel", preferredWidth: '1%' },
                        { header: "Agent Name", binding: "agentName", preferredWidth: '10%' },
                        { header: "Status", binding: "status", preferredWidth: '5%' }
                    ],
                    actionList: [
                        { label: "View", icon: "fa fa-eye", href: 'policies/view/{{row.policyNumber}}' }
                    ],
                    defaultOrderBy: "departureDateIso",
                    defaultOrder: false,
                    rowIdentifier: "policyNumber",
                    filter: $filter("policiesFilterPurchaseDate")
                };
            }
            else {
                vm.customerPolicies.gridConfig = {
                    noDataMessage: "No policies found",
                    allowMultiSelect: false,
                    hasActions: true,
                    columns: [
                        { header: "Policy Id", binding: "policyNumber", href: 'policies/view/{{row.policyNumber}}', preferredWidth: '10%' },
                        { header: "Primary Traveler", binding: "primaryTravelerName", preferredWidth: '20%' },
                        { header: "Purchase Date", binding: "purchaseDateForDisplay", preferredWidth: '1%' },
                        { header: "Product", binding: "productNameWithSubtitle", preferredWidth: '20%' },
                        { header: "Departure Date", binding: "departureDateForDisplay", preferredWidth: '1%' },
                        { header: "Submission Channel", binding: "submissionChannel", preferredWidth: '1%' },
                        { header: "Status", binding: "status", preferredWidth: '5%' }
                    ],
                    actionList: [
                        { label: "View", icon: "fa fa-eye", href: 'policies/view/{{row.policyNumber}}' }
                    ],
                    defaultOrderBy: "departureDateIso",
                    defaultOrder: false,
                    rowIdentifier: "policyNumber",
                    filter: $filter("policiesFilterPurchaseDate")
                };
            }

            /**
             * @description
             * grid configuration for quotes grid
             */
            if (vm.agent.isSuperUser) {
                vm.customerQuotes.gridConfig = {
                    noDataMessage: "No quotes found",
                    allowMultiSelect: false,
                    hasActions: true,
                    columns: [
                        { header: "Quote #", binding: "quoteNumber", href: 'quotes/view/{{row.quoteNumber}}', preferredWidth: '10%' },
                        { header: "Product", binding: "product", preferredWidth: '1%' },
                        { header: "Quote Date", binding: "quoteDate", preferredWidth: '1%' },
                        { header: "Departure Date", binding: "departureDate", preferredWidth: '1%' },
                        { header: "Policy Holder", binding: "primaryTraveler" },
                        { header: "Agent", binding: "agentName", preferredWidth: '1%' },
                        { header: "Travelers", binding: "travelers", preferredWidth: '1%' },
                        { header: "Destination", binding: "destination", preferredWidth: '1%' },
                        { header: "Trip Cost", binding: "tripCost", filter: "dollar", isCurrency: true, preferredWidth: '1%' }
                    ],
                    actionList: [
                        { label: "View", icon: "fa fa-eye", href: 'quotes/view/{{row.quoteNumber}}' }
                    ],
                    defaultOrderBy: "departure_date",
                    defaultOrder: false,
                    rowIdentifier: "quoteNumber",
                    filter: $filter("quotesFilter")
                };
            }
            else {
                vm.customerQuotes.gridConfig = {
                    noDataMessage: "No quotes found",
                    allowMultiSelect: false,
                    hasActions: true,
                    columns: [
                        { header: "Quote #", binding: "quoteNumber", href: 'quotes/view/{{row.quoteNumber}}', preferredWidth: '10%' },
                        { header: "Product", binding: "product", preferredWidth: '1%' },
                        { header: "Quote Date", binding: "quoteDate", preferredWidth: '1%' },
                        { header: "Departure Date", binding: "departureDate", preferredWidth: '1%' },
                        { header: "Policy Holder", binding: "primaryTraveler" },
                        { header: "Travelers", binding: "travelers", preferredWidth: '1%' },
                        { header: "Destination", binding: "destination", preferredWidth: '1%' },
                        { header: "Trip Cost", binding: "tripCost", filter: "dollar", isCurrency: true, preferredWidth: '1%' }
                    ],
                    actionList: [
                        { label: "View", icon: "fa fa-eye", href: 'quotes/view/{{row.quoteNumber}}' }
                    ],
                    defaultOrderBy: "departure_date",
                    defaultOrder: false,
                    rowIdentifier: "quoteNumber",
                    filter: $filter("quotesFilter")
                };
            }
        }

        /**
         * @description
         * grid implementation - cancel Policy
         */
        vm.cancelPolicy = function (actions) {
            var policy = actions.policyNumber;
            policiesService.confirmCancelPolicy(
                actions,
                function (result) {
                    vm.customerPolicies.refreshData();
                    vm.customerPolicies.reloadData();
                }
            );
        }

        init();
    }
})();
