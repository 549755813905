(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name quoteDetailController
     *
     * # quoteDetailController
     *
     * @description
     * controller to support interactions for quote details page that shows single quote details
     */
    angular.module('agentPortal')
        .controller('quoteDetailController',
        ['$stateParams', '$location', '$uibModal', 'settings',
            'loadQuoteFactory', 'utilService', 'sendQuoteFactory', 'format',
            'portalService', 'intentService', '$q', 'purchaseNavigationService', 'apiUtilService', '$window', quoteDetailController]);

    function quoteDetailController($stateParams, $location, $modal, settings, loadQuoteFactory, utilService, sendQuoteFactory, format, portalService, intentService, $q, purchaseNavigationService, apiUtilService, $window) {
        var vm = this;

        vm.quoteNumber = $stateParams.quoteNumber;
        vm.title = '';
        vm.dateFormat = '';

        vm.passengers = [];
        vm.coverages = [];
        vm.flights = [];
        vm.agentNotes = [];
        vm.quote = null;
        vm.ratedQuote = null;
        vm.event = null;
        vm.package = null;
        vm.customerId = null;
        vm.totalPrice = null;
        vm.quoteResponse = null;
        // this needs to be totalled for all travelers in the group.
        vm.tripCost = '';

        vm.isAirCare = false;
        vm.showCoverageBreakup = false;
        vm.showQuoteNumber = true;
        vm.isEmailQuote = false;
        vm.destinations = [];

        vm.coverages = {
            all: [],
            included: [],
            optional: []
        };

        vm.agent = portalService.getCurrentAgent();

        function init() {
            vm.title = 'Quote ' + vm.quoteNumber;
            vm.dateFormat = settings.date.format;

            portalService.getPackages().then(function (packages) {
                vm.packages = packages;
                vm.loadQuote();
            })
        }

        /**
        * @description
        * loads quote information by given quote number
        */
        vm.loadQuote = function () {
            loadQuoteFactory.getByNumberRated(vm.quoteNumber)
                .then(setCurrent)
                .catch(setError);
        };

        /**
         * @description
         * re-routes user to editing for the quote on the purchase path
         */
        vm.editQuote = function () {
            loadQuoteFactory.enterPurchasePathWithExistingQuote(vm.package, vm.customerId, vm.quoteNumber, vm.event, vm.ratingId, vm.state, vm.passengers, vm.coverages, vm.flights)
        };
        /**
         * @description
         * performs eligibility check and if succeeds, re-routes user to the purchase path's last step
         * where he can provide payment information to purchase the quote.
         */

         vm.purchaseQuote = function () {
            loadQuoteFactory.enterPurchasePathWithExistingQuote(vm.package, vm.customerId, vm.quoteNumber, vm.event, vm.ratingId, vm.state, vm.passengers, vm.coverages, vm.flights)
        };

        /**
         * @description
         * displays dialog box for sending quote via email
         */
        vm.sendQuote = function (apiResponse) {
            const request = {};
            request.event = apiResponse.event;
            request.email = vm.primaryTraveler.email;
            request.agentName = vm.agent.name;
            request.agentCode = vm.agent.agentCode;
            return sendQuoteFactory.sendQuote(request);
        }

        /**
         * @descriptions
         * utility method for one-spot error handling
         */
        vm.handleError = function (error, message) {
            intentService.resetIntent();
            console.warn(message + " %o", error);

            if (error.eligibilityResults != null) {
                var messageTitle = "Eligibility Check Failed";
                var eligibilityMessage = message;
                var eligibilityResults = error.eligibilityResults;
                for (var i = 0; i < eligibilityResults.length; i++) {
                    eligibilityMessage += " " + eligibilityResults[i].message;
                }
                utilService.showPopup(messageTitle, eligibilityMessage);
            } else if (error.exceptionMessage != null) {
                utilService.showPopup("Error", message + " " + error.exceptionMessage);
            } else if (error.message != null) {
                utilService.showPopup("Error", message + " " + error.message);
            } else {
                utilService.showPopup("Error", " " + message);
            }
        };

        vm.showEmail = function () {
            try {
                return (!vm.quoteError &&
                    !vm.coverageWarning &&
                    vm.quoteResponse &&
                    vm.quoteResponse.package &&
                    vm.quoteResponse.package.availablePlatform &&
                    vm.quoteResponse.package.availablePlatform.includes("Web"));
            }
            catch (err) {
                console.warn("Could not determine showEmail button status");
                return false;
             }
        }

        vm.showPurchase = function () {
            return  (!vm.quoteError && !vm.coverageWarning);
        }

        vm.showEdit = function () {
            return (!vm.quoteError);
        }

        /**
         * @descriptions
         * show travelers if present
         */
        vm.showTravelers = function () {
            return vm.passengers && vm.passengers.length > 0;
        };

        /**
         * @descriptions
         * show agent notes if present
         */
        vm.showNotes = function () {
            return vm.agentNotes && vm.agentNotes.length > 0;
        };

        /**
         * @descriptions
         * show flights if present
         */
        vm.showFlights = function () {
            return vm.flights && vm.flights.length > 0;
        };

        /**
         * @descriptions
         * show travelers and flight section if any present
         */
        vm.showMidSection = function () {
            return vm.showTravelers() || vm.showFlights();
        };

        /**
         * @descriptions
         * show trip cost
         */
        vm.showTripCost = function () {
            return (!vm.isAirCare && vm.quote && vm.tripCost !== '' && vm.tripCost !== null && vm.tripCost !== undefined);
        };

        vm.getRowIndexClass = function (number) {
            return utilService.isOddIndexNumber(number) ? 'odd' : '';
        };

        vm.getDataRowClass = function (acknowledgements) {
            return acknowledgements && acknowledgements.length ? 'view-acknowledgements-data' : '';
        };

        vm.departureDate = function () {
            if (!vm.event || !vm.event.startDate) {
                return null;
            }

            return format.getDisplayDateStringFromIsoString(vm.event.startDate) +
                ' ' + vm.event.startDateTimezone;
        };

        vm.returnDate = function () {
            if (!vm.event || !vm.event.endDate) {
                return null;
            }

            return format.getDisplayDateStringFromIsoString(vm.event.endDate) +
                ' ' + vm.event.endDateTimezone;
        };

        /**
         * @description
         * initializes currently loaded quote for display
         */
        async function setCurrent(result) {
            vm.quoteResponse = result;
            const viewModel = await loadQuoteFactory.getQuoteViewModel(result);
            vm.event = viewModel.event;
            vm.ratedQuote = viewModel.ratedQuote;
            vm.customerId = viewModel.externalCustomerId;
            vm.package = viewModel.package;
            vm.ratingId = viewModel.ratingId;
            vm.passengers = viewModel.passengers;
            vm.state = viewModel.state;
            vm.quote = viewModel.quote;
            vm.primaryTraveler = viewModel.primaryTraveler;
            vm.isAirCare = viewModel.isAirCare;
            vm.isEmailQuote = viewModel.isEmailQuote;
            vm.showCoverageBreakup = viewModel.quote && !viewModel.isAirCare;
            vm.tripCost = viewModel.tripCost;
            vm.totalPrice = viewModel.totalPrice;
            vm.totalFees = viewModel.totalFees;
            vm.flights = viewModel.flights;
            vm.destinations = viewModel.destinations;
            vm.residency = viewModel.residency;
            vm.carRentals = viewModel.carRentals;
            vm.coverages.all = viewModel.coverages.all;
            vm.coverages.optional = viewModel.coverages.optional;
            vm.coverages.included = viewModel.coverages.included;
            vm.coverageWarning = viewModel.coverages.coverageWarning;
            vm.agentNotes = viewModel.agentNotes;
            if (viewModel.coverages.coverageError) {
                setError(viewModel.coverageError);
            }
        }

        /**
         * @description
         * Show Error
         */
        function setError(error) {
            if (error.event) {
                setCurrent(error);
                vm.ratingError = 'The saved quote appears to be incomplete or no longer valid.'
                vm.messages =  error.messages;
            } else {
                vm.quoteError = error.exceptionMessage ? error.exceptionMessage : error;
                utilService.showPopup('Error', vm.quoteError);
            }
        }

        init();
    }
})();
