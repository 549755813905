(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policyFlights', [policyFlights]);

    function policyFlights() {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/policies/policyflights.html',
            scope: {
                flights: '=bhFlights'
            },
            bindToController: true,
            controllerAs: 'flightsCtrl',
            controller: FlightsController
        };
        return directive;
    }

    FlightsController.$inject = [];
    function FlightsController() {
    }

})();
