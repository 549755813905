(function () {
    angular
        .module('vacationguard')
        .config(routeConfig);

    routeConfig.$inject = ['$locationProvider', '$stateProvider', '$urlRouterProvider'];
    function routeConfig($locationProvider, $stateProvider, $urlRouterProvider) {

        $locationProvider.html5Mode(true);

    }

})();
