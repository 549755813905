(function () {
    'use strict';

    angular
        .module('app.core')
        .factory('dataservice', dataservice);

    dataservice.$inject = ['$http', '$q', '$sessionStorage', 'globalSettingsService'];

    function dataservice($http, $q, $sessionStorage, globalSettingsService) {
        var baseApiUrl = globalSettingsService.apiBaseUrl();
        var baseClientsApiUrl = globalSettingsService.clientsApiBaseUrl();

        var service = {
            getPackageStateConfigs: getPackageStateConfigs,
            getCountries: getCountries,
            getStates: getStates,
            getPartnerQuotes: getPartnerQuotes,
            getBhtpQuotes: getBhtpQuotes,
            getSingleQuote: getSingleQuote,
            verifyZip: verifyZip,
            verifyZipWithState: verifyZipWithState,
            getCountryByCode: getCountryByCode,
            purchaseQuote: purchaseQuote,
            getRelationshipTypes: getRelationshipTypes,
            searchPolicy: searchPolicy,
            getAgent: getAgent,
            searchClaimConfiguration: searchClaimConfiguration,
            createSimpleClaim: createSimpleClaim,
            getFraudWarning: getFraudWarning,
            getQuoteByQuoteNumber: getQuoteByQuoteNumber,
            retrieveCityInfo : retrieveCityInfo
        };

        return service;

        function retrieveCityInfo(postalCode, countryIso2, success, error) {
            if(countryIso2) {
                return $http.get(baseClientsApiUrl + '/Eligibility/PostalCodes/' + postalCode + '/cities?countryIso2=' + countryIso2, { cache: true })
                    .then(retrieveCityInfoCompleted)
                    .catch(retrieveCityInfoFailed);
            }else{
                return $http.get(baseClientsApiUrl + '/Eligibility/PostalCodes/' + postalCode + '/cities', { cache: true })
                    .then(getStatesCompleted)
                    .catch(getStatesFailed);
            }

            function retrieveCityInfoCompleted(response) {
                return success(response.data);
            }

            function retrieveCityInfoFailed(error) {
                return error;
                // TODO: Create logger service
            }
        }

        function getCountries() {
            return $http.get(baseApiUrl + '/eligibility/country', { cache: true })
                .then(getCountriesCompleted)
                .catch(getCountriesFailed);

            function getCountriesCompleted(response) {
                var countries = response.data;
                var countryNamesByCode = {};

                // Move the United States to the 1st index
                for (var i = 0; i < countries.length; i++) {
                    countryNamesByCode[countries[i].isoCode2] = countries[i].name;

                    if (countries[i].isoCode2 === 'US') {
                        countries.splice(0, 0, countries.splice(i, 1)[0]);
                    }
                }

                $sessionStorage.countryNamesByCode = countryNamesByCode;

                return countries;
            }

            function getCountriesFailed(error) {
                // TODO: Create logger service
            }
        }

        function getCountryByCode(code) {
            if ($sessionStorage.countryNamesByCode && $sessionStorage.countryNamesByCode[code]) {
                return $sessionStorage.countryNamesByCode[code];
            }

            return null;
        }

        function getPackageStateConfigs(agentCRMId, agent) {
            if (!agentCRMId) {
                agentCRMId = agent.crmId;
            }

            return $http.get(baseClientsApiUrl + '/v1/pa/states/agent/' + agentCRMId, { cache: true })
                .then(getPackageStateConfigsCompleted)
                .catch(getPackageStateConfigsFailed);

            function getPackageStateConfigsCompleted(response) {
                return response.data;
            }

            function getPackageStateConfigsFailed(error) {
                // TODO: Create logger service
            }
        }

        function getStates() {
            return $http.get(baseApiUrl + '/eligibility/state', { cache: true })
                .then(getStatesCompleted)
                .catch(getStatesFailed);

            function getStatesCompleted(response) {
                return response.data;
            }

            function getStatesFailed(error) {
                // TODO: Create logger service
            }
        }

        function getRelationshipTypes() {
            return $http.get(baseApiUrl + '/middleearthproxy/content/relationshiptypes')
                .then(getRelationshipsCompleted)
                .catch(getRelationshipsFailed);

            function getRelationshipsCompleted(response) {
                if (response && response.data && response.data.response) {
                    return response.data.response;
                }

                return [];
            }

            function getRelationshipsFailed(error) {
                // TODO: Create logger service
            }
        }

        function getQuoteByQuoteNumber(quoteNumber, agent) {
            return $http.get(baseApiUrl + '/middleearthproxy/agents/' + agent.crmId + '/quotes/' + quoteNumber)
                .then(getQuoteCompleted)
                .catch(getQuoteFailed);

            function getQuoteCompleted(response) {
                if (response && response.data) {
                    return response.data;
                }

                return null;
            }

            function getQuoteFailed(error) {
                // TODO: Create logger service
            }
        }

        function getBhtpQuotes(quotes) {
            var req = {
                method: 'POST',
                url: baseClientsApiUrl + '/v1/pa/quote',
                data: quotes,
                isArray: true
            };
            return $http(req)
                .then(getQuoteResponses)
                .catch(getQuotesFailed);

            function getQuoteResponses(response) {
                return response.data;
            }

            function getQuotesFailed(errors) {
                // TODO: Create logger service
                if (errors.status === 422) {
                    return errors.data;
                }

                return $q.reject(errors);
            }
        }

        function getPartnerQuotes(quotes) {
            var req = {
                method: 'POST',
                url: baseClientsApiUrl + '/v1/pa/partner/multiplequotes',
                data: quotes,
                isArray: true
            };
            return $http(req)
                .then(getQuoteResponses)
                .catch(getQuotesFailed);

            function getQuoteResponses(response) {
                return response.data;
            }

            function getQuotesFailed(errors) {
                // TODO: Create logger service
                if (errors.status === 422) {
                    return errors.data;
                }

                return $q.reject(errors);
            }
        }

        function getSingleQuote(quote, forPartner) {
            var quoteUrl;
            if (forPartner === false) {
                quoteUrl = baseClientsApiUrl + '/v1/pa/quote';
                quote.policy.agentCode = quote.agentCode;
                quote.policy.agencyCode = quote.agencyCode;
            } else {
                quoteUrl = baseClientsApiUrl + '/v1/pa/partner/quote';
                quote.zip = quote.postalCode;
            }

            if (!quote.startDate && quote.startDateFull) {
                quote.startDate = moment(quote.startDateFull).format('YYYY-MM-DD');
            }

            var req = {
                method: 'POST',
                url: quoteUrl,
                data: quote
            };
            return $http(req)
                .then(getQuoteResponse)
                .catch(getQuoteFailed);

            function getQuoteResponse(response) {
                response.data.isPartnerProduct = forPartner;
                return response;
            }

            function getQuoteFailed(error) {
                // TODO: Create logger service
                return error;
            }
        }

        function purchaseQuote(quote, success, error) {
            var quoteUrl;
            if (quote.isPartnerProduct === false) {
                quoteUrl = baseClientsApiUrl + '/v1/pa/purchase';
                quote.policy.agentCode = quote.agentCode;
                quote.policy.agencyCode = quote.agencyCode;
            } else {
                quoteUrl = baseClientsApiUrl + '/v1/pa/partner/purchase';
                quote.zip = quote.postalCode;

            }

            var req = {
                method: 'POST',
                url: quoteUrl,
                data: quote
            };
            return $http(req)
                .then(getQuoteResponse)
                .catch(getQuoteFailed);

            function getQuoteResponse(response) {
                return response;
            }

            function getQuoteFailed(error) {
                // TODO: Create logger service
                return error;
            }
        }

        function verifyZip(zipValue) {
            return $http.get(
                baseApiUrl + '/eligibility/postalcode/verify/' + zipValue,
                { cache: true })
                .catch(function (error) {
                    return true;
                });
        }

        function verifyZipWithState(zipValue, state) {
            return $http.get(
                baseApiUrl + '/eligibility/postalcode/verifybystate/' + zipValue + '/' + state,
                { cache: true })
                .catch(function (error) {
                    return true;
                });
        }

        function searchPolicy(policyNumber, postalCode, emailAddress) {
            var options = {
                cache: true,
                params: {
                    postalCode: postalCode,
                    emailAddress: emailAddress
                }
            };

            var deferred = $q.defer();

            $http.get(baseClientsApiUrl + '/v1/Policy/SearchPolicies/' + policyNumber, options)
                .then(function (httpResult) {
                    var policySearchResponse = httpResult ? httpResult.data : null;

                    if (!policySearchResponse) {
                        deferred.reject('An error occurred while attempting to load the policy.');
                    }
                    else if (areThereApiErrorMessages(policySearchResponse.messages)) {
                        deferred.reject(policySearchResponse.messages[0].text);
                    }
                    else {
                        deferred.resolve(policySearchResponse.response);
                    }
                },
                function (error) {
                    console.log(error);

                    var errorMessage = error;
                    if (error && error.status && error.status === 500) {
                        errorMessage = 'An error occurred while attempting to search for the policy';
                    }
                    else if (error) {
                        errorMessage = error.data
                    }

                    deferred.reject(errorMessage);
                });

            return deferred.promise;
        }

        function searchClaimConfiguration(policyNumber, postalCode, emailAddress) {
            var options = {
                cache: true,
                params: {
                    postalCode: postalCode,
                    emailAddress: emailAddress
                }
            };

            return $http.get(
                baseApiUrl + '/middleearthproxy/searchpolicies/' + policyNumber + '/claimconfiguration', options)
                .then(function handleSearchPolicySuccess(data) {
                    return data.data;
                }, function handleSearchPolicyError(error) {
                    return $q.reject(error.data.message);
                });
        }

        function createSimpleClaim(claim) {
            return $http.post(baseApiUrl + '/middleearthproxy/simpleclaims', claim)
            .then(function (data) {
                return data.data;
            }, function (error) {
                return $q.reject(error.data);
            });
        }

        // agent code is optional, if one isn't provided the default will be
        function getAgent(agentCode) {
            if (!agentCode) {
                agentCode = '';
            }

            return $http.get(
                    baseClientsApiUrl + '/v1/pa/partner/agent/' + agentCode, { cache: true })
                .then(function handleGetAgentSuccess(resp) {
                    return resp.data;
                })
                .catch(function handleGetAgentError(error) {
                    return null;
                });
        }

        function getFraudWarning() {
            return $http.get(
                baseClientsApiUrl + '/v1/content/BlanketFraudWarning', { cache: true })
                .then(function handleGetFraudWarningSuccess(resp) {
                    if (resp && resp.data && resp.data.response) {
                        return resp.data.response;
                    }

                    return '';
            }, function handleGetFraudWarningError(error) {
                $q.reject(error.message);
            });
        }

        function areThereApiErrorMessages(messages) {
            if (!messages || messages.length < 1) {
                return false;
            }

            for (var i = 0; i < messages.length; i++) {
                var currentMessage = messages[i];
                if (currentMessage && currentMessage.severity !== 4) {
                    // this is a message to be concerned about.
                    return true;
                }
            }

            return false;
        };
    }
})();
