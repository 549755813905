(function () {
    angular
        .module('app')
        .config(routeConfig);

    routeConfig.$inject = ['$locationProvider', '$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider'];
    function routeConfig($locationProvider, $stateProvider, $urlRouterProvider, $urlMatcherFactory) {
        $urlMatcherFactory.caseInsensitive(true);
        //$locationProvider.html5Mode(true);
         
        // Default Route
        $urlRouterProvider.when('/quote', '/quote/quick');

        $stateProvider
            .state('searchPolicy', {
                url: '/policy/search',
                template: '<policy-holder></policy-holder>'
            })
            .state('quickQuote', {
                url: '/quote/quick',
                templateUrl: 'app/vacationguard/quotes/quick/quickquote.html',
                controller: 'QuickQuoteController',
                controllerAs: 'vm'
            })
            .state('quote', {
                url: '/quote/full/:packageName',
                templateUrl: 'app/vacationguard/quotes/full/enrollmentForm.html',
                controller: 'EnrollmentFormController',
                controllerAs: 'vm'
            })
            .state('loadQuote', {
                url: '/quote/full/:packageName/:quoteNumber',
                templateUrl: 'app/vacationguard/quotes/full/enrollmentForm.html',
                controller: 'EnrollmentFormController',
                controllerAs: 'vm'
            })
            .state('review', {
                url: '/quote/review',
                templateUrl: 'app/vacationguard/quotes/full/reviewQuote.html',
                controller: 'ReviewQuoteController',
                controllerAs: 'vm'
            })
            .state('receipt', {
                url: '/quote/receipt',
                templateUrl: 'app/vacationguard/quotes/purchase/purchaseReceipt.html',
                controller: 'PurchaseReceiptController',
                controllerAs: 'vm'
            })
            .state('quoteBHTP', {
                url: '/quote/bhtp',
                templateUrl: 'app/vacationguard/quotes/bhtp/bhtpQuoteForm.html',
                controller: 'BHTPQuoteController',
                controllerAs: 'vm'
            })
            .state('quoteBHTP.exactcareQuick', {
                url: '/exactcare/quick',
                templateUrl: 'app/vacationguard/quotes/bhtp/exactCareQuickForm.html',
                data: {
                    quickQuote: true,
                    nextState: 'quoteBHTP.exactcareCoverages'
                }
            })
            .state('quoteBHTP.exactcareCoverages', {
                url: '/exactcare/coverages',
                templateUrl: 'app/vacationguard/quotes/bhtp/exactCareCoverages.html',
                data: {
                    quickQuote: true,
                    nextState: 'quoteBHTP.exactcareTravelers'
                }
            })
            .state('quoteBHTP.exactcareTravelers', {
                url: '/exactcare/travelers',
                templateUrl: 'app/vacationguard/quotes/bhtp/exactCareTravelers.html',
                data: {
                    quickQuote: false,
                    nextState: 'quoteBHTP.exactcareReview'
                }
            })
            .state('quoteBHTP.exactcareReview', {
                url: '/exactcare/review',
                templateUrl: 'app/vacationguard/quotes/bhtp/bhtpReviewQuote.html',
                data: {
                    quickQuote: false,
                    nextState: 'payment'
                }
            })
            .state('quoteBHTP.payment', {
                url: '/payment',
                templateUrl: 'app/vacationguard/quotes/purchase/paymentForm.html',
                controller: 'PaymentFormController',
                controllerAs: 'paymentCtrl'
            })
            .state('claims', {
                abstract: true,
                url: '/claims',
                template: '<ui-view  autoscroll="true" />'
            })
            .state('claims.fnol', {
                url: '/create',
                template: '<claim-create></claim-create>'
            })
            .state('claims.details', {
                url: '/details',
                templateUrl: 'app/vacationguard/claims/details.html',
                controller: 'ClaimsDetailsController',
                controllerAs: 'detailsCtrl'
            });
    }

})();
