(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policyActions', policyActions);

    policyActions.$inject = [];
    function policyActions() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/vacationguard/components/policies/policyactions.html',
            scope: {
                actions: '=bhActions'
            },
            controller: ActionsController,
            controllerAs: 'actionsCtrl',
            bindToController: true
        };
        return directive;
    }

    function ActionsController() {
    }

})();
