/* globals $: false */
(function () {
    'use strict';

    angular
        .module('app.directives')
        .directive('bhAutoComplete', bhAutoComplete);

    bhAutoComplete.$inject = ['dataservice', '$http'];
    function bhAutoComplete (dataservice, $http) {
        return {
            templateUrl: 'app/vacationguard/directives/bhAutoComplete.html',
            restrict: 'E',
            scope: {
                lookupUrl: '=',
                selectedValue: '=',
                entityKey: '=',
                requiredVal: '=',
                disabledVal: '='
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.inputName = attrs.inputName;
                scope.getLookup = function (val) {
                    var url = baseApiUrl + attrs.lookupUrl.replace(/'/gi, '');
                    return $http.get(url.replace(':query', val), {
                        params: {
                        }
                    }).then(function (response) {
                        return response.data.map(function (item) {
                            return item[attrs.entityKey];
                        });
                    }).catch(function (error) { });
                };
            }
        };
    }
}());
