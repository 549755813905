(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policySearch', policySearch);

    function policySearch() {
        var directive = {
            restrict: 'EA',
            templateUrl: 'app/vacationguard/components/policies/policysearch.html',
            replace: true,
            scope: {
                policyNumber: '@bhPolicyNumber',
                postalCode: '@bhPostalCode',
                emailAddress: '@bhEmailAddress'
            },
            bindToController: true,
            controller: SearchController,
            controllerAs: 'searchCtrl',
            link: link
        };

        function link(scope, element, attrs) {
            if (scope.searchCtrl.policyNumber && scope.searchCtrl.postalCode && scope.searchCtrl.emailAddress) {
                scope.searchCtrl.submitForm(true);
            }
        }

        SearchController.$inject = ['policy'];
        function SearchController(policy) {
            var vm = this;

            vm.submitForm = submitForm;

            function submitForm(formValid) {
                if (formValid === true) {
                    vm.searching = true;
                    vm.errorMessage = null;

                    policy.searchPolicy(vm.policyNumber, vm.postalCode, vm.emailAddress)
                        .then(null, function handleSearchFlightError(reason) {
                            vm.errorMessage = reason;
                        })
                        .finally(function () {
                            vm.searching = false;
                        });
                }
            }
        }

        return directive;
    }
})();

