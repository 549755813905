(function () {
    'use strict';

    angular.module('app.directives')
           .directive('input', input);

    input.$inject = [];

    function input() {
        var directive = {
            restrict: 'E',
            require: '?ngModel',
            link: link,
        };
        return directive;

        function link(scope, element, attrs, ctrl) {
            if (attrs.type !== 'number') { return; }

            var numberType = (attrs.numberType) ? attrs.numberType : 'integer';
            switch (numberType) {
                case 'integer':
                    setupIntegerNumberInput(element, ctrl);
                    break;
            }

            function setupIntegerNumberInput(element, ctrl) {
                ctrl.$parsers.push(function (value) {
                    if (ctrl.$isEmpty(value)) {
                        return null;
                    }

                    var rounded = Math.floor(value);

                    ctrl.$setViewValue(rounded);
                    ctrl.$render();

                    return rounded;
                });

            }
        }
    }
})();
