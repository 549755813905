/* global $:false */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .service('travelerservice', travelerservice);

    travelerservice.$inject = ['quotes'];

    function travelerservice(quotes) {
        /* jshint -W040 */
        var vm = this;
        /* jshint +W040 */

        var quote = quotes.getCurrentQuote();
        if (quote && quote.additionalTravelers)
        {
            vm.travelers = quote.additionalTravelers;
        }
        else {
            vm.travelers = [];
        }

        /* jshint -W117 */
        vm.travelerSubject = new Rx.ReplaySubject();
        /* jshint +W117 */

        vm.travelerSubject.onNext(vm.travelers);

        travelerservice.prototype.removeTraveler = function removeTraveler(index) {
            vm.travelers.splice(index, 1);
            vm.travelerSubject.onNext(vm.travelers);
        };

        travelerservice.prototype.addTraveler = function addTraveler() {
            vm.travelers.push({});
            vm.travelerSubject.onNext(vm.travelers);
        };

        travelerservice.prototype.calculateAge = function calculateAge(birthDateValue) {
            var today = new Date();
            var birthDate = new Date(birthDateValue);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        };
    }
})();
