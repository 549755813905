(function () {
    'use strict';

    angular
        .module('vacationguard')
        .config(uiViewScrollConfig);

    uiViewScrollConfig.$inject = ['$uiViewScrollProvider'];

    function uiViewScrollConfig($uiViewScrollProvider) {
        $uiViewScrollProvider.useAnchorScroll();
    }

})();
