(function () {
    'use strict';

    angular
      .module('vacationguard')
      .controller('ClaimController', ClaimController);

    ClaimController.$inject = ['$sce', 'contacts'];

    function ClaimController($sce, contacts) {
        var vm = this;
        vm.trustAsHtml = $sce.trustAsHtml;
        vm.title = contacts.priorPolicies.label;
        vm.contact = contacts.priorPolicies.contacts[1].description;
    }
})();
