(function () {
    'use strict';

    var agentPortal = angular.module('agentPortal');

    /**
     * @ngdoc controller
     * @name rootController
     *
     * # rootController
     *
     * @description
     * root controller is parent controller of all the other controllers and gets injected as top-most
     * controller, provides functions to initialize items that belog on rootScope
     */

    agentPortal.controller('rootController', ['$rootScope', '$scope', 'portalService', 'storage', '$q', 'ambassadorInformationSessionStorage', 'globalSettingsService', rootController]);

    function rootController($rootScope, $scope, portalService, storage, $q, ambassadorInformationSessionStorage, globalSettingsService) {

        var rootModel = this;
        rootModel.agent = {};
        rootModel.ready = false;

        $rootScope.alerts = [];

        // Setup logos/images
        rootModel.mainLogoFile = globalSettingsService.baseCdnUrl() + globalSettingsService.mainLogoFile();

        // Setup favicon
        var link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        link.href = globalSettingsService.baseCdnUrl() + globalSettingsService.faviconUrl();
        document.getElementsByTagName('head')[0].appendChild(link);

        // var global_baseCdnUrl = '${baseCdnUrl}';
        // var global_mainLogoFile = '${mainLogoFile}';
        // var global_faviconUrl = '${faviconUrl}';

        rootModel.isLoggedIn = function () {
            return portalService.getCurrentAgentIsLoggedIn();
        };
        
        rootModel.isAmbassador = function () {
            return portalService.getCurrentAgentIsAmbassador();
        };

        rootModel.isReady = function () {
            return (rootModel.ready && !rootModel.isLoggedIn()) || portalService.getAgentIsInitialized();
        };

        rootModel.getAgent = function () {
            return portalService.getCurrentAgent();
        }

        /**
         * @description
         * initializes agent and configuration information on the root-scope, i.e., upon successful login
         */
        $scope.init = function () {
            $rootScope.currentYear = (new Date()).getFullYear();

            portalService.loadConfig().then(function(config){
                $rootScope.config = config;
            });

            var profile = portalService.getAgentProfileFromLocalStorage();

            if (profile) {
                portalService.initializeAgent().then(function (agent) {
                    rootModel.ready = true;

                    $rootScope.isCustomSession = (window.sessionStorage.getItem("isCustomSession") == 'true');
                });
            }
            else {
                rootModel.ready = true;
            }
        };

        $scope.init();

    }

})();

