(function () {
    'use strict';

    var myAppModule = angular.module('agentPortal');

    /**
     * @ngdoc service
     * @name portalService
     *
     * # portalService
     *
     * @description
     * service to perform one-time data-loading functions  and some utility methods to interact with the backend
     */
    myAppModule.service('portalService', ['$q', '$resource', '$http', 'storage', 'agents',
        'cacheService', '$rootScope', '$filter', 'apiUtilService', 'globalSettingsService', portalService]);

    function portalService($q, $resource, $http, storage, agents, cacheService, $rootScope, $filter, apiUtilService, globalSettingsService) {
        // Clients API replacement for middleware
        var packagesForAgentApiUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/Licenses/Agreements/:agentCode';
        var packagesForAgentByState = globalSettingsService.clientsApiBaseUrl() + '/v1/Licenses/:agentCode';

        var packageByStateAndRatingIdUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/packages/:ratingId/state/:state';

        var packagesApiUrl = globalSettingsService.apiBaseUrl() + '/v1/Packages';
        var agencyPackagesApiUrl = globalSettingsService.apiBaseUrl() + '/v1/Agency/Packages';
        var DrpAgentUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/Agency/:agencyCode/GetDrpAgent';
        var licenseUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/Licenses/:agentCode/:state/:ratingId'

        // verifies the agent exists and loads their info.
        var agentServiceUrl = globalSettingsService.apiBaseUrl() + '/v1/Agents/ByAuthId';

        // api
        var statesUrl = globalSettingsService.apiBaseUrl() + '/Eligibility/State';
        var countriesUrl = globalSettingsService.apiBaseUrl() + '/Eligibility/Country/ISO2/:countryCode';

        // Agents Gateway
        var expiredMessagesUrl = globalSettingsService.agentsGatewayBaseUrl() + '/quote/expired/reasons';

        var currentAgentInternalAuthId = null;
        var currentAgentIsAmbassador = false;
        var currentAgentCanInvoice = false;
        var currentAgentCanUseBulkPolicies = false;
        var currentAgentBatchTemplate;
        var currentAgentIsLoggedIn = false;
        var currentAgentIsDRP = false;
        var agentInitialized = false;
        var currentAgent = {};

        var agentLoginProfileCache = [];

        function packageComparator(pkg1, pkg2) {
            if (pkg1.name < pkg2.name)
                return -1;
            if (pkg1.name > pkg2.name)
                return 1;
            return 0;
        }

        // invalidate the cache on a 5 minute interval
        function executeGetWithCache(url, data, isArray) {
            cacheService.invalidateCacheIfNeeded();
            return $resource(url, data, { get: { cache: true, method: 'GET', isArray: isArray } }).get().$promise;
        }

        /**
         * @description
         * looks up country by given country-code
         */
        function getCountryByCode(countryIso2Code) {
            var deferred = $q.defer();

            executeGetWithCache(countriesUrl, { countryCode: countryIso2Code }, false)
                .then(function (countryDetailResponse) {
                        if (!countryDetailResponse) {
                            deferred.reject('Error loading country info for ' + countryIso2Code + '.');
                        }
                        else if (apiUtilService.areThereApiErrorMessages(countryDetailResponse.messages)) {
                            deferred.reject(countryDetailResponse.messages[0]);
                        }
                        else if (!countryDetailResponse.response || countryDetailResponse.response.length < 1) {
                            deferred.reject('Error loading country info for ' + countryIso2Code + '.');
                        }
                        else {
                            deferred.resolve(countryDetailResponse.response[0]);
                        }
                    },
                    function (error) {
                        deferred.reject('Error loading country info for ' + countryIso2Code + '.');
                    });

            return deferred.promise;
        }

        /**
         * @description
         * retrieves expired messages
         */
        function expiredMessages() {
            return executeGetWithCache(expiredMessagesUrl, {}, true);
        }

        /**
         * @description
         * retrieves states
         */
        function loadStates() {
            // eligibility just returns an array of states. no cleanup necessary like with the other apis.
            return executeGetWithCache(statesUrl, {}, true);
        }

        function loadPackagesForAgentApi(agentCode) {
            return executeGetWithCache(packagesForAgentApiUrl, { agentCode: agentCode }, false)
                    .then(function (promise) {
                        mapPackageIconCssClasses(promise.packages);
                        return promise;
                    });
        }

        function loadPackagesForAgentByState(agentCode) {
            return executeGetWithCache(packagesForAgentByState, { agentCode: agentCode }, false)
                    .then(function (promise) {
                        if (!promise || !promise.states || promise.states.length < 1){
                            return promise;
                        }

                        for (var i = 0; i < promise.states.length; i++) {
                            mapPackageIconCssClasses(promise.states[i].packages);
                        }

                        return promise;
                    });
        }

        function mapPackageIconCssClasses(packages) {
            if (!packages || packages.length < 1) {
                return;
            }

            for (var i = 0; i < packages.length; i++) {
                var p = packages[i];

                if (!p) {
                    continue;
                }

                var cssClass = '';

                switch ((p.packageIconType || '').toLowerCase()) {
                    case 'aircare':
                    case 'aircareenhanced':
                        // both aircares use the same, new icon.
                        cssClass = 'bhtp-aircare';
                        break;
                    case 'exactcare':
                        cssClass = 'bhtp-exactcare-traditional';
                        break;
                    case 'exactcareenhanced':
                        cssClass = 'bhtp-exactcare';
                        break;
                    case 'exactcarefamily':
                        cssClass = 'bhtp-exactcare-family-traditional';
                        break;
                    case 'exactcareextraenhanced':
                        cssClass = 'bhtp-exactcare-extra';
                        break;
                    case 'exactcarevalueenhanced':
                        cssClass = 'bhtp-exactcare-value';
                        break;
                    case 'vacationguard':
                        cssClass = 'bhtp-vacation-rental';
                        break;
                    case 'adrenalinecare':
                        cssClass = 'bhtp-adrenalinecare';
                        break;
                    case 'wavecare':
                        cssClass = 'bhtp-wavecare';
                        break;
                    case 'exactcarelite':
                        cssClass = 'bhtp-exactcare-lite';
                        break;
                    case 'luxurycare':
                        cssClass = 'bhtp-luxurycare';
                        break;
                    default:
                        cssClass = 'bhtp-product-default';
                }

                p.iconCssClass = cssClass;
            }

            return;
        }

        /**
         * @description
         * retrieves packages information from api
         */
        function getPackages() {
            var deferred = $q.defer();

            executeGetWithCache(packagesApiUrl, {}, true)
                .then(function (packageResponse) {
                        if (!packageResponse) {
                            deferred.reject('An error occurred while attempting to load package information.');
                        }
                        else if (packageResponse.length < 1) {
                            deferred.reject('No packages are available');
                        }

                        var allProducts = packageResponse || [];
                        var packagesToReturn = [];

                        // clean up the data -- left over from before.
                        for (var i = 0; i < packageResponse.length; i++) {
                            var currentPackage = allProducts[i];

                            // store the sf name of the package before we modify it.
                            currentPackage.actualName = currentPackage.name;
                            if (currentPackage.name === 'Aircare') {
                                currentPackage.name = 'AirCare';
                            }

                            if (isPackageAvailableInAgentPortal(currentPackage)) {
                                packagesToReturn.push(currentPackage);
                            }
                        }

                        deferred.resolve(packagesToReturn);
                    },
                    function (error) {
                        deferred.reject(error);
                    });

            return deferred.promise;
        }

        /**
         * @description
         * retrieves packages information from api
         */
        function getPackageByStateAndRatingId(state, ratingId) {
            var deferred = $q.defer();

            executeGetWithCache(packageByStateAndRatingIdUrl, { ratingId: ratingId, state: state }, false)
                .then(function (packageResponse) {
                        if (!packageResponse) {
                            deferred.reject('An error occurred while attempting to load package information.');
                        }

                        deferred.resolve(packageResponse);
                    },
                    function (error) {
                        deferred.reject(error);
                    });

            return deferred.promise;
        }

        /**
         * @description
         * retrieves products information
         */
        function loadDRPAgent(agencyCode) {
            return executeGetWithCache(DrpAgentUrl, { agencyCode: agencyCode }, false);
        }

        function loadConfig() {
            var deferredAgent = $q.defer();
            cacheService.invalidateCacheIfNeeded();

            // code previously made a call to server to get config values
            var resp = {
                AirCareRatingIds: global_airCareRatingIds,
                CLIENT_CONSUMER_DOMAIN: global_consumer_domain,
                CLIENT_AGENT_GUIDE: global_agent_guide_url,
                CLIENT_GUIDE_TO_AGENT: global_guide_for_agents_url,
                CLIENT_API_BASE_URL: global_client_base_api_url,
                CLIENT_ELIGIBILITY_BASE_URL: global_base_eligibility_url
            };

            deferredAgent.resolve(resp);

            return deferredAgent.promise;
        }

        function getLoginProfileFromCache(authId) {
            var toReturn = null;

            if (agentLoginProfileCache.length < 1) {
                return toReturn;
            }

            // clean up the cache
            // reverse loop, so we don't remove an index before the current one.
            for (var i = agentLoginProfileCache.length - 1; i > -1; i--) {
                var item = agentLoginProfileCache[i];

                if (apiUtilService.shouldBustCache(item.cacheTime, moment())) {
                    // too old. remove from cache.
                    agentLoginProfileCache.splice(i, 1);
                }
            }

            for (var i = 0; i < agentLoginProfileCache.length; i++) {
                if (agentLoginProfileCache[i].key === authId) {
                    toReturn = agentLoginProfileCache[i].profile;
                    break;
                }
            }

            return toReturn;
        }

        function saveLoginProfileToCache(authId, profile) {
            if (!profile) {
                return;
            }

            // verify the profile is not yet in the cache.
            for (var i = 0; i < agentLoginProfileCache.length; i++) {
                if (agentLoginProfileCache[i].key === authId) {
                    return;
                }
            }

            var cacheRecord = {
                key: authId,
                profile: profile,
                cacheTime: moment()
            };

            agentLoginProfileCache.push(cacheRecord);
        }

        function getAgentByInternalAuthId(id) {
            var deferred = $q.defer();

            // see if the profile is current in cache
            var existingProfile = getLoginProfileFromCache(id);

            if (existingProfile) {
                deferred.resolve(existingProfile);
                return deferred.promise;
            }


            var loadProfileApi = $resource(agentServiceUrl, {}, { post: { method: 'POST' } });

            var data = {
                authId: id
            };

            loadProfileApi.post(data)
                .$promise
                .then(function (profileResponse) {
                    if (!profileResponse) {
                        deferred.reject('Error loading agent info for ' + id + '.');
                    }
                    else if (apiUtilService.areThereApiErrorMessages(profileResponse.messages)) {
                        deferred.reject(profileResponse.messages[0]);
                    }

                    var userToReturn = processAgentProfile(profileResponse.response);

                    // save altered profile in cache
                    saveLoginProfileToCache(id, userToReturn);

                    deferred.resolve(userToReturn);
                },
                function (error) {
                    deferred.reject('Error loading agent info for ' + id + '.');
                });

            return deferred.promise;
        }

        function processAgentProfile(profile) {
            var superuserroles = 'administrator';
            var ambassadorRoles = 'ambassador';
            var user = profile;

            if (user.role) {
                user.role = user.role.toLowerCase();
            }
            else {
                user.role = null;
            }

            user.isSuperUser = superuserroles.indexOf(user.role) > -1;
            user.isAmbassador = ambassadorRoles.indexOf(user.role) > -1;

            // make the address nicer for angular2 edit/purchase.
            // address is currently stored as 'otherAddress', since that
            //  is what it is called in sf.
            //  set it to 'address' for more clarity.
            user.address = user.otherAddress;

            // temp update to recreate original properties from new ones.
            user.agencyId = user.agencyCrmId;
            user.agentId = user.crmId;

            return user;
        }

        function getAgentProfileFromLocalStorage() {
            return JSON.parse(localStorage.getItem('profile'));
        }


        // bypassAgentCodeCheck is the hack put in so that Geico can sell using BHTP products
        function getAgentByInternalId(id, useAgentPassedIn, bypassAgentCodeCheck) {
            if (id == null) {
                var profile = getAgentProfileFromLocalStorage();

                if (profile) {
                    id = profile.user_id;
                }
            }

            if (id !== null) {
                return getAgentByInternalAuthId(id);
            }
        }

        /**
         * @description
         * retrieves check for availability of packages
         */
        function isPackageAvailableInAgentPortal(Pckage) {
            var availablePlatform = Pckage.availablePlatform;

            //If no platform set in salesforce
            if (availablePlatform == null) {
                return false;
            }

            var availablePlatformArray = availablePlatform.split(';');
            for (var i = 0; i < availablePlatformArray.length; i++) {
                //If package available in salesforce
                if (availablePlatformArray[i].toLowerCase() == 'agent') {
                    return true;
                }
            }

            //If package not found
            return false;
        }

        /**
        * @description
        * returns filtering for agency products and expired packages
        */
        function loadAgencyProductsForDropdown(buildByRatingId = false) {
            var deferred = $q.defer();
            var promise = executeGetWithCache(agencyPackagesApiUrl, {}, false);

            promise.then(
                function (packageResponse) {
                    if (!packageResponse) {
                        deferred.reject('An error occurred while attempting to load agency products.');
                    }
                    else if (apiUtilService.areThereApiErrorMessages(packageResponse.messages)) {
                        deferred.reject(packageResponse.messages[0]);
                    }

                    var packages = [];

                    if (packageResponse.response.length > 0) {
                        for (var i = 0; i < packageResponse.response.length; i++) {
                            packages.push(packageResponse.response[i]);
                        }
                    }

                    packages.sort(packageComparator);

                    var packagesToReturn;

                    if (buildByRatingId) {
                        packagesToReturn = buildDropDownValuesForQuotes(packages);
                    } else {
                        packagesToReturn = buildDropDownValues(packages);
                    }

                    deferred.resolve(packagesToReturn);
                },
                function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function buildDropDownValues(packages) {
            var toModify = packages || [];
            var packagesToReturn = [];

            packagesToReturn.push({ value: '', name: 'All Products' });

            for (var i = 0; i < toModify.length; i++) {
                var currentPackage = toModify[i];

                var label = currentPackage.name;
                if (currentPackage.subTitle) {
                    label += " " + currentPackage.subTitle;
                }

                // see if there's a similarly named package already in the list
                //  if so, we'll concatenate the matching package ids together,
                //  so we only have a single list item per package name.
                var alreadyContains = false;
                for (var p = 0; p < packagesToReturn.length; p++) {
                    var existingPackage = packagesToReturn[p];

                    if (existingPackage.name == label) {
                        alreadyContains = true;
                        existingPackage.value = existingPackage.value + "," + currentPackage.packageIdentifier;
                        existingPackage.id = existingPackage.id + "," + currentPackage.packageIdentifier;
                        existingPackage.ratingId = existingPackage.ratingId + "," + currentPackage.ratingId;
                        existingPackage.subTitle = existingPackage.subTitle + "," + currentPackage.subTitle;
                        break;
                    }
                }

                // only add the package to the list if it isn't in the list yet.
                if (!alreadyContains) {
                    packagesToReturn.push({
                        value: currentPackage.packageIdentifier,
                        id: currentPackage.packageIdentifier,
                        name: label,
                        ratingId: currentPackage.ratingId,
                        subTitle: currentPackage.subTitle
                    });
                }
            } // end for loop through package list

            return packagesToReturn;
        }

        function buildDropDownValuesForQuotes(packages) {
            var toModify = packages || [];
            var packagesToReturn = [];
            packagesToReturn.push({ value: '', name: 'All Products' });

            for (var i = 0; i < toModify.length; i++) {
                var currentPackage = toModify[i];

                var label = currentPackage.name;
                if (currentPackage.subTitle) {
                    label += " " + currentPackage.subTitle;
                }

                // see if there's a similarly named package already in the list
                //  if so, we'll concatenate the matching package ids together,
                //  so we only have a single list item per package name.
                var alreadyContains = false;
                for (var p = 0; p < packagesToReturn.length; p++) {
                    var existingPackage = packagesToReturn[p];
                    // This was added to fix a bug in the api where duplicate packages were coming back.
                    if (existingPackage.name == label && !existingPackage.value.split(',').includes(currentPackage.packageIdentifier)) {
                        alreadyContains = true;
                        // If the Name is the same but rating Id's are different, the we will concatenate the matching packages
                        if (!existingPackage.ratingId.split(',').includes(currentPackage.ratingId)) {
                            existingPackage.value = existingPackage.value + "," + currentPackage.packageIdentifier;
                            existingPackage.id = existingPackage.id + "," + currentPackage.packageIdentifier;
                            existingPackage.ratingId = existingPackage.ratingId + "," + currentPackage.ratingId;
                            existingPackage.subTitle = existingPackage.subTitle + "," + currentPackage.subTitle;
                        }
                        break;
                    }
                }

                // only add the package to the list if it isn't in the list yet.
                if (!alreadyContains) {
                    packagesToReturn.push({
                        value: currentPackage.ratingId,
                        id: currentPackage.packageIdentifier,
                        name: label,
                        ratingId: currentPackage.ratingId,
                        subTitle: currentPackage.subTitle
                    });
                }
            } // end for loop through package list

            return packagesToReturn;
        }

        function getStateLicense(state, agentCode, ratingId)
        {
            var licenseApi = $resource(licenseUrl, { state: state, agentCode: agentCode, ratingId: ratingId }, { get: { method: 'GET', isArray: false } });
            return licenseApi.get().$promise;
        }

        return {

            /**
             * @description
             * utility method to post data to URL - probably not used anymore
             */
            postJsonToURL: function (url, postData) {
                var deferredAgent = $q.defer();

                $http.post(url, postData)
                    .then(function (result) {
                        deferredAgent.resolve(result.data);
                    }).catch(
                    function (data, status) {
                        deferredAgent.reject(status);
                    });
                return deferredAgent.promise;
            },

            /**
             * @description
             * retrieves configuration described in Web.config file from server
             */
            loadConfig: loadConfig,

            /**
             * @description
             * get drp agent by agencyId
             */
            loadDRPAgentForAgency: function (agencyCode) {
                var DRPAgentApi = $resource(DrpAgentUrl, { agencyCode: agencyCode });
                var deferred = $q.defer();

                DRPAgentApi.get().$promise
                    .then(
                        function (agentResponse) {
                            if (!agentResponse) {
                                deferred.reject('An error occurred while attempting to load agents.');
                            }
                            else if (apiUtilService.areThereApiErrorMessages(agentResponse.messages)) {
                                deferred.reject(agentResponse.messages[0]);
                            }
                            else {
                                deferred.resolve(agentResponse.response);
                            }
                        },
                        function (error) {
                            deferred.reject(error);
                        });

                return deferred.promise;
            },

            loadStates: loadStates,
            loadPackagesForAgentApi: loadPackagesForAgentApi,
            loadPackagesForAgentByState: loadPackagesForAgentByState,
            getPackages: getPackages,
            getPackageByStateAndRatingId: getPackageByStateAndRatingId,
            loadAgencyProductsForDropdown: loadAgencyProductsForDropdown,
            getCountryByCode: getCountryByCode,
            isPackageAvailableInAgentPortal: isPackageAvailableInAgentPortal,
            getAgentProfileFromLocalStorage: getAgentProfileFromLocalStorage,
            getExpiredMessages: expiredMessages,

            setInternalAgentAuthId: function(id){
                currentAgentInternalAuthId = id;
            },

            getInternalAgentAuthId: function(){
                return currentAgentInternalAuthId;
            },

            /*
            * Get the agent information by passing in the auth0 Id of the agent
            */
            getAgentByInternalId: getAgentByInternalId,

            initializeAgent: function (id) {
                var deferredAgent = $q.defer();
                var agentResp = {};
                getAgentByInternalId(id, false).then(function (agent) {
                    if (agent) {
                        agentResp = agent;

                        var currentAgentCode = agents.getCurrentAgentCode();
                        if (!currentAgentCode || currentAgentCode === agent.agentCode) {
                            agents.refreshCurrentAgent(agent.agentCode)
                            .then(function (apiAgent) {
                                if (apiAgent) {
                                    currentAgentCanInvoice = apiAgent.canInvoice;
                                    currentAgentCanUseBulkPolicies = apiAgent.canInvoice && apiAgent.batchPolicyTemplate;
                                    currentAgentBatchTemplate = apiAgent.batchPolicyTemplate;
                                    currentAgentIsDRP = apiAgent.isDRP;
                                    agentResp.currentAgentCanInvoice = currentAgentCanInvoice;
                                    agentResp.currentAgentCanUseBulkPolicies = currentAgentCanUseBulkPolicies;
                                    agentResp.currentAgentBatchTemplate = currentAgentBatchTemplate;
                                    agentResp.currentAgentIsDRP = currentAgentIsDRP;
                                }

                                currentAgent = agentResp;
                                agentInitialized = true;
                                currentAgentIsLoggedIn = true;
                                currentAgentIsAmbassador = agent.isAmbassador;
                                deferredAgent.resolve(agentResp);
                            });
                        }
                        else {
                            // check the current logged in agent
                            agents.refreshCurrentAgent(agent.agentCode)
                            .then(function (apiAgent) {
                                if (apiAgent) {
                                    currentAgentCanInvoice = apiAgent.canInvoice;
                                    currentAgentCanUseBulkPolicies = apiAgent.canInvoice && apiAgent.batchPolicyTemplate;
                                    currentAgentBatchTemplate = apiAgent.batchPolicyTemplate;
                                    currentAgentIsDRP = apiAgent.isDRP;
                                    agentResp.currentAgentCanInvoice = currentAgentCanInvoice;
                                    agentResp.currentAgentCanUseBulkPolicies = currentAgentCanUseBulkPolicies;
                                    agentResp.currentAgentBatchTemplate = currentAgentBatchTemplate;
                                    agentResp.currentAgentIsDRP = currentAgentIsDRP;
                                }

                                // check the agent passed in on the url
                                agents.refreshCurrentAgent(currentAgentCode)
                                .then(function (apiAgent) {
                                    currentAgent = agentResp;
                                    agentInitialized = true;
                                    currentAgentIsLoggedIn = true;
                                    currentAgentIsAmbassador = agent.isAmbassador;
                                    deferredAgent.resolve(agentResp);
                                });
                            });
                        }
                    }
                });

                return deferredAgent.promise;
            },

            getAgentIsInitialized: function(){
                return agentInitialized;
            },

            getCurrentAgentIsAmbassador: function(){
                return currentAgentIsAmbassador;
            },

            getCurrentAgentCanInvoice: function () {
                return currentAgentCanInvoice;
            },

            getCurrentAgentCanUseBulkPolicies: function () {
                return currentAgentCanUseBulkPolicies;
            },

            getCurrentAgentBatchTemplate: function () {
                return currentAgentBatchTemplate;
            },

            getCurrentAgentIsDRP: function () {
                return currentAgentIsDRP;
            },

            getCurrentAgentIsLoggedIn: function () {
                return currentAgentIsLoggedIn;
            },

            getCurrentAgent: function(){
                return currentAgent;
            },

            logout: function () {
                currentAgentInternalAuthId = null;
                currentAgentIsAmbassador = false;
                currentAgentCanInvoice = false;
                currentAgentIsLoggedIn = false;
                currentAgentCanUseBulkPolicies = false;
                currentAgentBatchTemplate = undefined;
                currentAgentIsDRP = false;
                agentInitialized = false;
                currentAgent = {};
                agents.removeAgentCookies();
            },

            getStateLicense : getStateLicense
        };
    }

})();
