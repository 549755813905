(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name contactController
     *
     * # contactController
     *
     * @description
     * provides functions behind contact us form
     */

    angular.module('agentPortal')
        .controller('contactController', ['portalService','contactService', 'utilService', 'intentService', contactController]);

    function contactController(portalService, çontactService, utilService, intentService) {
        var vm = this;
        vm.contactCategories = [];
        vm.selectedContact = '';
        vm.mailContent = '';
        vm.contacts = [];

        vm.agent = {};

        /**
         * @description
         * posts the contact us form to server, there by sending the email to the concerned party.
         */
        vm.sendMail = function () {
            console.log(vm.selectedContact);
            console.log(vm.mailContent);
            intentService.setIntent("Submitting...");
            çontactService.sendContactRequest(vm.selectedContact.value, vm.mailContent).then(function () {
                intentService.resetIntent();
                vm.selectedContact = '';
                vm.mailContent = '';
                utilService.showPopup("Message", "Thank you, "+ vm.agent.firstName + ", for contacting us; we'll reach out to you shortly.");
            }, function (error) {
                intentService.resetIntent();
                console.warn("Failed while submitting the contact-us form %o", error);
                utilService.showPopup("Message", "Oops! Something went wrong, please try again later.");
            });
        };


        /**
         * @description
         * initialization activities ...
         */
        function init() {
            vm.title = 'Contact Us';

            portalService.getAgentByInternalId().then(function (agent) {
                vm.agent = agent;
            });

            //load categories of questions from the API
            çontactService.loadCategoryInfo().then(function (content) {
                vm.contactCategories = content || [];
            }, function(error) {
                console.warn("Failed while submiting the contact-us form %o", error);
                utilService.showPopup("Error", "Failed while retrieving the categories.");
            });

            //get static list of contacts by question-categories to display on the side.
            çontactService.getContacts().then(function (contacts) {
                vm.contacts = contacts;

                intentService.resetIntent();
            }, function(error) {
                intentService.resetIntent();
                console.warn("Failed while submiting the contact-us form %o", error);
                utilService.showPopup("Message", "Oops! Something went wrong, please try again later.");
            });
        }

        init();
    }
})();