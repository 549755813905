(function () {
    'use strict';

    angular
        .module('app.quotes')
        .controller('ReviewQuoteController', ReviewQuoteController);

    ReviewQuoteController.$inject = ['quotes', 'coverageservice'];

    function ReviewQuoteController(quotes, coverageservice) {
        var vm = this;
        vm.quote = {};
        vm.currentPackage = {};
        vm.title = 'Review Quote';
        vm.errors = [];

        init();

        function init() {
            vm.quote = quotes.getCurrentQuote();
            vm.currentQuoteResponse = quotes.getQuoteResponse();
            if (vm.currentQuoteResponse) {
                vm.quote.optionalAmount = coverageservice
                    .sumOptionalPremiums(vm.currentQuoteResponse.coverages.optional);
            }

            vm.currentPackage = quotes.getCurrentPackage();
        }
    }
})();
