(function () {
    'use strict';

    // used to cache all html template files so the app as a whole can be packaged into a single file
    angular.module('templates', []);

    angular
        .module('app', [
            'app.core', 'app.components', 'app.directives', 'app.quotes',
            'components.claims', 'components.policies',
            'templates',
            'ngStorage', 'ui.bootstrap', 'ui.router', 'ui.mask',
            'ngAnimate', 'ngCookies', 'ngMessages', 'ngSanitize'
        ])
        .config(['$provide', function ($provide) {

            $provide.decorator('uibDatepickerPopupDirective', ['$delegate', function ($delegate) {
                var directive = $delegate[0];
                var link = directive.link;

                directive.compile = function () {
                    return function (scope, element, attrs) {
                        link.apply(this, arguments);
                        element.mask('99/99/9999', { placeholder: 'mm/dd/yyyy' });
                    };
                };

                return $delegate;
            }]);
        }])
        .config(['$localStorageProvider', function ($localStorageProvider) {
            $localStorageProvider.setKeyPrefix('bhtp-');
        }])
        .run(['$rootScope', 'agents', '$location', '$http', function ($rootScope, agents, $location, $httpProvider) {
            // by default, only allow application/json to be returned.  This used to also allow xml or plain text (angular default).
            $httpProvider.defaults.headers.common.Accept = ['application/json', 'text/html'];

            // url parameters are only available if defined explicitly in the ui router state.
            // so use $location here to grab the agent code
            $rootScope.$on('$locationChangeStart', function (event, nextPath, current) {
                if ($location.$$search.agentCode) {
                    agents.refreshCurrentAgent($location.$$search.agentCode)
                        .then(function (agent) {

                        });
                }
            });
        }]);

})();
