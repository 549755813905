(function () {
    'use strict';

    angular
        .module('app')
        .config(uiConfig);

    uiConfig.$inject = ['uibDatepickerConfig', 'uibDatepickerPopupConfig'];

    function uiConfig(uibDatepickerConfig, uibDatepickerPopupConfig) {
        uibDatepickerConfig.showWeeks = false;
        uibDatepickerPopupConfig.showButtonBar = false;
        uibDatepickerPopupConfig.datepickerPopup = 'MM/dd/yyyy';
        uibDatepickerPopupConfig.closeOnDateSelection = true;
    }

})();
