(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policyDetails', [policyDetails]);

    function policyDetails() {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/policies/policydetails.html',
            scope: {
                policyContainer: '='
            },
            bindToController: true,
            controllerAs: 'detailsCtrl',
            controller: DetailsController
        };
        return directive;
    }

    DetailsController.$inject = [];
    function DetailsController() {
    }

})();
