(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policyCoverages', policyCoverages);

    policyCoverages.$inject = [];
    function policyCoverages() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/vacationguard/components/policies/policycoverages.html',
            scope: {
                coverages: '=bhCoverages',
                hideIfUnselected: '=bhHideIfUnselected'
            },
            bindToController: true,
            controllerAs: 'coveragesCtrl',
            controller: CoveragesController
        };
        return directive;
    }

    function CoveragesController() {
    }

})();
