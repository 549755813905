(function () {

    angular
        .module('app.quotes')
        .directive('quoteSummary', quoteSummary);

    function quoteSummary() {
        var directive = {
            restrict: 'E',
            scope: {
                quote: '=',
                currentPackage: '='
            },
            templateUrl: 'app/vacationguard/quotes/purchase/quoteSummary.html',
            replace: true,
            bindToController: true,
            controllerAs: 'vm',
            controller: QuoteSummaryController
        };
        return directive;
    }

    QuoteSummaryController.$inject = ['datecalculation', 'dataservice', 'agents', '$state'];

    function QuoteSummaryController(datecalculation, dataservice, agents, $state) {
        this.$onInit = function () {
            var vm = this;
            vm.getCurrentState = getCurrentState;

            if (vm.quote) {
                if (vm.quote.destination) {
                    vm.destination = getCountryByCode(vm.quote.destination);
                }

                if (vm.quote.startDate) {
                    vm.quote.endDate = datecalculation.calculateYearMinusDay(vm.quote.startDate);
                }
            }

            function getCurrentState() {
                return $state.$current.name;
            }

            function getCountryByCode(code) {
                return dataservice.getCountryByCode(code);
            }
        };
    }

})();
