(function () {
    'use strict';

    /**
     * @ngdoc factory
     * @name customersService
     *
     * # customersService
     *
     * @description
     * service to support backend integration for customers page
     */
    angular.module('agentPortal')
        .factory('customersService', ['$rootScope', '$resource', '$q', 'portalService', 'utilService', '$state', 'apiUtilService', 'globalSettingsService', customersService]);

    function customersService($rootScope, $resource, $q, portalService, utilService, $state, apiUtilService, globalSettingsService) {
        var productStateMetaDataUrl = globalSettingsService.apiBaseUrl() + '/Eligibility/ProductStateMetadata/:packageId/:state';
        var removeCustomerUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/Customer/:customerId/:agentId';
        var getCustomerByIdUrl = globalSettingsService.apiBaseUrl() + '/v1/Agency/Customer';
        var queryCustomersPagedUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/agency/:agencyCode/customers';

        var thisService = this;

        /**
         * @description
         * loads single customer by given id, to show customer details page
         */
        this.getById = function getById(customerId) {
            var deferred = $q.defer();

            portalService.getAgentByInternalId().then(function (agent) {
                var data = {
                    customerId: customerId,
                    agencyName: agent.agencyName
                };

                $resource(getCustomerByIdUrl, {}, { post: { method: 'POST' } })
                    .post(data)
                    .$promise
                    .then(function (customerDetailResponse) {
                        if (!customerDetailResponse) {
                            showCustomerLoadErrorAndReject(deferred);
                        }
                        else if (apiUtilService.areThereApiErrorMessages(customerDetailResponse.messages)) {
                            showCustomerLoadErrorAndReject(deferred, customerDetailResponse.messages[0]);
                        }
                        else if (!customerDetailResponse.response || !customerDetailResponse.response.id) {
                            showCustomerLoadErrorAndReject(deferred);
                        }
                        else {
                            shimOldCustomerProperties(customerDetailResponse.response);

                            deferred.resolve(customerDetailResponse.response);
                        }
                    },
                    function (error) {
                        showCustomerLoadErrorAndReject(deferred);
                    });
                },
                function (error) {
                    showCustomerLoadErrorAndReject(deferred);
            });

            return deferred.promise;
        };

        function showCustomerLoadErrorAndReject(promise, errorMessage) {
            var message = errorMessage || 'The selected customer is not available.';

            // we need to show service error messages at the service level, or implement handling everywhere.
            utilService.showPopup('Error', message);
            promise.reject(message);
        }

        /*
       * @desription
       * Calls the BHTP Client Api to remove a customer from a partners view
       */
        this.removeCustomer = function removeCustomer(customerId, redirectTo, customerName, refreshFunction) {
            return portalService.getAgentByInternalId().then(function (agent) {
                var approvePromiseFunction = function () {
                    return $resource(removeCustomerUrl, { agentId: agent.agentId, customerId: customerId })
                        .delete()
                        .$promise;
                };

                var options = thisService._getRemoveCustomerModalOptions(redirectTo, customerName, refreshFunction, approvePromiseFunction);

                utilService.showConfirmWithOptionsPopupNoMessages(options.title, options.message, options.buttons);
            });
        };

        this._getRemoveCustomerModalOptions = function getRemoveCustomerModalOptions(redirectTo, customerName, refreshCallback, approvePromiseFunction) {
            var options = {};
            options.message = 'Removing a customer removes that customer and any of their unpurchased quotes. Are you sure you want to remove ' + customerName + '?';
            options.title = 'Remove Customer Validation';

            var cancelButton = {
                style: "btn btn-lg btn-default btn-cust-sec",
                action: function (f) {
                    // Nothing Happens
                    return false;
                },
                name: "No"
            };

            var okButton = {
                style: "btn btn-lg btn-default btn-cust",
                action: function (f) {
                    var promise = approvePromiseFunction ? approvePromiseFunction() : $q.when({});

                    promise.then(
                        function (result) {
                            return thisService._onRemoveCustomerSuccess(result, refreshCallback, redirectTo);
                        },
                        function (error) {
                            return thisService._onRemoveCustomerError(error);
                        });
                },
                name: "Yes"
            };

            options.buttons = [cancelButton, okButton];

            return options;
        };

        this._onRemoveCustomerSuccess = function onRemoveCustomerSuccess(result, callback, redirectTo) {
            // Display Success Message
            utilService.showMessagePopup('Message', 'The customer has been removed.');

            if (callback) {
                callback();
            }

            // Redirect to the Customer List
            if (redirectTo) {
                $state.go(redirectTo);
            }

            return true;
        };

        this._onRemoveCustomerError = function onRemoveCustomerError(error) {
            // Display Error Message
            utilService.showMessagePopup(
                'Message',
                'Something went wrong while removing the customer.',
                'fa fa-exclamation-circle fa-icon-medium fa-icon-error');
            return false;
        };

        /**
         * @description
         * date filters for customers grid 
         */
        this.getDateFilters = function getDateFilters() {
            return [
                { interval: 'All', name: 'All' },
                { interval: '0', name: 'Today' },
                { interval: '1', name: 'This Week' },
                { interval: '2', name: 'This Month' },
                { interval: '3', name: 'Past Month' },
                { interval: '4', name: 'YTD' }
            ];
        };

        /**
         * @description
         * loads customers from the server
         */
        this.loadCustomerSummaries = function loadCustomerSummaries(agentCode, pageNumber, orderBy, direction, dateSelected, searchText) {
            var deferred = $q.defer();

            portalService.getAgentByInternalId().then(function (agent) {
                    var searchCriteria = {
                        agencyId: agent.agencyId,
                        agentCode: agentCode,
                        agencyName: agent.agencyName,
                        pageNumber: pageNumber,
                        orderBy: orderBy,
                        direction: direction,
                        startDate: dateSelected.startDate,
                        endDate: dateSelected.endDate,
                        pageSize: 10,
                        searchValue: searchText
                    };

                    var customersApi = $resource(queryCustomersPagedUrl, { agencyCode: agent.agencyCode }, { load: { method: 'POST' } });

                    customersApi.load(searchCriteria)
                        .$promise
                        .then(function (customerSummaryResponse) {
                                    if (!customerSummaryResponse) {
                                        deferred.reject('An error occurred while attempting to load customer summaries.');
                                    }
                                    else if (apiUtilService.areThereApiErrorMessages(customerSummaryResponse.messages)) {
                                        deferred.reject(customerSummaryResponse.messages[0]);
                                    }
                                    else {
                                        deferred.resolve(customerSummaryResponse.response);
                                    }
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                        );
                },
                function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function shimOldCustomerProperties(customer) {
            if (!customer) {
                return;
            }

            if (customer.birthDateForDisplay) {
                customer.birthDate = moment(customer.birthDateForDisplay).format('YYYY-MM-DD');
            }

            if (customer.id) {
                customer.customerId = customer.id;
            }

            if (customer.phone) {
                // clean any phone formatting.
                customer.phoneNumber = customer.phone.replace(/\(|\)|\s|-/gi, '');
                customer.phoneNumbers = [{ phoneNumber: customer.phoneNumber }];
            }

            if (customer.emailAddress) {
                customer.email = customer.emailAddress;
            }
        }

        return thisService;
    }
})();