/* global $:false */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .controller('BHTPQuoteController', BHTPQuoteController);

    BHTPQuoteController.$inject = [
        '$q', 'dataservice', '$state', 'quotes', 'moment', 'coverageservice', 'travelerservice', 'agents', 'ambassadorInformationSessionStorage'
    ];

    function BHTPQuoteController(
        $q, dataservice, $state, quotes, moment, coverageservice, travelerservice, agents, ambassadorInformationSessionStorage) {

        var vm = this;
        vm.title = 'Get a Quote';

        vm.quote = quotes.getCurrentQuote();
        vm.errors = [];
        vm.currentPackage = quotes.getCurrentPackage();
        vm.currentQuoteResponse = quotes.getQuoteResponse();
        vm.quote.optionalAmount = 0;
        vm.today = moment().format('YYYY-MM-DD');
        vm.todayDate = new Date();

        vm.datePickers = {
            dateOfBirth: false,
            departureDate: false,
            returnDate: false,
            bookingDate: false
        };

        //* Functions exposed to View
        vm.openDatePicker = openDatePicker;
        vm.submitForm = submitForm;
        vm.addTraveler = addTraveler;
        vm.removeTraveler = removeTraveler;
        vm.changedOptionalCoverage = changedOptionalCoverage;
        vm.getCoveragePremium = getCoveragePremium;
        vm.updateAge = updateAge;
        vm.currentState = getCurrentState;
        vm.policyBuyerChanged = policyBuyerChanged;

        travelerservice.travelerSubject.subscribe(function (travelers) {
            this.quote.additionalTravelers = travelers;
        }.bind(vm));

        init();

        //* Method Declarations
        function init() {
            if (!vm.quote.policy) {
                vm.quote.policy = {};
            }

            // default fulfillment method to be email
            if (!vm.quote.policy.fulfillmentMethod || vm.quote.policy.fulfillmentMethod === null) {
                vm.quote.policy.fulfillmentMethod = 'Email';
            }

            var promises = [].concat(
                getRelationshipTypes(),
                getAgent()
            );

            $q.all(promises)
                .then(function () {
                    // ready function
                });

            calculateOptionalPremium();
        }

        function getRelationshipTypes() {
            return dataservice.getRelationshipTypes()
                .then(function (data) {
                    vm.relationships = data;
                    return vm.relationships;
                });
        }

        function getAgent() {
            return agents.getCurrentAgent()
                .then(function (agent) {
                    vm.quote.policy.agencyCode = agent.agencyCode;
                    vm.quote.policy.agentCode = ambassadorInformationSessionStorage.getAgentCode();                 
                });
        }

        function openDatePicker($event, source) {
            $event.preventDefault();
            $event.stopPropagation();

            // Keep the current state of the selected date picker so we can toggle it on click
            var isOpen = vm.datePickers[source];

            for (var key in vm.datePickers) {
                if (vm.datePickers.hasOwnProperty(key)) {
                    vm.datePickers[key] = false;
                }
            }

            vm.datePickers[source] = !isOpen;
        }

        function getCurrentState() {
            return $state.$current.name;
        }

        function addTraveler() {
            travelerservice.addTraveler();
            vm.datePickers['additionalTravelers' + (vm.quote.additionalTravelers.length - 1)] = false;
        }

        function removeTraveler(index) {
            delete vm.datePickers['additionalTravelers' + (vm.quote.additionalTravelers.length - 1)];
        }

        function updateAge(birthDateField, birthDateValue, isPrimary, travelerIndex) {
            if (birthDateField.$valid) {
                if (isPrimary) {
                    vm.quote.primaryTraveler.age = travelerservice.calculateAge(birthDateValue);
                }
                else {
                    vm.quote.additionalTravelers[travelerIndex].age = travelerservice.calculateAge(birthDateValue);
                }
            }
        }

        function changedOptionalCoverage(coverage, fieldValueChanged, dataValid) {
            vm.quote.optionalAmount += coverageservice
                .toggleCoverageAndCalculatePremiumChange(coverage, vm.currentPackage.coverages,
                fieldValueChanged, dataValid, vm.quote.policy.rentalCarPickupDate,
                vm.quote.policy.rentalCarReturnDate);
        }

        function calculateOptionalPremium() {
            if (vm.currentQuoteResponse) {
                vm.quote.optionalAmount = coverageservice
                    .sumOptionalPremiums(vm.currentQuoteResponse.coverages.optional);
            }
        }

        function getCoveragePremium(coverage) {
            return coverageservice.getCoveragePremium(coverage);
        }

        function submitForm(form) {
            if (form.$valid === true) {
                // get quotes
                vm.errors = [];
                var quickQuote = $state.current.data.quickQuote != null &&
                    $state.current.data.quickQuote === true ? true : false;

                // while quoting, don't send in optional coverages so that the api will quote all of them.
                var newQuote = quotes.buildBHTPQuote(vm.quote, [], quickQuote);
                vm.quoting = true;
                return quotes.getSingleQuote(newQuote, false)
                    .then(function(resp) {
                        if (!resp.data.messages || resp.data.messages.length === 0) {
                            handleQuoteSuccess(resp, form);
                        }
                        else {
                            handleQuoteError(resp);
                        }
                    })
                    .catch(function(err) {
                        handleQuoteError(err);
                    });
            }
        }

        function handleQuoteSuccess(resp, form) {
            vm.quoting = false;

            if (vm.currentQuoteResponse) {
                coverageservice.reconcileData(
                    resp.data.coverages.optional,
                    vm.currentQuoteResponse.coverages.optional);
            }

            vm.currentQuoteResponse = resp.data;
            var currentCoverages = [];
            if (resp.data.coverages) {
                currentCoverages = currentCoverages
                    .concat(vm.currentQuoteResponse.coverages.included)
                    .concat(vm.currentQuoteResponse.coverages.optional);
                vm.currentPackage.coverages = currentCoverages;
                quotes.setCurrentPackage(vm.currentPackage);
            }

            quotes.setQuoteResponse(vm.currentQuoteResponse);

            calculateOptionalPremium();
            quotes.setCurrentQuote(vm.quote);

            form.$setPristine();
            $state.go($state.current.data.nextState);
        }

        function handleQuoteError(err) {
            vm.quoting = false;
            if (err.data.messages) {
                for (var i = 0; i < err.data.messages.length; i++) {
                    vm.errors.push(err.data.messages[i].text);
                }
            }
            else if (err.data.message) {
                vm.errors.push(err.data.message);
            }
        }

        function policyBuyerChanged() {
            // reset the policy buyer
            vm.quote.policyBuyer = {
                address: {}
            };
        }
    }
})();
