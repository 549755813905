/**
 * @ngdoc filter
 * @name saveMethodFilter
 *
 * # saveMethodFilter
 *
 * @description
 * formats saveMethod
 */
(function () {
    'use strict';

    angular.module('agentPortal')
        .filter('saveMethod', [saveMethodFilter]);

    function saveMethodFilter() {
        return function (value) {
            if (!value) { return '-'; }

            switch (value.toLowerCase()) {
                case 'quote(s) emailed' : { return 'Emailed'; break; }
                case 'auto saved' : { return 'Saved'; break; }
            }
        };
    }
})();