(function () {
    'use strict';

    angular
        .module('app.quotes')
        .directive('travelerList', function () {
            return {
                restrict: 'A',
                controllerAs: 'travelerListCtrl',
                controller: ['$scope', '$attrs', 'travelerservice', function($scope, $attrs, travelerservice) {
                    this.travelers = $scope.$eval($attrs.travelers);

                    this.removeTraveler = function removeTraveler(index) {
                        travelerservice.removeTraveler(index);
                    };
                }]
            };
        })
        .directive('copyAddress', function () {
            return {
                require: 'ngModel',
                scope: {
                    quote: '=',
                    bhtpPackage: '='
                },
                link: function (scope, elem, attrs, ctrl) {
                    function adjustAddress(checked) {
                        if (checked) {
                            if (scope.bhtpPackage === true) {
                                copyBHTPProduct();
                            }
                            else {
                                copyPartnerProduct();
                            }
                        }
                        else {
                            scope.quote.paymentInformation.address1 = null;
                            scope.quote.paymentInformation.address2 = null;
                            scope.quote.paymentInformation.city = null;
                            scope.quote.paymentInformation.state = null;
                            scope.quote.paymentInformation.zip = null;
                        }

                        scope.$apply();
                    }

                    function copyBHTPProduct() {
                        scope.quote.paymentInformation.address1 = scope.quote.primaryTraveler.address.address1;
                        scope.quote.paymentInformation.address2 = scope.quote.primaryTraveler.address.address2;
                        scope.quote.paymentInformation.city = scope.quote.primaryTraveler.address.city;
                        scope.quote.paymentInformation.state = scope.quote.primaryTraveler.address.stateOrProvince;
                        scope.quote.paymentInformation.zip = scope.quote.primaryTraveler.address.postalCode;
                    }

                    function copyPartnerProduct() {
                        scope.quote.paymentInformation.address1 = scope.quote.address1;
                        scope.quote.paymentInformation.address2 = scope.quote.address2;
                        scope.quote.paymentInformation.city = scope.quote.city;
                        scope.quote.paymentInformation.state = scope.quote.state;
                        scope.quote.paymentInformation.zip = scope.quote.zip;
                    }

                    elem.on('change', function () {
                        adjustAddress(elem[0].checked);
                    });
                }
            };
        });
})();
