(function () {
    'use strict';

    /**
     * @ngdoc factory
     * @name loadQuoteFactory
     *
     * # loadQuoteFactory
     *
     * @description
     * Formats and prepares loaded quotes to be viewed or purchased
     */

    angular.module('agentPortal')
        .factory('loadQuoteFactory', loadQuoteFactory);

    loadQuoteFactory.$inject = ['$rootScope', 'quotesService', 'portalService', 'apiUtilService', 'purchaseNavigationService', '$window', 'format', 'utilService', 'coverageUtils'];

    function loadQuoteFactory($rootScope, quotesService, portalService, apiUtilService, purchaseNavigationService, $window, format, utilService, coverageUtils) {
        
        return {
            enterPurchasePathWithExistingQuote: enterPurchasePathWithExistingQuote,
            getByNumberRated: getByNumberRated,
            getQuoteViewModel: getQuoteViewModel
        };

    async function getQuoteViewModel(quoteDetailResponse) {
        const viewModel = {};
        viewModel.event = quoteDetailResponse.event;
        viewModel.ratedQuote = quoteDetailResponse.ratedQuote;
        viewModel.customerId = viewModel.event.externalCustomerId;
        viewModel.package = quoteDetailResponse.package;
        viewModel.ratingId = quoteDetailResponse.ratingId;
        viewModel.passengers = quoteDetailResponse.event.insureds;

        if (viewModel.event) {
            viewModel.state = viewModel.event.stateIso2;
        }
        if (viewModel.event && viewModel.event.quotes && viewModel.event.quotes[0]) {
            viewModel.quote = quoteDetailResponse.event.quotes[0];
        }

        if (viewModel.passengers) {
            viewModel.primaryTraveler = viewModel.passengers.find((p) => p.isPrimary);
        }

        if (viewModel.package) {
            viewModel.isAirCare = utilService.isAircare(viewModel.package.ratingId);
        }

        if (viewModel.package && viewModel.primaryTraveler) {
            viewModel.isEmailQuote = viewModel.package.canQuoteBeEmailed && viewModel.primaryTraveler.email !== null;
        }

        const coverageBreakdown = await setupCoverages(viewModel.quote, viewModel.ratedQuote, viewModel.state, viewModel.ratingId);
        viewModel.coverages = { };

        viewModel.coverages.all = coverageBreakdown.all;
        viewModel.coverages.optional = coverageBreakdown.optional;
        viewModel.coverages.included = coverageBreakdown.included;
        viewModel.coverages.coverageWarning = coverageBreakdown.coverageWarning;
        viewModel.coverageError = coverageBreakdown.error

        viewModel.tripCost = viewModel.event.totalInsurableCost;
        viewModel.totalPrice = calculateTotalPrice(viewModel.event, viewModel.ratedQuote, coverageBreakdown);
        viewModel.totalFees = viewModel.ratedQuote && viewModel.ratedQuote.totalFees ? viewModel.ratedQuote.totalFees : 0;
        viewModel.flights = getSimplifiedFlightData(viewModel.event.flights);
        viewModel.destinations = getDestinations(viewModel.event);
        viewModel.residency = getResidency(viewModel.event);
        viewModel.carRentals = getcarRentals(viewModel.event);
        viewModel.agentNotes = getAgentNotes(quoteDetailResponse.event.quotes[0]);
        return viewModel;
    }

    /**
     * @description
     * Takes the current loaded quote and stores in session as purchaseData to be picked up by the purchase path.
     */
    function enterPurchasePathWithExistingQuote(quotePackage, customerId, quoteNumber, event, ratingId, state, passengers, coverages, flights) {
        const purchaseData = convertSavedQuoteToPurchaseData(event, ratingId, quoteNumber, customerId, state, passengers, coverages, flights);
        $window.sessionStorage.setItem('purchaseData', JSON.stringify(purchaseData));
        purchaseNavigationService.navigateToPurchase(quotePackage, customerId, quoteNumber, purchaseData.sessionId, true);
    };

    function getByNumberRated(quoteNumber) {
        return quotesService.getByNumberRated(quoteNumber);
    }

    function valueAsDollars(value) {
        return value && !isNaN(value) ? Math.trunc(value / 100) : null;
    }

    function convertSavedQuoteToPurchaseData(event, ratingId, quoteNumber, customerId, state, passengers, coverages, flights) {
        const sessionId = new Date().getTime();
        const purchaseData = {};
        setupPurchaseDataForPurchase(purchaseData, event, ratingId, sessionId, quoteNumber, customerId, state, coverages);
        setupDestinationsForPurchase(event, purchaseData);
        setupAttributesForPurchase(event, purchaseData);
        setupFlightsForPurchase(flights, purchaseData);
        setupTravelersForPurchase(passengers, purchaseData);
        setupCarRentalsForPurchase(event, purchaseData);

        return purchaseData;
    }
    
    function setupPurchaseDataForPurchase(purchaseData, event, ratingId, sessionId, quoteNumber, customerId, state, coverages) {
        purchaseData.eventId = event.id;
        purchaseData.agentCode = event.agentCode;
        purchaseData.agencyCode = event.agencyCode;
        purchaseData.ratingId = ratingId;
        purchaseData.sessionId = sessionId;
        purchaseData.quoteId = quoteNumber;
        purchaseData.quoteNumber = quoteNumber;
        purchaseData.customerId = customerId;
        purchaseData.residenceState = state;
        purchaseData.residenceStateName = null; // TO DO
        purchaseData.departureDate = event.startDate;
        purchaseData.returnDate = event.endDate;
        purchaseData.depositDate = event.initialDepositDate;
        purchaseData.purchaseForOther = false;
        purchaseData.policyBuyer = null;
        purchaseData.selectedCoverages = coverages.optional;


    }

    function setupCarRentalsForPurchase(event, purchaseData) {
        if (event.carRentals && event.carRentals[0]) {
            purchaseData.rentalCarReturnDate = event.carRentals[0].endDate;
            purchaseData.rentalCarPickupDate = event.carRentals[0].startDate;
            purchaseData.carRentalCompany = event.carRentals[0].provider;
        }
    }

    function setupAttributesForPurchase(event, purchaseData) {
        if (event.attributes) {
            event.attributes.forEach((a) => {
                switch (a.name) {
                    case 'airline':
                        purchaseData.airline = a.value;
                        break;
                    case 'tourOperator':
                        purchaseData.tourOperator = a.value;
                        break;
                    case 'carRentalCompany':
                        purchaseData.carRentalCompany = a.value;
                        break;
                    case 'cruiseLine':
                        purchaseData.cruiseLine = a.value;
                        break;
                    case 'submissionChannel':
                        purchaseData.submissionChannel = a.value;
                        break;
                    case 'flightSegments':
                        purchaseData.flightSegmentsCount = a.value;
                        break;
                }
            });
        }
    }

    function getAgentNotes(quote) {
        let agentNotes = [];

        if (quote.attributes && quote.attributes.length > 0) {
            agentNotes = quote.attributes.find((a) => a.name === 'agentNotes')?.value || [];
        }

        return agentNotes;
    }

    function setupDestinationsForPurchase(event, purchaseData) {
        if (event && event.destinations && event.destinations.length != 0) {
            purchaseData.destination = event.destinations[0].destination;
            purchaseData.destinationCountry = {
                isoCode2: event.destinations[0].countryIso2,
                name: event.destinations[0].destination
            };
            purchaseData.destinationState = event.destinations[0].stateIso2;
            purchaseData.destinationCity = event.destinations[0].city;
        }
    }

    function setupTravelersForPurchase(passengers, purchaseData) {
        if (passengers) {
            const primary = passengers.find((p) => p.isPrimary);
            purchaseData.primaryTraveler = {
                firstName: primary.firstName,
                lastName: primary.lastName,
                birthDate: primary.dateOfBirth,
                phoneNumber: primary.phone,
                tripCost: valueAsDollars(primary.insurableCost),
                emailGroup: {
                    email: primary.email,
                    noEmail: false
                },
                address: {
                    address1: primary.addressLine1,
                    address2: primary.addressLine2,
                    city: primary.city,
                    stateOrProvince: primary.stateIso2,
                    postalCode: primary.postalCode
                },
                isPrimary: true
            };
            purchaseData.additionalTravelers = passengers.filter((fp) => !fp.isPrimary).map((p) => {
                return {
                    firstName: p.firstName,
                    lastName: p.lastName,
                    birthDate: p.dateOfBirth,
                    phoneNumber: p.phoneNumber,
                    tripCost: valueAsDollars(p.insurableCost),
                    isPrimary: false
                };
            });
        }
        else {
            purchaseData.primaryTraveler = {};
            purchaseData.additionalTravelers = [];
        }
    }

    function setupFlightsForPurchase(flights, purchaseData) {
        if (flights) {
            purchaseData.flights = flights.map((f) => {
                return {
                    departureAirport: f.departureAirportIataCode,
                    arrivalAirport: f.arrivalAirportIataCode,
                    flightDate: f.departureDateIso,
                    flightNumber: f.flightNumber,
                    airline: {
                        providerCode: f.airlineIataCode,
                        name: f.airlineName
                    }
                };
            });
        }

        // If there is a flight segments Count Reconcile with number of flights
        reconcileFlightCountAndFlights(purchaseData);
    }

    function reconcileFlightCountAndFlights(purchaseData) {
        if (purchaseData.flightSegmentsCount && purchaseData.flightSegmentsCount > 0) {
            if (!purchaseData.flights) {
                purchaseData.flights = [];
            }
            const flightCount = purchaseData.flights.length;
            const flightsToAdd = purchaseData.flightSegmentsCount - flightCount;
            for (let i = 0; i <= flightsToAdd - 1; i++) {
                purchaseData.flights.push({});
            }
        }
    }

    /**
     * @description
     * initializes car rentals
     */
    function getcarRentals(event) {
        let carRentals = [];

        if (event && event.carRentals && event.carRentals.length != 0) {
            carRentals = event.carRentals.map((c) => {
                let tempCarRental = {
                    startDate: c.startDate ? format.getDisplayDateStringFromIsoString(c.startDate) : 'N/A',
                    endDate: c.endDate ? format.getDisplayDateStringFromIsoString(c.endDate) : 'N/A',
                    provider: c.provider ? c.provider : 'N/A'
                };

                if (tempCarRental.startDate !== 'N/A' ||  tempCarRental.endDate !== 'N/A' || provider !== 'N/A') {
                    return tempCarRental;
                }
            });
        }
        
        return carRentals;
    }

    /**
     * @description
     * initializes formatted residency
     */
    function getResidency(event) {
        let residency = '';

        if (event) {
            if (event.city) {
                residency += event.city + ' ';
            }
            if (event.stateIso2) {
                residency += event.stateIso2 + ',  ';
            }
            if (event.countryIso2) {
                residency += event.countryIso2;
            }
        }
        
        return residency;
    }

    /**
     * @description
     * initializes formatted destinations
     */
    function getDestinations(event) {
        let destinations = [];

        if (event && event.destinations && event.destinations.length != 0) {
            destinations = event.destinations.map((d) => {
                let tempDestination = d.destination;
                if (d.city) {
                    tempDestination += ' ' + d.city;
                }
                if (d.stateIso2) {
                    tempDestination += ' ' + d.stateIso2;
                }
                if (d.countryIso2) {
                    tempDestination += ', ' + d.countryIso2;
                }

                return tempDestination;
            });
        }
        
        return destinations;
    }
    
    /**
     * @description
     * initializes formatted flight data
     */
    function getSimplifiedFlightData(flights) {
        if (!flights || flights.length === 0) {
            return [];
        }

        // format flight dates using local date strings
        for (var i = 0; i < flights.length; i++) {
            if (flights[i].departureDate) {
                flights[i].departureDateIso = flights[i].departureDate
                flights[i].departureDate = format.getLocalDateDisplayString(flights[i].departureDate, 'MM/DD/YYYY');
            }
            if (flights[i].arrivalDate) {
                flights[i].arrivalDateIso = flights[i].arrivalDate;
                flights[i].arrivalDate = format.getLocalDateDisplayString(flights[i].arrivalDate, 'MM/DD/YYYY');
            }
        }

        return flights;
    };    

    /**
     * @description
     * initializes total price for the quote
     */
    function calculateTotalPrice(event, ratedQuote, coverageBreakdown) {
        let totalPrice = 0; 

        // First lets try and get the price from the fresh rated quote
        if (ratedQuote && ratedQuote.baseQuoteAmount && ratedQuote.baseQuoteAmount !== 0) {
            totalPrice =
                ratedQuote.baseQuoteAmount +
                ratedQuote.fees +
                ratedQuote.packageFee + 
                ratedQuote.coverageFees +
                getSelectedOptionalCoverageTotalForQuote(coverageBreakdown);
        // If there is no data on fresh rated quote to calculate price lets put in the original quoted amount
        } else if (event && event.quotes && event.quotes[0] && event.quotes[0].totalPremium !== 0) {
            totalPrice = event.quotes[0].totalPremium;
        // if there is no original quoted amount then information and a requote is required
        } else {
            totalPrice = null;
        }

        return totalPrice;
    }

    function getSelectedOptionalCoverageTotalForQuote(coverageBreakdown) {
        if (coverageBreakdown) {
            if (coverageBreakdown.optional) {
                let totalOptionalCoverageAmount = 0;
                coverageBreakdown.optional.forEach((coverage) => {
                    if (coverageUtils.isCoverageSelectedWithAllData(coverage)) {
                        totalOptionalCoverageAmount += coverageUtils.getOptionalCoveragePremium(coverage);
                    }
                });

                return totalOptionalCoverageAmount;
            }
        }

        return 0;
    }

    /**
     * @description
     * initializes coverages for display
     */
    async function setupCoverages(quote, ratedQuote, state, ratingId) {
        const coverageBreakdown = {
            all: [],
            included: [],
            optional: [],
            coverageWarning: null,
            error: null
        };

        // Check to see if we have a fresh rated quote, use those coverages if they exist
        if (ratedQuote && ratedQuote.coverages && Object.keys(ratedQuote.coverages).length > 0) {
            if (ratedQuote.coverages.optional) {
                coverageBreakdown.optional = ratedQuote.coverages.optional.filter((c) => {
                return c.isSelected;
                });
            } else {
                coverageBreakdown.optional = [];
            }
            coverageBreakdown.included = ratedQuote.coverages.included || [];

            // Add the selected limit from the original saved quote
            for (var i = 0; i < coverageBreakdown.optional.length; i++) {
                const foundCoverage = quote.coverages.find((c) => c.externalRatingId == coverageBreakdown.optional[i].ratingId)
                if (foundCoverage) {
                    coverageBreakdown.optional[i].coverageLimit = valueAsDollars(foundCoverage.selectedLimit);
                }
            }
        // Otherwise use the coverages off the existing quote and map from package
        } else if (quote && quote.coverages) {
            coverageBreakdown.coverageWarning = 'This quote needs to be requoted for the most accurate coverages.';
            let savedCoverages = {};
            let packageWithCoverages = await portalService.getPackageByStateAndRatingId(state, ratingId);
            
            if (!packageWithCoverages.ratingId)
            {
                coverageBreakdown.error = 'The selected quote references an insurance product that is no longer available.\nPlease start a new quote.';
                
                return;
            }

            savedCoverages.packageCoverages = packageWithCoverages.coverages

            savedCoverages.optional = quote.coverages.filter((c) => {
                return c.type === 'Upgrade' || c.type === 'Extra Upgrade';
            });
            savedCoverages.included = quote.coverages.filter((c) => {
                return c.type !== 'Upgrade' && c.type !== 'Extra Upgrade';
            });
        
            const mappedCoverages = mapSavedCoveragesToRatedCoverages(savedCoverages);
            coverageBreakdown.optional = mappedCoverages.optional;
            coverageBreakdown.included = mappedCoverages.included;
        // If there are no coverages it means not enough data was saved on the quote
        } else {
            coverageBreakdown.optional = [];
            coverageBreakdown.included = [];
        }

        coverageBreakdown.all = coverageBreakdown.included.concat(coverageBreakdown.optional);

        function mapSavedCoveragesToRatedCoverages(coverages) {
            let mappedCoverages = { included: [], optional: [] };
            if (coverages.packageCoverages) {
                let coverageMapper = (c) => {
                    let foundCoverage = coverages.packageCoverages.find((canidate) => {
                        return (canidate.externalRatingId === c.externalRatingId || canidate.ratingId === c.externalRatingId);
                    });
                    if (foundCoverage) {
                        foundCoverage.ratingId = c.externalRatingId ? c.externalRatingId : c.ratingId;
                        if(foundCoverage.type === 'Upgrade' || foundCoverage.type === 'Extra Upgrade') {
                            foundCoverage.isSelected = true;
                        }
                
                        return foundCoverage;
                    }
                };
                mappedCoverages.included = coverages.included.map(coverageMapper);
                mappedCoverages.optional = coverages.optional.map(coverageMapper);
            }

            return mappedCoverages;
        }

        return coverageBreakdown;
    }
}
})();
