/* global moment:false */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .directive('validBookingDate', validBookingDate)
        .directive('validStartDate', validStartDate)
        .directive('validTimeshareDepartureDate', validTimeshareDepartureDate)
        .directive('validDepartureDate', validDepartureDate)
        .directive('validReturnDate', validReturnDate)
        .directive('validPhoneNumber', validPhoneNumber)
        .directive('validTripCost', validTripCost)
        .directive('validPostalCode', validPostalCode)
        .directive('validCarReturnDate', validCarReturnDate)
        .directive('validCarPickupDate', validCarPickupDate)
        .directive('validDepositDate', validDepositDate);

    function validBookingDate() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.inPast = function bookingMaxDate(modelValue, viewValue) {
                    return maxDate(modelValue, viewValue, new Date());
                };
            }
        };
        return directive;
    }

    function validStartDate() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        return directive;

        function link(scope, elem, attrs, ctrl) {
            ctrl.$validators.withinLastYear = function startDateMinDate(modelValue, viewValue) {
                return minDate(modelValue, viewValue, moment(new Date())
                            .subtract(365, 'days').format('MM/DD/YYYY'));
            };
        }
    }

    function validTimeshareDepartureDate() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var departureDateMinDate = function departureDateMinDate(modelValue, viewValue) {
                    return minDate(modelValue, viewValue, attrs.startDate);
                };

                var departureDateMaxDate = function departureDateMaxDate(modelValue, viewValue) {
                    return exactDate(new Date(), attrs.bookingDate) ||
                        minDate(modelValue, viewValue, moment(new Date(attrs.bookingDate))
                        .add(10, 'days').format('MM/DD/YYYY'));
                };

                ctrl.$validators.onOrAfterStartDate = departureDateMinDate;

                ctrl.$validators.lessThanBooking = departureDateMaxDate;
                attrs.$observe('startDate', function (value) {
                    if (value) {
                        var isValid = minDate(ctrl.$modelValue, ctrl.$viewValue, value);
                        ctrl.$setValidity('onOrAfterStartDate', isValid);
                    }
                });

                attrs.$observe('bookingDate', function (value) {
                    if (value) {
                        var isValid = exactDate(value, new Date()) ||
                            minDate(ctrl.$modelValue, ctrl.$viewValue, moment(new Date(value))
                            .add(10, 'days').format('MM/DD/YYYY'));

                        ctrl.$setValidity('lessThanBooking', isValid);
                    }
                });
            }
        };
        return directive;
    }

    function validDepartureDate() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.inPast = function departureDateMaxDate(modelValue, viewValue) {
                    return minDate(modelValue, viewValue, new Date());
                };
            }
        };
        return directive;
    }

    function validReturnDate() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var returnDateMinDate = function returnDateMinDate(modelValue, viewValue) {
                    return minDate(modelValue, viewValue, attrs.departureDate);
                };

                ctrl.$validators.onOrAfterDepartureDate = returnDateMinDate;

                attrs.$observe('departureDate', function (value) {
                    if (value) {
                        var isValid = minDate(ctrl.$modelValue, ctrl.$viewValue, value);
                        ctrl.$setValidity('onOrAfterDepartureDate', isValid);
                    }
                });
            }
        };
        return directive;
    }

    function validPhoneNumber() {
        var directive = {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.validPhone = function (modelValue, viewValue) {
                    var regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                    return !(viewValue || modelValue) || regexMatch(modelValue, viewValue, regex);
                };
            }
        };
        return directive;
    }

    function validTripCost() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.validNumber = validNumber;

                ctrl.$validators.greaterThanZero = function (modelValue, viewValue) {
                    return minValue(modelValue, viewValue, 0);
                };

                ctrl.$validators.lessThanMax = function (modelValue, viewValue) {
                    return maxValue(modelValue, viewValue, 100000);
                };
            }
        };
        return directive;
    }

    validPostalCode.$inject = ['$q', 'dataservice'];
    function validPostalCode($q, dataservice) {
        var directive = {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.validFormat = function validFormat(modelValue, viewValue) {
                    var regex = /^\d{5}$/;
                    return !(viewValue || modelValue) || regexMatch(modelValue, viewValue, regex);
                };

                ctrl.$asyncValidators.validZip = function validZip(modelValue, viewValue) {
                    return verifyZipExistsForState(modelValue, viewValue, attrs.state);
                };

                attrs.$observe('state', function (value) {
                    verifyZipExistsForState(ctrl.$modelValue, ctrl.$viewValue, value)
                        .then(function (data) {
                            ctrl.$setValidity('validZip', true);
                        })
                        .catch(function (error) {
                            ctrl.$setValidity('validZip', false);
                        });
                });

                function verifyZipExistsForState(modelValue, viewValue, state) {
                    var value = viewValue || modelValue;

                    if (value) {
                        if (state) {
                            return dataservice.verifyZipWithState(value, state)
                                .then(function (result) {
                                    //If we got a result lets check it, otherwise assume it is valid
                                    //this is done in case the service is down we still want to register customers
                                    if (result.data === "false") {
                                        return $q.reject();
                                    }

                                    return $q.resolve();
                                });
                        } else {
                            return dataservice.verifyZip(value)
                                .then(function (result) {
                                    //If we got a result lets check it, otherwise assume it is valid
                                    //this is done in case the service is down we still want to register customers
                                    if (result.data === false) {
                                        return $q.reject();
                                    }

                                    return $q.resolve();
                                });
                        }
                        // Return valid if no value is present yet
                    } else {
                        return $q.resolve();
                    }
                }
            }
        };
        return directive;
    }

    validCarReturnDate.$inject = ['moment'];
    function validCarReturnDate(moment) {
        var directive = {
            restict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var returnDateMinDate = function returnDateMinDate(modelValue, viewValue) {
                    return attrs.validCarReturnDate === 'false' || exactDate(modelValue ||
                        viewValue, attrs.carPickupDate) || minDate(modelValue, viewValue, attrs.carPickupDate);
                };

                var returnDateMaxDate = function returnDateMaxDate(modelValue, viewValue) {
                    return attrs.validCarReturnDate === 'false' || exactDate(modelValue ||
                        viewValue, attrs.tripReturnDate) || maxDate(modelValue, viewValue, attrs.tripReturnDate);
                };

                var returnDateTripMinDate = function returnDateTripMinDate(modelValue, viewValue) {
                    return attrs.validCarReturnDate === 'false' || exactDate(modelValue ||
                        viewValue, attrs.tripDepartureDate) || minDate(modelValue, viewValue, attrs.tripDepartureDate);
                };

                ctrl.$validators.onOrAfterPickupDate = returnDateMinDate;
                ctrl.$validators.beforeTripEnds = returnDateMaxDate;
                ctrl.$validators.afterTripStarts = returnDateTripMinDate;

                attrs.$observe('carPickupDate', function (value) {
                    if (value) {
                        var isValid = attrs.validCarReturnDate === 'false' ||
                            exactDate(value, ctrl.$modelValue || ctrl.$viewValue) ||
                            minDate(ctrl.$modelValue, ctrl.$viewValue, value);

                        ctrl.$setValidity('onOrAfterPickupDate', isValid);
                    }
                });
            }
        };

        return directive;
    }

    validCarPickupDate.$inject = ['moment'];
    function validCarPickupDate(moment) {
        var directive = {
            restict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {

                var pickupDateMinDate = function pickupDateMinDate(modelValue, viewValue) {
                    return attrs.validCarPickupDate === 'false' || exactDate(modelValue ||
                        viewValue, attrs.tripDepartureDate) || minDate(modelValue, viewValue, attrs.tripDepartureDate);
                };

                var pickupDateMaxDate = function pickupDateMaxDate(modelValue, viewValue) {
                    return attrs.validCarPickupDate === 'false' || exactDate(modelValue ||
                        viewValue, attrs.tripReturnDate) || maxDate(modelValue, viewValue, attrs.tripReturnDate);
                };

                ctrl.$validators.afterTripStarts = pickupDateMinDate;
                ctrl.$validators.beforeTripEnds = pickupDateMaxDate;
            }
        };

        return directive;
    }

    function validDepositDate() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.inPast = function depositMaxDate(modelValue, viewValue) {
                    return maxDate(modelValue, viewValue, new Date());
                };
            }
        };
        return directive;
    }

    // #region Date Validation Functions
    function exactDate(value, toCompare) {
        var valueMoment = moment(new Date(value));
        var toCompareMoment = moment(new Date(toCompare));

        return valueMoment.isSame(toCompareMoment, 'day');
    }

    function minDate(modelValue, viewValue, toCompare) {
        var value = viewValue || modelValue;
        var valueMoment = moment(new Date(value));
        var toCompareMoment = moment(new Date(toCompare));

        return !valueMoment.isBefore(toCompareMoment, 'days');
    }

    function maxDate(modelValue, viewValue, toCompare) {
        var value = viewValue || modelValue;
        var valueMoment = moment(new Date(value));
        var toCompareMoment = moment(new Date(toCompare));

        return !valueMoment.isAfter(toCompareMoment, 'days');
    }

    function sameYear(modelValue, viewValue, toCompare) {
        var value = viewValue || modelValue;
        var valueYear = moment(new Date(value)).year();

        return valueYear === toCompare;
    }

    // #endregion
    // #region Number Validation Functions
    function validNumber(modelValue, viewValue) {
        var value = viewValue || modelValue;
        return !isNaN(value);
    }

    function minValue(modelValue, viewValue, toCompare) {
        var value = viewValue || modelValue;
        return value >= toCompare;
    }

    function maxValue(modelValue, viewValue, toCompare) {
        var value = viewValue || modelValue;
        return value <= toCompare;
    }
    // #endregion
    // #region String Validation Functions
    function regexMatch(modelValue, viewValue, regex) {
        var value = viewValue || modelValue;
        return regex.test(value);
    }
    // #endregion
    // #region Other Validation Functions
    // #endregion
})();
