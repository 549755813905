/**
 * @ngdoc filter
 * @name submissionFilter
 *
 * # submissionFilter
 *
 * @description
 * formats submission channel
 */
(function () {
    'use strict';

    angular.module('agentPortal')
        .filter('submission', [submissionFilter]);

    function submissionFilter() {
        return function (value) {
            if (!value) { return '-'; }

            switch (value.toLowerCase()) {
                case 'agents' : { return 'Agent Portal'; break; }
                case 'web' : { return 'Consumer Website'; break; }
            }
        };
    }
})();