(function () {
    'use strict';

    var id = 'contactService';

    /**
     * @ngdoc factory
     * @name contactService
     *
     * # contactService
     *
     * @description
     * provides backend API integration for contact us page
     */

    angular.module('agentPortal').factory(id, ['$resource', '$q', 'portalService', 'apiUtilService', 'globalSettingsService', contactService]);

    function contactService($resource, $q, portalService, apiUtilService, globalSettingsService) {
        var contentUri = globalSettingsService.clientsApiBaseUrl() + '/v1/agents/content/:contentKey';
        var contactUsEmailUri = globalSettingsService.apiBaseUrl() + '/v1/agents/contactus';

        return {
            loadCategoryInfo: getContactOptions,
            sendContactRequest: sendContact,
            getContacts: getContacts
        };

        /**
         * @description
         * retrieves the static contacts from the API
         */
        function getContacts() {
            var deferred = $q.defer();

            getContent('AgentContactInformation')
                .then(function (content) {
                        var contacts = [];

                        for (var i = 0; i < content.length; i++) {
                            var tokens = content[i].content.split('|');
                            contacts.push({
                                // title starts with a 2 digit number for sorting, so contact info will remain in a specified order.
                                title: content[i].title.substring(2),
                                sortOrder: content[i].title.substring(0, 2),
                                contents: tokens
                            });
                        }

                        deferred.resolve(contacts);
                    },
                    function (error) {
                        deferred.reject(error);
                });

            return deferred.promise;
        }

        /**
         * @description
         * retrieves the question type categories for the list box.
         */
        function getContactOptions() {
            var deferred = $q.defer();

            getContent('AgentsContactUs')
                .then(function (content) {
                    var categories = [];

                    for (var i = 0; i < content.length; i++) {
                        var item = content[i];
                        categories.push({
                            name: item.title.trim(),
                            value: item.content.trim()
                        });
                    }

                    deferred.resolve(categories);
                },
                function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getContent(key) {
            var deferred = $q.defer();

            $resource(contentUri, { contentKey: key })
                .get()
                .$promise
                .then(function (contentResponse) {
                        if (!contentResponse) {
                            deferred.reject('Error loading content for key "' + key + '".');
                        }
                        else if (apiUtilService.areThereApiErrorMessages(contentResponse.messages)) {
                            deferred.reject(contentResponse.messages[0]);
                        }
                        else {
                            deferred.resolve(contentResponse.response);
                        }
                    },
                    function (error) {
                        deferred.reject('Error loading content for key "' + key + '".');
                });

            return deferred.promise;
        }

        /**
         * @description
         * posts the filled-contact-us form to server which in turn sends email to concerned parties.
         */
        function sendContact(option, text) {

            var content = {
                subject: option,
                body: text
            };

            var deferred = $q.defer();
            $resource(contactUsEmailUri, {}, { post: { method: 'POST' } })
                .post(content)
                .$promise
                .then(function (sendEmailResponse) {
                    if (!sendEmailResponse) {
                        deferred.reject('Error sending contact us email.');
                    }
                    else if (apiUtilService.areThereApiErrorMessages(sendEmailResponse.messages)) {
                        deferred.reject(sendEmailResponse.messages[0]);
                    }
                    else if (sendEmailResponse && !sendEmailResponse.response.wasSuccessful) {
                        deferred.reject('Error sending contact us email.');
                    }
                    else {
                        deferred.resolve(sendEmailResponse.response);
                    }
                },
                function (error) {
                    deferred.reject('Error sending contact us email.');
                });

            return deferred.promise;
        }
    }
})();
