(function() {
    'use strict';

/**
 * @ngdoc config
 * @name datepickerConfig
 *
 * # datepickerConfig
 *
 * @description
 * configuration for date pickers across the board
 */
   
    angular.module('agentPortal')
        .config(['uibDatepickerConfig', 'uibDatepickerPopupConfig', 'settings', uiConfig]);

    function uiConfig(uibDatepickerConfig, uibDatepickerPopupConfig, settings) {
        uibDatepickerConfig.showWeeks = false;
        uibDatepickerPopupConfig.showButtonBar = false;
        uibDatepickerPopupConfig.datepickerPopup = settings.date.format;
        uibDatepickerPopupConfig.closeOnDateSelection = true;
    }
})();
