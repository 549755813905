(function () {
    'use strict';

    angular
      .module('vacationguard')
      .controller('PrivacyController', PrivacyController);

    PrivacyController.$inject = ['privacy'];
    function PrivacyController(privacy) {
        var vm = this;

        vm.privacy = privacy;
    }

})();
