(function () {
    'use strict';

    angular
        .module('app.components')
        .directive('countrySelect', function () {
            return {
                templateUrl: 'app/vacationguard/components/forms/countryselect.html',
                restrict: 'E',
                scope: {
                    selectedValue: '='
                },
                controller: CountrySelectController,
                controllerAs: 'countryCtrl',
                bindToController: true,
                link: function (scope, elem, attrs) {
                    scope.countryCtrl.display = (attrs.bhDisplay) ? attrs.bhDisplay : 'name';
                    scope.countryCtrl.name = attrs.name;
                    scope.countryCtrl.value = (attrs.bhValue) ? attrs.bhValue : 'isoCode2';

                    scope.$watch(function () {
                        return attrs.disabled;
                    }, function (value) {
                        scope.countryCtrl.disabled = typeof value !== 'undefined' && value !== false;
                    });

                    scope.$watch(function () {
                        return attrs.required;
                    }, function (value) {
                        scope.countryCtrl.required = typeof value !== 'undefined' && value !== false;
                    });
                }
            };

        });

    CountrySelectController.$inject = ['dataservice'];

    function CountrySelectController(dataservice) {
        var vm = this;

        activate();

        function activate() {
            dataservice.getCountries()
                .then(function getCountriesComplete(data) {
                    vm.countries = data;
                });
        }
    }
})();
