(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policyHeader', policyHeader);

    function policyHeader() {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/policies/policyheader.html',
            scope: {
                policy: '=bhPolicy',
                flights: '=bhFlights'
            },
            bindToController: true,
            controllerAs: 'headerCtrl',
            controller: HeaderController
        };
        return directive;
    }

    function HeaderController() {

    }

})();
