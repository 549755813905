(function () {
    'use strict';

    angular
      .module('app.components')
      .directive('claimConfigurationSearch', claimConfigurationSearch);

    claimConfigurationSearch.$inject = ['$parse'];
    function claimConfigurationSearch($parse) {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/claims/claimconfigurationsearch.html',
            bindToController: true,
            controller: SearchController,
            controllerAs: 'searchCtrl',
            link: link
        };

        function link(scope, element, attrs) {
            var searchCtrl = scope.searchCtrl;

            scope.$watch(function () {
                return attrs.bhPolicySearch;
            }, function (bhPolicySearch) {
                if (bhPolicySearch) {
                    var search = $parse(bhPolicySearch)(scope);

                    searchCtrl.search.policyNumber = search.policyNumber;
                    searchCtrl.search.postalCode = search.postalCode;
                    searchCtrl.search.emailAddress = search.emailAddress;
                }
            }, true);
        }

        SearchController.$inject = ['policySearch', 'claimconfiguration', 'claim'];
        function SearchController(policySearch, claimconfiguration, claim) {
            var vm = this;
            vm.search = {};

            vm.submitForm = submitForm;

            function submitForm(formValid) {
                if (formValid === true) {
                    vm.searching = true;
                    vm.errorMessage = null;

                    policySearch.set(vm.search);
                    claimconfiguration.reset();
                    claim.reset();

                    claimconfiguration.search(vm.search)
                        .then(null, function handleSearchError(error) {
                            vm.errorMessage = error;
                        })
                        .finally(function () {
                            vm.searching = false;
                        });
                }
            }
        }

        return directive;
    }
})();

