(function () {
    'use strict';

    angular
      .module('components.policies')
      .directive('policyHolder', policyHolder);

    policyHolder.$inject = [];
    function policyHolder() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/vacationguard/components/policies/policyholder.html',
            scope: {},
            controller: PolicyHolderController,
            controllerAs: 'holderCtrl',
            bindToController: true
        };
        return directive;
    }

    PolicyHolderController.$inject = ['$location', 'policy'];

    function PolicyHolderController($location, policy) {
        var vm = this;
        vm.policy = null;

        policy.policySubject.subscribe(function (policy) {
            this.policy = policy;
        }.bind(vm));

        activate();

        function activate() {
            vm.policyNumber = $location.search().policyNumber;
            vm.postalCode = $location.search().postalCode;
            vm.emailAddress = $location.search().emailAddress;
        }
    }

})();
