(function () {

    angular
        .module('app.quotes')
        .directive('bhtpQuoteSummary', bhtpQuoteSummary);

    function bhtpQuoteSummary() {
        var directive = {
            restrict: 'EA',
            scope: {
                quote: '=',
                currentPackage: '=',
                currentQuoteResponse: '='
            },
            templateUrl: 'app/vacationguard/quotes/bhtp/bhtpQuoteSummary.html',
            replace: true,
            bindToController: true,
            controllerAs: 'vm',
            controller: BhtpQuoteSummaryController
        };
        return directive;
    }

    BhtpQuoteSummaryController.$inject = ['dataservice', '$state'];

    function BhtpQuoteSummaryController(dataservice, $state) {
        var vm = this;
        vm.getCountryByCode = getCountryByCode;
        vm.getCurrentState = getCurrentState;

        if (vm.quote && vm.quote.policy && vm.quote.policy.destinationCountry) {
            vm.destination = vm.getCountryByCode(vm.quote.policy.destinationCountry);
        }

        function getCurrentState() {
            return $state.$current.name;
        }

        function getCountryByCode(code) {
            return dataservice.getCountryByCode(code);
        }
    }

})();
