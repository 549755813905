(function () {
    'use strict';

    angular
        .module('app')
        .factory('agents', agents);

    agents.$inject = ['$q', 'dataservice', '$cookies'];

    function agents($q, dataservice, $cookies) {
        var service = {
            getCurrentAgentCode: getCurrentAgentCode,
            setCurrentAgentCode: setCurrentAgentCode,
            getCurrentAgent: getCurrentAgent,
            setCurrentAgent: setCurrentAgent,
            refreshCurrentAgent: refreshCurrentAgent,
            removeAgentCookies: removeAgentCookies
        };
        return service;

        function setCurrentAgent(agent) {
            $cookies.put('agent', angular.toJson(agent), { secure: true });
        }

        function getCurrentAgent() {
            if ($cookies.get('agent')) {
                return $q.when($cookies.getObject('agent'));
            }

            return refreshCurrentAgent(getCurrentAgentCode());
        }

        function refreshCurrentAgent(agentCode) {
            if (agentCode) {
                setCurrentAgentCode(agentCode);
            }

            return dataservice.getAgent(getCurrentAgentCode())
                .then(function (agent) {
                    setCurrentAgent(null);
                    setCurrentAgent(agent);
                    setCurrentAgentCode(agent.agentCode);

                    return agent;
                });
        }

        function setCurrentAgentCode(agentCode) {
            $cookies.put('agentCode', agentCode, { secure: true });
        }

        function getCurrentAgentCode() {
            if ($cookies.get('agentCode')) {
                return $cookies.get('agentCode');
            }

            return null;
        }

        function removeAgentCookies() {
            if ($cookies.get('agent')) {
                $cookies.remove('agent');
            }

            if ($cookies.get('agentCode')) {
                $cookies.remove('agentCode');
            }
        }
    }

})();
