(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name profileController
     *
     * # profileController
     *
     * @description
     * provides profile related functions on top-navigation bar
     */
    angular.module('agentPortal')
        .controller('profileController', ['$stateParams', '$scope', '$uibModal', 'settings', '$timeout', '$filter',
                                            'agentService', 'utilService', 'portalService', 'storage', 'intentService',
                                            'authService', profileController]);

    function profileController($stateParams, $scope, $modal, settings, $timeout, $filter,
                                agentService, utilService, portalService, storage, intentService,
                                authService) {
        var vm = this;

        vm.title = 'Profile';
        vm.agent = {};
        vm.phoneMask = {};
        vm.editMode = false;
        vm.change = null;
        vm.currentAgent = {};
        vm.updatedAgent = null;
        vm.states = [];

        /**
         * @description
         * initialization - loads fresh agent information from server
         */
        function init() {
            initMasks();

            portalService.getAgentByInternalId().then(function (agent) {
                vm.currentAgent = agent;
                loadAgent();

                portalService.loadStates().then(function (stateData) {
                    vm.states = stateData;
                });
            });
        };

        /**
         * @description
         * initalizes mask for phone number field
         */
        function initMasks() {
            vm.phoneMask = settings.masks.phone;
        }

        /**
         * @description
         * returns formatted phone number via filter
         */
        function getPhoneNumber(phoneNumber) {
            if (!phoneNumber) {
                return "";
            }

            return $filter('phoneNumber')(phoneNumber);
        };

        /**
         * @description
         * sets scope to enable editing of agent's profile information
         */
        vm.editProfile = function () {
            vm.updatedAgent = angular.copy(vm.agent);
            $scope.agentAdddressForm.$setPristine();
            vm.editMode = true;
            $timeout(function () { $('input[name="street"]').focus(); }, 100);
        };

        /**
         * @description
         * sets up scope to cancel the editing of agent's profile information
         */
        vm.cancelEdit = function () {
            vm.editMode = false;
            vm.updatedAgent = null;
            intentService.resetIntent();
        };

        /**
         * @description
         * saves agent's edited profile information back to the server
         */
        vm.saveProfile = function () {
            intentService.setIntent("Saving Agent Information ...");
            var updatedProfile = angular.copy(vm.updatedAgent);

            updatedProfile.phoneNumber = getPhoneNumber(vm.updatedAgent.phoneNumber);
            agentService.saveAgent(updatedProfile)
                .then(function () {
                    intentService.resetIntent();
                    loadAgent();
                }, function (reason) {
                    intentService.resetIntent();
                    console.warn("Error in saving agent: %o", reason);
                    utilService.showPopup("Error", "Failed while trying to save agent information.");
                    vm.cancelEdit();
                });
        };

        /**
         * @description
         * loads agent's fresh information from the server
         */
        function loadAgent() {
            agentService.getAgent(vm.currentAgent.agentCode).then(function (agent) {
                vm.agent = agent;
                vm.cancelEdit();
            }, function (error) {
                console.warn("Error in retrieving agent : %o", error);
                utilService.showPopup("Error", "Failed while trying to retrieve the agent's information.");
            });
        };

        /**
         * @description
         * opens change password modal
         */
        vm.changePassword = function () {
            var agentEmail = vm.currentAgent.emailAddress;
            var confirmMessage = 'You will receive an email at ' + agentEmail + ' with instructions for how to change your password.\nAre you sure you want to change it?';

            var cancelButton = {
                style: "btn btn-lg btn-default btn-cust-sec",
                action: function (f) {
                    // Nothing Happens
                    return false;
                },
                name: "No"
            };

            var okButton = {
                style: "btn btn-lg btn-default btn-cust",
                action: function (f) {
                    initiatePasswordChange(agentEmail);
                },
                name: "Yes"
            };

            utilService.showConfirmWithOptionsPopupNoMessages('Password Change', confirmMessage, [cancelButton, okButton]);

        };

        function initiatePasswordChange(agentEmail) {
            authService.reset(agentEmail,
                function () {
                    var customIcon = "fa-exclamation-circle fa-icon-medium";

                    utilService.showPopup('Password Change Initiated', 'Please check your email to continue the password change process.', customIcon);
                },
                function (error) {
                    utilService.showPopup('Error', 'There was an error while initiating the password change: ' + error);
                }
            );
        }

        init();
    };
})();