(function () {
    'use strict';

    angular
        .module('agentPortal')
        .factory('eligibilityFactory', ['quotesService', 'storage', '$q', 'format', eligibilityFactory]);


    function eligibilityFactory(quotesService, storage, $q, format) {
        var airCareV2RatingId = '4';
        var airCareAbroadRatingId = '3';

        var factory = {
            getEligibleQuotes: getEligibleQuotes
        };

        return factory;

        function getPackageByRatingId(ratingId) {
            return quotesService.getProducts().then(function (products) {
                return products.filter(function (pkg) {
                    if (pkg.ratingId) {
                        return pkg.ratingId == ratingId;
                    }
                })[0];
            });
        }

        function getRecommendation(messages) {
            if (!messages) {
                return null;
            }

            for (var i = 0; i < messages.length; i++) {
                switch (messages[i].code) {
                    case '8003':
                    case '8004':
                        return messages[i];
                }
            }

            // recommendation not found.
            return null;
        }

        function getRecommendationsObject(packageName, errorMessage) {
            return {
                '8003': {
                    prompt: 'Would you like to switch to ' + packageName + '?',
                    confirm: 'Yes',
                    dismiss: 'No',
                    action: action2621,
                    apiErrorMessage: errorMessage
                },
                '8004': {
                    prompt: 'Would you like to switch to ' + packageName + '?',
                    confirm: 'Yes',
                    dismiss: 'No',
                    action: action2619,
                    apiErrorMessage: errorMessage
                }
            };
        }

        /**
         * @description
         * Filters responses from eligibility that should not be customer facing.
         */
        function verifyResponseMessage(messageResponse) {
            var messageOk = true;

            if (messageResponse.indexOf('Trip blocked') > -1) {
                messageOk = false;
            }

            if (messageResponse.indexOf('Eligible Flight') > -1) {
                messageOk = false;
            }

            if (messageResponse.indexOf('but this product does not cover flights outside of the United States') > -1) {
                messageOk = false;
            }

            if (messageResponse.indexOf('This product requires at least one flight that originates from or terminates at an international destination.') > -1) {
                messageOk = false;
            }

            if (messageResponse.indexOf('Try AirCare Abroad') > -1) {
                messageOk = false;
            }

            if (messageResponse.indexOf('Try AirCare') > -1) {
                messageOk = false;
            }

            return messageOk;
        }

        function isMessageInMessageList(eligibilityResponse, message) {
            for (var i = 0; i < eligibilityResponse.length; i++) {
                if (eligibilityResponse[i].text === message.text) {
                    // found a duplicate.
                    return true;
                }
            }

            // no duplicates
            return false;
        }

        /**
         * @description
         * takes an array of questes, packagesConfiguration and returns eligible quotes
         */
        function getEligibleQuotes(quote) {
            var pkg = quote.package;
            var packageConfiguration = quote.packageConfiguration;
            var productRules = packageConfiguration.productRules;
            var departureDate = moment(quote.policy.departureDates.localized.dateString, 'YYYY-MM-DD', true);
            var returnDate = moment(quote.policy.returnDates.localized.dateString, 'YYYY-MM-DD', true);

            for (var i = 0; i < quote.travelers.length; i++) {
                var age = moment().diff(quote.travelers[i].birthDate, 'years');
                if ((productRules.minimumAge != null && age < productRules.minimumAge) || (productRules.maximumAge != null && age > productRules.maximumAge)) {
                    // minimumAge or maximumAge Eligibility Failed
                    return false;
                }

                if ((productRules.minimumTripCost != null && quote.travelers[i].tripCost < productRules.minimumTripCost) || (productRules.maximumTripCost != null && quote.travelers[i].tripCost > productRules.maximumTripCost)) {
                    // trip cost eligibility failed
                    return false;
                }
            }

            var tripLength = returnDate.diff(departureDate, 'days');
            if ((productRules.minimumTripLength != null && tripLength < productRules.minimumTripLength) || (productRules.maximumTripLength != null && tripLength > productRules.maximumTripLength)) {
                // TripLength Eligibility Failed
                return false;
            }

            // check for exempt country
            if (productRules.exemptCountries != null) {
                for (var i = 0; i < productRules.exemptCountries.length; i++) {
                    if (quote.policy.destinationCountry.toLowerCase() == productRules.exemptCountries[i].toLowerCase()) {
                        // Country Eligibility Failed
                        return false;
                    }
                }
            }

            return quote;
        }

        //#region Actions
        function action2619(state) {
            getPackageByRatingId(airCareV2RatingId).then(function (domestic) {
                setPackage(state, domestic);
            });
        }

        function action2621(state) {
            getPackageByRatingId(airCareAbroadRatingId).then(function (abroad) {
                setPackage(state, abroad);
            });
        }

        function setPackage(state, objpackage) {

            state.packageId = objpackage.id;
            state.subTitle = objpackage.subTitle

            storage.set('aircare.state', state);
        }
        //#endregion
    }
})();
