(function () {
    'use strict';

    angular
      .module('app.core')
      .service('datecalculation', datecalculation);

    datecalculation.$inject = ['moment'];
    function datecalculation(moment) {
        var service = {
            calculateYearMinusDay: calculateYearMinusDay
        };

        return service;

        function calculateYearMinusDay(dateString) {
            var date = new Date(dateString);
            var calculatedDate = new Date(date);
            calculatedDate.setFullYear(date.getFullYear() + 1);
            calculatedDate.setDate(date.getDate() - 1);
            return moment(calculatedDate).format('MM/DD/YYYY');
        }
    }

})();
