(function () {
    'use strict';

    angular
        .module('app.components')
        .directive('stateSelect', function () {
            return {
                templateUrl: 'app/vacationguard/components/forms/stateselect.html',
                restrict: 'E',
                scope: {
                    selectedValue: '=',
                    stateChanged: '&'
                },
                controller: StateSelectController,
                controllerAs: 'stateCtrl',
                bindToController: true,
                link: function (scope, elem, attrs) {
                    scope.stateCtrl.display = (attrs.bhDisplay) ? attrs.bhDisplay : 'name';
                    scope.stateCtrl.name = attrs.name;
                    scope.stateCtrl.value = (attrs.bhValue) ? attrs.bhValue : 'isoCode2';

                    scope.$watch(function () {
                        return attrs.disabled;
                    }, function (value) {
                        scope.stateCtrl.disabled = typeof value !== 'undefined' && value !== false;
                    });

                    scope.$watch(function () {
                        return attrs.required;
                    }, function (value) {
                        scope.stateCtrl.required = typeof value !== 'undefined' && value !== false;
                    });

                    // ng-change isn't working, so just bind to the element's change event
                    if (attrs.stateChanged) {
                        elem.bind('change', function () {
                            scope.stateCtrl.stateChanged();
                        });
                    }
                }
            };

        });

    StateSelectController.$inject = ['dataservice'];

    function StateSelectController(dataservice) {
        var vm = this;

        activate();

        function activate() {
            dataservice.getStates()
                .then(function getStatesComplete(data) {
                    vm.states = data;
                });
        }
    }

})();
