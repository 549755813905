/* global enrollmentForm:false */
/* jshint -W072 */
/* jshint -W074 */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .controller('EnrollmentFormController', EnrollmentFormController)
        .controller('StartDateModalController', StartDateModalController);

    EnrollmentFormController.$inject = [
        '$q', 'dataservice', '$stateParams', 'packageNames', '$uibModal', '$state', 'quotes', 'moment', 'dateFilter',
        'partnerCustomerService', 'datecalculation', 'agents', 'packageDisplay', '$timeout', 'messages'
    ];

    function EnrollmentFormController(
        $q, dataservice, $stateParams, packageNames, $modal, $state, quotes, moment, dateFilter,
        partnerCustomerService, datecalculation, agents, packageDisplay, $timeout, messages) {

        var vm = this;
        vm.title = 'Get a Quote';
        vm.quote = quotes.getCurrentQuoteOnReload();
        vm.errors = [];
        vm.currentPackage = quotes.getCurrentPackage();
        vm.loadedQuote = false;
        vm.uibDateFormat = "MM/dd/yyyy";
        vm.momentDateFormat = "MM/DD/YYYY"; 
        vm.datePickers = {
            startDate: false,
            departureDate: false,
            returnDate: false,
            bookingDate: false
        };

        //this address object is used by child directive of type postalcodeform
        vm.postalCodeFormModel = {};

        vm.quote.packageName = $stateParams.packageName.toLowerCase();

        switch (vm.quote.packageName) {
            case packageNames.timeshareplus:
            case packageNames.timeshareplusmid:
            case packageNames.timeshareplushigh:
            case packageNames.timesharepluslow:
            case packageNames.timeshareannual1:
            case packageNames.timeshareannual2:
                vm.travelFormTemplate = 'timeshare';
                break;
            default:
                vm.travelFormTemplate = 'default';
                break;
        }

        //* Functions exposed to View
        vm.openDatePicker = openDatePicker;
        vm.openStartDatePicker = openStartDatePicker;
        vm.submitForm = submitForm;
        vm.openStartDateModal = openStartDateModal;
        vm.showTripCost = showTripCost;
        vm.showReservationId = showReservationId;
        vm.getQuote = getQuote;
        vm.policyBuyerChanged = policyBuyerChanged;
        vm.onPostalChange = onPostalChange;

        init();

        vm.stringDate = function ($event, source) {
            if (vm.quote[source + 'Full']) {
                // if it came from the datepicker, its a date and we want a string version of it
                if (angular.isDate(vm.quote[source + 'Full'])) {
                    vm.quote[source] = moment(vm.quote[source + 'Full']).format(vm.momentDateFormat);
                }
                else {
                    // if it was typed, we'll want the date version too so the field doesn't get wiped out
                    vm.quote[source] = vm.quote[source + 'Full'];
                    vm.quote[source + 'Full'] = moment(vm.quote[source], vm.momentDateFormat);
                }
            }
        };

        //* Method Declarations
        function init() {
            var currentYear = new Date().getFullYear();
            vm.validUseYears = [(currentYear - 1) + '', currentYear + '', (currentYear + 1) + ''];
            vm.startDateClicked = false;
            vm.bookingDateOptions = {
                maxDate: new Date()
            };
            vm.minDepartureDate = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');

            // default fulfillment method to Email
            if (!vm.quote.fulfillmentMethod || vm.quote.fulfillmentMethod === null) {
                vm.quote.fulfillmentMethod = 'Email';
            }

            if (isTimesharePackage(vm.quote.packageName) === true) {
                vm.quote.useYr = currentYear + '';
            }

            partnerCustomerService.mapCustomerToQuote(vm.quote);

            // get new package if the current package doesn't have a value or doesn't match the state params package
            if (!vm.currentPackage || vm.currentPackage === null || vm.currentPackage.packageName.toLowerCase() !== vm.quote.packageName) {
                agents.getCurrentAgent().then(function (agent) {
                    dataservice.getPackageStateConfigs(null, agent)
                        .then(function (data) {
                            vm.packages = data;
                            for (var p = 0; p < vm.packages.length; p++) {
                                if (vm.packages[p].packageName.toLowerCase() === vm.quote.packageName) {
                                    var pkgData = packageDisplay.refinePackageData(vm.packages[p], []);
                                    var skipPremiumClearing = pkgData.package.partnerPackage;
                                    quotes.setCurrentPackage(pkgData.package, skipPremiumClearing);
                                    vm.currentPackage = quotes.getCurrentPackage();
                                }
                            }
                        });
                });
            }

            if ($stateParams.quoteNumber && $stateParams.quoteNumber !== null) {
                quotes.getQuoteByQuoteNumber($stateParams.quoteNumber).then(function (quote) {
                    vm.quote = quote;
                    vm.loadedQuote = true;
                    vm.quote.packageName = $stateParams.packageName.toLowerCase();
                });
            }

            if (vm.quote.state) {
                getQuote();
            }

            vm.postalCodeFormModel.postalCode = vm.quote.postalCode;
            vm.postalCodeFormModel.city = vm.quote.city;
            vm.postalCodeFormModel.stateOrProvince = vm.quote.state;
        }

        function onPostalChange() {
            var previousState = vm.quote.state;

            vm.quote.postalCode = vm.postalCodeFormModel.postalCode;
            vm.quote.city = vm.postalCodeFormModel.city;
            vm.quote.state = vm.postalCodeFormModel.stateOrProvince;

            if(previousState !== vm.postalCodeFormModel.state)
            {
                getQuote();
            }
        }

        function isTimesharePackage(pkgName) {
            return pkgName === packageNames.timeshareplus || pkgName === packageNames.timeshareplusmid ||
                pkgName === packageNames.timeshareplushigh || pkgName === packageNames.timesharepluslow ||
                pkgName === packageNames.timeshareannual1 || pkgName === packageNames.timeshareannual2;
        }

        function showTripCost() {
            return vm.quote.packageName === packageNames.leisureplus ||
                vm.quote.packageName === packageNames.leisurepluslow ||
                vm.quote.packageName === packageNames.travelclub ||
                vm.quote.packageName === packageNames.travelclublow ||
                vm.quote.packageName === packageNames.travelclubhigh ||
                vm.quote.packageName === packageNames.travelclubhighest ||
                vm.quote.packageName === packageNames.leisureplusenc ||
                vm.quote.packageName === packageNames.clubEncompass ||
                vm.quote.packageName === packageNames.clubgoldEncompass ||
                vm.quote.packageName === packageNames.club ||
                vm.quote.packageName === packageNames.clubgold;
        }

        function showReservationId() {
            return vm.quote.packageName !== packageNames.viplifestyle &&
                vm.quote.packageName !== packageNames.postdepartureplus;
        }

        function openDatePicker($event, source) {
            $event.preventDefault();
            $event.stopPropagation();

            // Keep the current state of the selected date picker so we can toggle it on click
            var isOpen = vm.datePickers[source];

            for (var key in vm.datePickers) {
                if (vm.datePickers.hasOwnProperty(key)) {
                    vm.datePickers[key] = false;
                }
            }

            vm.datePickers[source] = !isOpen;
        }

        function openStartDatePicker($event, form) {
            if (!vm.startDateClicked) {
                openStartDateModal(false, $event, form);
            }
            else {
                openDatePicker($event, 'startDate');
            }
        }

        function openStartDateModal(override, $event, form) {
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }

            if (!vm.startDateClicked || override) {
                var modalInstance = $modal.open({
                    templateUrl: 'startDateModal.html',
                    backdrop: 'static',
                    keyboard: false,
                    controller: 'StartDateModalController',
                    controllerAs: 'vm',
                    resolve: {
                        useYr: function () {
                            return vm.quote.useYr;
                        }
                    }
                });

                modalInstance.result.then(function (selectedDate) {
                    enrollmentForm.startDateFull.removeAttribute('readonly');
                    if (selectedDate !== null) {
                        vm.quote.startDateFull = selectedDate;
                        enrollmentForm.startDateFull.blur();
                    }
                    else {
                        enrollmentForm.startDateFull.blur();
                        enrollmentForm.startDateFull.focus();
                    }

                    form.startDateFull.$setUntouched();
                    vm.startDateClicked = true;
                }, function () {
                    vm.startDateClicked = true;
                    enrollmentForm.startDateFull.removeAttribute('readonly');
                });
            }
        }

        function submitForm(formValid) {
            if (formValid === true) {
                // get quotes
                vm.errors = [];
                vm.quote.isQuickQuote = false;
                vm.quoting = true;

                updateQuoteBeforeSending();

                return quotes.getSingleQuote(vm.quote, true)
                    .then(function (resp) {
                        if (resp && resp.data && resp.data.messages.length === 0) {
                            handleQuoteSuccess(resp, true);
                        }
                        else {
                            handleQuoteFailure(resp);
                        }
                    })
                    .catch(function (err) {
                        handleQuoteFailure(err);
                    });
            }
        }

        function handleQuoteSuccess(resp, navigate) {
            vm.quoting = false;
            vm.quote.premium = resp.data.premium;
            vm.quote.isPartnerProduct = resp.data.isPartnerProduct;
            vm.currentPackage.coverages = resp.data.coverages;
            var skipPremiumClearing = vm.currentPackage.partnerPackage;
            quotes.setCurrentPackage(vm.currentPackage, skipPremiumClearing);
            quotes.setCurrentQuote(vm.quote);

            if (navigate === true) {
                $state.go('review');
            }
        }

        function handleQuoteFailure(err) {
            vm.quoting = false;
            if (err.data.messages) {
                for (var i = 0; i < err.data.messages.length; i++) {
                    var errorMessage = messages.overrideErrorMessage(err.data.messages[i]);
                    if (vm.errors.indexOf(errorMessage)) {
                        vm.errors.push(errorMessage);
                    }
                }
            }
            else if (err.data.message) {
                vm.errors.push(err.data.message);
            }
        }

        var quotePromise;
        function getQuote() {
            $timeout.cancel(quotePromise);

            quotePromise = $timeout(function () {
                // make sure a state is selected before trying to quote
                if (vm.quote.state) {

                    // get quotes
                    vm.errors = [];
                    vm.quote.isQuickQuote = true;
                    vm.quoting = true;

                    updateQuoteBeforeSending();

                    return quotes.getSingleQuote(vm.quote, true)
                        .then(function (resp) {
                            if (resp && resp.data && resp.data.messages.length === 0) {
                                handleQuoteSuccess(resp, false);
                            }
                            else {
                                handleQuoteFailure(resp);
                            }
                        })
                        .catch(function (err) {
                            handleQuoteFailure(err);
                        })
                        .finally(function () {
                            vm.quoting = false;
                        });
                }
            }, 800);
        }

        function updateQuoteBeforeSending() {
            //if the quote's primary traveler is also the policy buyer, copy over
            if (vm.quote.isPolicyBuyer) {
                vm.quote.policyBuyer = {
                    firstName: vm.quote.firstName,
                    lastName: vm.quote.lastName,
                    phoneNumber: vm.quote.phone,
                    email: vm.quote.email,
                    address: {
                        address1: vm.quote.address1,
                        address2: vm.quote.address2,
                        city: vm.quote.city,
                        stateOrProvince: vm.quote.state,
                        postalCode: vm.quote.zip,
                        country: vm.quote.country
                    }
                };
            }

            // if start date is used, fill in the departure and return dates based on this
            if (vm.quote.startDate) {
                vm.quote.departureDate = vm.quote.startDate;
                vm.quote.returnDate = datecalculation.calculateYearMinusDay(vm.quote.departureDate);
            }

            // don't pass in the quote number if this is a new quote. The server will set the
            // quote number in this case
            if (vm.loadedQuote === false) {
                vm.quote.quoteNumber = null;
            }

            // clear out the email address if the customer wants USPS
            if (vm.quote.fulfillmentMethod === 'USPS') {
                vm.quote.email = null;
            }
        }

        function policyBuyerChanged() {
            // reset the policy buyer
            vm.quote.policyBuyer = {
                address: {}
            };
        }
    }

    // useYr comes from the controller resolve
    StartDateModalController.$inject = ['$uibModalInstance', 'moment', 'useYr'];

    function StartDateModalController($modalInstance, moment, useYr) {

        var vm = this;
        vm.januaryFirst = januaryFirst;
        vm.today = today;
        vm.customDate = customDate;
        vm.useYr = useYr;

        function januaryFirst() {
            $modalInstance.close(new Date('01/01/' + vm.useYr));
        }

        function today() {
            $modalInstance.close(new Date());
        }

        function customDate() {
            $modalInstance.close(null);
        }
    }

})();
