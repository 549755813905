(function () {
    'use strict';

    angular
        .module('app')
        .directive('isPolicyBuyer', isPolicyBuyer);

    isPolicyBuyer.$inject = [];

    function isPolicyBuyer() {
        return {
            templateUrl: 'app/vacationguard/directives/isPolicyBuyer.html',
            restrict: 'E',
            scope: {
                selectedValue: '=',
                buyerValueChanged: '&'
            },
            link: function (scope, elem, attrs, ctrl) {
                if (scope.selectedValue === undefined || scope.selectedValue === null) {
                    scope.selectedValue = true;
                }

                scope.valueChanged = function () {
                    scope.buyerValueChanged();
                };
            }
        };
    }

})();
