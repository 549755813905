/* global enrollmentForm:false */
(function () {
    'use strict';

    angular
        .module('app.core')
        .controller('RootController', RootController);

    RootController.$inject = ['agents'];

    function RootController(
        agents) {

        init();

        //* Method Declarations
        function init() {
            if (!agents.getCurrentAgent()) {
                agents.refreshCurrentAgent()
                    .then(function (agent) {

                    });
            }
        }
    }
})();
