(function() {
    'use strict';

    /**
     * @ngdoc module
     * @name agentService
     *
     * # agentService
     *
     * @description
     * Agent related data retrieval and storage service
     */
    angular.module('agentPortal')
        .factory('agentService', ['$resource', '$q', '$rootScope', 'portalService', 'apiUtilService', 'globalSettingsService', agentService]);

    function agentService($resource, $q, $rootScope, portalService, apiUtilService, globalSettingsService) {
        var agentsApi = $resource(globalSettingsService.clientsApiBaseUrl() + '/v1/agents/:agentCode/profile', {}, {
            update: { method: 'PUT' },
            get: { method: 'GET' }
        });
        var updateAgentStatusApi = $resource(globalSettingsService.clientsApiBaseUrl() + '/v1/Agency/:agencyCode/agent/status',
            { agencyCode: '@agencyCode' },
            { update: { method: 'put'} }
        );
        var getAgentsUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/Agency/:agencyCode/getAgents';
        var searchAgentsByNameUrl = $resource(globalSettingsService.clientsApiBaseUrl() + '/v1/agents/name/:searchAgentName', { searchAgentName: '@searchAgentName' }, { get: { method: 'get', isArray: false } });
        var searchAgentsByCodeUrl = $resource(globalSettingsService.clientsApiBaseUrl() + '/v1/agents/code/:searchAgentCode', { searchAgentCode: '@searchAgentCode' }, { get: { method: 'get', isArray: false } });

        return {
            getAgent: getAgent,
            saveAgent: saveAgent,
            getStatePackages: getStatePackages,
            loadAllAgents: loadAllAgents,
            saveAgentDetail: saveAgentDetail,
            expireAgent: expireAgent,
            activateAgent: activateAgent,
            fetchAgents: fetchAgents,
            findAgentsByName: findAgentsByName,
            findAgentsByCode: findAgentsByCode
        };

        /**
         * @description
         * retrieves agent
         */
        function getAgent(agentCode) {
            var promise = agentsApi.get({ agentCode: agentCode }).$promise;
            var deferred = $q.defer();

            promise.then(function (response) {
                if (response && response.response) {
                    // the agent profile data is here.
                    deferred.resolve(response.response);
                }
                else {
                    deferred.reject(response.messages);
                }
            },
                function (err) {
                    deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description
         * saves agent back to the server
         */
        function saveAgent(agent) {
            var promise = agentsApi.update({ agentCode: agent.agentCode }, agent).$promise;
            var deferred = $q.defer();

            promise.then(function (agentResponse) {
                if (!agentResponse) {
                    deferred.reject('An error occurred while attempting to update the agent profile.');
                }
                else if (apiUtilService.areThereApiErrorMessages(agentResponse.messages)) {
                    deferred.reject(agentResponse.messages[0]);
                }
                else {
                    deferred.resolve(agentResponse.response);
                }
            },
            function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        /**
         * @description
         * reconciles countries and states to create meta data that will allow
         * us to determine which states agent is allowed to sell product packages.
         */
        function processStatePackageResponse(results) {
            var statePackages = {};
            results.forEach(function(state) {
                statePackages[state.iso2Code] = [];
                if (state.packages) {
                    state.packages.forEach(function (packageElement) {
                        var packageName = packageElement.displayName;
                        if (packageElement.displayNameSubtitle) {
                            packageName = packageName + ' ' + packageElement.displayNameSubtitle;
                        }

                        var canSell = packageElement.canSell && packageElement.hasAgreement && portalService.isPackageAvailableInAgentPortal(packageElement);

                        statePackages[state.iso2Code].push({
                            ratingId: packageElement.ratingId,
                            name: packageName,
                            canSell: canSell,
                            title: canSell ? "" : "This policy is not available in the state."
                        });
                    });
                }
            });
            return statePackages;
        }

        /**
         * @description
         * returns packages that agent is allowed to sell 
         */
        function getStatePackages(states) {
            return processStatePackageResponse(states);
        }

        /**
        * @description
        * loads agent from the server
        */
        function loadAllAgents() {
            var deferred = $q.defer();

            return portalService.getAgentByInternalId().then(function (agent) {
                if (!agent.isSuperUser) {
                    defer.reject('Unauthorized');
                }
                // pageSize: $rootScope.config.CLIENT_GRID_PAGE_SIZE,
                // pageNumber: pageNumber,
                var searchCriteria = {
                    agencyCode: agent.agencyCode
                };

                var allAgentsDataUrl = $resource(getAgentsUrl);

                allAgentsDataUrl.get({ agencyCode: agent.agencyCode }, searchCriteria).$promise
                    .then(
                        function (agentsResponse) {
                            if (!agentsResponse) {
                                deferred.reject('An error occurred while attempting to load agents.');
                            }
                            else if (apiUtilService.areThereApiErrorMessages(agentsResponse.messages)) {
                                deferred.reject(agentsResponse.messages[0]);
                            }
                            else {
                                deferred.resolve(agentsResponse.response);
                            }
                        },
                        function (error) {
                            deferred.reject(error);
                    });

                return deferred.promise;
            });
        }

        function getAgentDetailApi(agencyCode) {
            return $resource(globalSettingsService.clientsApiBaseUrl() + '/v1/agency/' + agencyCode + '/Agent', {}, {
                insert: { method: 'POST' },
                update: { method: 'PUT' }
            });
        }

        /**
         * @description
         * saves agent detail back to the server
         */
        function saveAgentDetail(agent) {
            var deferred = $q.defer();

            portalService.getAgentByInternalId().then(function (loggedInAgent) {
                if (!loggedInAgent.isSuperUser) {
                    return;
                }

                var agentsDetailApi = getAgentDetailApi(loggedInAgent.agencyCode);
                var resultPromise;

                if (agent.agencyCode == null) {
                    resultPromise = agentsDetailApi.insert(null, agent).$promise;
                }
                else {
                    resultPromise = agentsDetailApi.update(null, agent).$promise;
                }

                resultPromise.then(function (result) {
                    if (!result) {
                        deferred.reject('An error occurred.');
                    }
                    else if (apiUtilService.areThereApiErrorMessages(result.messages)) {
                        deferred.reject(result.messages[0]);
                    }
                    else {
                        deferred.resolve(result.response);
                    }
                }, function (error) {
                    deferred.reject(error);
                });
            });

            return deferred.promise;
        }

        /**
         * @description
         * expire selected agent 
         */
        function expireAgent(agentCode) {
            return updateAgentStatus(agentCode, false);
        }

        /**
        * @description
        * activate selected agent 
        */
        function activateAgent(agentCode) {
            return updateAgentStatus(agentCode, true);
        }

        function updateAgentStatus(agentCode, activate) {
            return portalService.getAgentByInternalId().then(function (agent) {
                if (!agent.isSuperUser) {
                    return;
                }

                var agentToUpdate = { agentCode: agentCode, activate: activate };

                return updateAgentStatusApi.update({ agencyCode: agent.agencyCode }, agentToUpdate).$promise;
            });
        }

        var timeAgentsLastFetched = null;
        var fetchedAgents = [];

        function fetchAgents(agencyCode) {
            var deferred = $q.defer();

            if (timeAgentsLastFetched && fetchedAgents && !apiUtilService.shouldBustCache(timeAgentsLastFetched, Date.now())) {
                deferred.resolve(fetchedAgents);

                return deferred.promise;
            }

            var allAgentsDataUrl = $resource(getAgentsUrl);
            var getAllAgentsPromise = allAgentsDataUrl.get({ agencyCode: agencyCode }, null).$promise;

            getAllAgentsPromise
                .then(function (agentsResult) {
                        if (!agentsResult) {
                            deferred.reject('An error occurred.');
                        }
                        else if (apiUtilService.areThereApiErrorMessages(agentsResult.messages)) {
                            deferred.reject(result.messages[0]);
                        }
                        else {
                            var agents = agentsResult.response;

                            if (agents && agents.length > 1) {
                                agents.sort(function (thisAgent, nextAgent) {
                                    // sort by agent name.function (thisAgent, nextAgent) {
                                    // if there is a string value, return it.
                                    // if not, return -1 (since an empty value is as low as you can get during compare)
                                    return thisAgent.name ? thisAgent.name.localeCompare(nextAgent.name) : -1;
                                });
                            }

                            fetchedAgents = agents;
                            timeAgentsLastFetched = Date.now();

                            deferred.resolve(fetchedAgents);
                        }
                    }, function (error) {
                        deferred.reject('An error occurred.');
                    });

            return deferred.promise;
        }

        function findAgentsByName(loggedInAgentCode, agentName, success, error) {
            var resp = searchAgentsByNameUrl.get({
                searchAgentName: agentName
            }, function () {
                if (success) {
                    success(resp);
                }
            }, error);
            return resp;
        };

        function findAgentsByCode(loggedInAgentCode, agentCode, success, error) {
            var resp = searchAgentsByCodeUrl.get({
                searchAgentCode: agentCode
            }, function () {
                if (success) {
                    success(resp);
                }
            }, error);
            return resp;
        }
    }
})();