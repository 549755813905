(function () {
    'use strict';
    /**
     * @ngdoc factory
     * @name kpiServices
     *
     * # kpiServices
     *
     * @description
     * API integration for KPI tiles with the backend
     */
    var serviceId = 'kpiServices';

    angular.module('agentPortal').factory(serviceId, ['$resource', '$q', 'portalService', 'apiUtilService', 'globalSettingsService', kpiservice]);

    function kpiservice($resource, $q, portalService, apiUtilService, globalSettingsService) {

        return {
            getAgencyMetrics: getAgencyMetrics,
            getAgentMetrics: getAgentMetrics
        };

        /**
         * @description
         * loads kpi informatino from the server
         */
        function getAgencyMetrics(fromDate, endDate) {
            var deferred = $q.defer();

            portalService.getAgentByInternalId().then(function (agent) {
                var agencyMetricsUrl = globalSettingsService.apiBaseUrl() + '/v1/agency/:agencyCode/metrics/:startDate/:endDate';
                var agencyMetricsApi = $resource(agencyMetricsUrl);

                agencyMetricsApi.get({
                    agencyCode: agent.agencyCode,
                    startDate: moment(fromDate).format('YYYY-MM-DD'),
                    endDate: moment(endDate).format('YYYY-MM-DD')
                }).$promise
                  .then(function (metricsResponse) {
                            if (!metricsResponse) {
                                deferred.reject('An error occurred while attempting to load agent metrics.');
                            }
                            else if (apiUtilService.areThereApiErrorMessages(metricsResponse.messages)) {
                                deferred.reject(metricsResponse.messages[0]);
                            }
                            else {
                                deferred.resolve(metricsResponse.response);
                            }
                        },
                        function (error) {
                            deferred.reject(error);
                        });
            });

            return deferred.promise;
        }

        /**
         * @description
         * loads kpi informatino from the server
         */
        function getAgentMetrics(fromDate, endDate, agentCode) {
            var deferred = $q.defer();
            var agencyMetricsUrl = globalSettingsService.apiBaseUrl() + '/v1/agents/:agentCode/metrics/:startDate/:endDate';
            var agencyMetricsApi = $resource(agencyMetricsUrl);

            agencyMetricsApi.get({
                agentCode: agentCode,
                startDate: moment(fromDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            }).$promise
              .then(function (metricsResponse) {
                    if (!metricsResponse) {
                        deferred.reject('An error occurred while attempting to load agency metrics.');
                    }
                    else if (apiUtilService.areThereApiErrorMessages(metricsResponse.messages)) {
                        deferred.reject(metricsResponse.messages[0]);
                    }
                    else {
                        deferred.resolve(metricsResponse.response);
                    }
                },
                function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }
    }
})();