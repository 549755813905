(function () {
    'use strict';

    angular.module('agentPortal')
        .service('fnolService', fnolService);

    fnolService.$inject = ['$http', '$resource', '$q', 'apiUtilService', 'globalSettingsService'];

    function fnolService($http, $resource, $q, apiUtilService, globalSettingsService) {
        var fraudWarningKey = 'BlanketFraudWarning';
        var contentUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/content/:contentKey';
        var createClaimUrl = globalSettingsService.apiBaseUrl() + '/v1/Agents/Claims/Create';

        var vm = this;
        var currentClaim;


        vm.getClaimConfiguration = getClaimConfiguration;
        vm.submitFnolForClaim = submitFnolForClaim;
        vm.formatFnolForClaim = formatFnolForClaim;
        vm.getFraudWarning = getFraudWarning;
        vm.setCurrentClaim = setCurrentClaim;
        vm.getCurrentClaim = getCurrentClaim;

        function getClaimConfiguration(policyNumber) {
            var options = {
                cache: true
            };
            return $http.get(
                globalSettingsService.clientsApiBaseUrl() + '/v1/claims/configuration/' + policyNumber)
            .then(function handleSuccess(data) {
                var claimConfiguration = parseConfigurationData(data.data.response);
                if (claimConfiguration.flights && claimConfiguration.flights.length > 0) {
                    claimConfiguration = formatFlightsForDisplay(claimConfiguration);
                }
                return claimConfiguration;
            }, function handleError(error) {
                return $q.reject(error.data);
            });
        }

        function submitFnolForClaim(customerId, fnolClaimModel) {
            var deferred = $q.defer();

            $resource(createClaimUrl, {}, { post: { method: 'POST' } })
                .post(fnolClaimModel)
                .$promise
                .then(function (claimResponse) {
                    if (!claimResponse) {
                        deferred.reject('Error creating a claim.');
                    }
                    else if (apiUtilService.areThereApiErrorMessages(claimResponse.messages)) {
                        deferred.reject(claimResponse.messages[0]);
                    }
                    else {
                        deferred.resolve(claimResponse.response);
                    }
                },
                function (error) {
                    deferred.reject('Error creating a claim.');
                });

            return deferred.promise;
        }

        function formatFnolForClaim(fnol) {
            var formattedFnol = {
                policyNumber: fnol.policyNumber,
                postalCode: fnol.postalCode,
                emailAddress: fnol.emailAddress,
                risk: fnol.risk,
                coverage: fnol.coverage.coverageId,
                flight: fnol.flight,
                countryOfLoss: fnol.countryOfLoss,
                cityOfLoss: fnol.cityOfLoss,
                stateProvinceOfLoss: fnol.stateProvinceOfLoss,
                dateTimeOfLoss: moment(fnol.dateOfLoss).format("YYYY-MM-DD"),
                postalCodeOfLoss: fnol.postalCodeOfLoss,
                locationOfLoss: fnol.locationOfLoss,
                isCruise: fnol.isCruise,
                affectedTravelers: []
            };

            if (fnol.coverage.coveredTravelers) {
                for (var i = 0; i < fnol.coverage.coveredTravelers.length; i++) {
                    if (fnol.coverage.coveredTravelers[i].selected) {
                        formattedFnol.affectedTravelers.push(fnol.coverage.coveredTravelers[i].travelerId);
                    }
                }
            }

            return formattedFnol;
        }

        function getFraudWarning() {
            var deferred = $q.defer();

            $resource(contentUrl, { contentKey: fraudWarningKey })
                .get()
                .$promise
                .then(function (contentResponse) {
                    if (!contentResponse) {
                        deferred.reject('Error loading fraud warning content.');
                    }
                    else if (apiUtilService.areThereApiErrorMessages(contentResponse.messages)) {
                        deferred.reject(contentResponse.messages[0]);
                    }
                    else {
                        deferred.resolve(contentResponse.response);
                    }
                },
                function (error) {
                    deferred.reject('Error loading fraud warning content.');
                });

            return deferred.promise;
        }

        function setCurrentClaim(claim) {
            currentClaim = claim;
        }

        function getCurrentClaim() {
            return currentClaim;
        }

        function formatFlightsForDisplay(config) {
            for (var i = 0; i < config.flights.length; i++) {
                config.flights[i].displayOption = config.flights[i].departureAirportCode + ' ' + String.fromCharCode("8594") + String.fromCharCode("160") + config.flights[i].arrivalAirportCode + ' ' + ' - ' + config.flights[i].airlineName + ' - ' + config.flights[i].flightNumber;
            }

            return config;
        }

        function parseConfigurationData(response) {
            var coverages = {};

            for (var i = 0; i < response.coverages.length; i++) {
                var coverage = response.coverages[i];
                var riskGroups = [];

                for (var j = 0; j < coverage.risks.length; j++) {
                    if (riskGroups.indexOf(coverage.risks[j].groupName) === -1) {
                        riskGroups.push(coverage.risks[j].groupName);
                    }
                }

                coverages[coverage.coverageId] = {
                    coverageId: coverage.coverageId,
                    description: coverage.description,
                    name: coverage.name,
                    shortName: coverage.shortName,
                    riskGroups: riskGroups,
                    risks: coverage.risks,
                    coveredTravelers: coverage.coveredTravelers,
                    requires: coverage.requires
                };
            }

            response.coverages = coverages;
            return response;
        }
    }
})();