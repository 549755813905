(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name apiUtilService
     *
     * # utilService
     *
     * @description
     * has utility functions to show popup messages, dates processing, etc
     */
    angular.module('agentPortal')
        .service('apiUtilService', [apiUtilService]);

    function apiUtilService() {
        var $this = this;

        $this.refundByCheckErrorCodes = function refundByCheckErrorCodes() {
            return ['2827', '2828'];
        };

        $this.areThereApiErrorMessages = function areThereApiErrorMessages(messages) {
            if (!messages || messages.length === 0) {
                return false;
            }

            for (var i = 0; i < messages.length; i++) {
                var currentMessage = messages[i];
                if (currentMessage && currentMessage.severity !== 4 && currentMessage.severity !== 2) {
                    // this is a message to be concerned about.
                    return true;
                }
            }

            return false;
        };

        $this.areThereApiErrorMessagesExceptProvidedCodes = function areThereApiErrorMessagesExceptProvidedCodes(messages, excludedErrorCodes) {
            if (!messages || messages.length < 1) {
                return false;
            }

            if (!excludedErrorCodes || !Array.isArray(excludedErrorCodes) || excludedErrorCodes.length < 1) {
                return $this.areThereApiErrorMessages(messages);
            }

            for (var i = 0; i < messages.length; i++) {
                var currentMessage = messages[i];
                if (excludedErrorCodes.every((code) => code !== currentMessage.code) && currentMessage && currentMessage.severity !== 4 && currentMessage.severity !== 2) {
                    // this is a message to be concerned about.
                    return true;
                }
            }

            return false;
        };

        $this.getApiErrorMessagesWithProvidedCodes = function getApiErrorMessagesWithProvidedCodes(messages, errorCodesToFind) {
            var messagesToReturn = [];

            if (!messages || messages.length < 1 || !errorCodesToFind || errorCodesToFind.length < 1) {
                return messagesToReturn;
            }

            return messages.filter((m) => errorCodesToFind.some((ec) => ec === m.code))
                    .map((m) => m.text);
        };

        $this.shouldBustCache = function shouldBustCache(startDate, endDate) {
            if (!startDate || !endDate) {
                return true;
            }

            var minutesCached = moment.duration(moment(endDate).diff(moment(startDate))).asMinutes();

            return minutesCached >= 20;
        };

        return $this;
    }
})();
