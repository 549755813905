(function () {
    'use strict';

    angular
      .module('components.claims')
      .service('claimconfiguration', ClaimConfiguration);

    ClaimConfiguration.$inject = ['$q', 'Rx', 'dataservice'];
    function ClaimConfiguration($q, Rx, dataservice) {
        this.dataservice = dataservice;

        this.claimConfiguration = null;
        this.claimConfigurationSubject = new Rx.ReplaySubject();
        this.claimConfigurationSubject.onNext(this.claimConfiguration);
    }

    ClaimConfiguration.prototype.reset = function resetClaimConfiguration() {
        this.claimConfiguration = null;
        this.claimConfigurationSubject.onNext(this.claimConfiguration);
    };

    ClaimConfiguration.prototype.search = function searchClaimConfiguration(search) {
        var self = this;

        return self.dataservice.searchClaimConfiguration(search.policyNumber, search.postalCode, search.emailAddress)
            .then(function handleSuccess(data) {
                self.claimConfiguration = parseConfigurationData(data);

                self.dataservice.getFraudWarning()
                    .then(function handleSuccess(data) {
                        self.claimConfiguration.fraudWarning = data;
                    }, function (error) {
                        // use default fraud warning
                    })
                    .finally(function () {
                        self.claimConfigurationSubject.onNext(self.claimConfiguration);
                    });
            });
    };

    function parseConfigurationData(response) {
        var coverages = {};

        for (var i = 0; i < response.coverages.length; i++) {
            var coverage = response.coverages[i];
            var riskGroups = [];

            for (var j = 0; j < coverage.risks.length; j++) {
                if (riskGroups.indexOf(coverage.risks[j].groupName) === -1) {
                    riskGroups.push(coverage.risks[j].groupName);
                }
            }

            coverages[coverage.coverageId] = {
                coverageId: coverage.coverageId,
                description: coverage.description,
                name: coverage.name,
                shortName: coverage.shortName,
                riskGroups: riskGroups,
                risks: coverage.risks,
                coveredTravelers: coverage.coveredTravelers
            };
        }

        response.coverages = coverages;
        return response;
    }

})();
