(function () {
    'use strict';

    angular
      .module('vacationguard')
      .controller('ContactController', ContactController);

    ContactController.$inject = ['$sce', '$timeout', 'contacts'];
    function ContactController($sce, $timeout, contacts) {
        var vm = this;

        vm.contacts = contacts;

        vm.trustAsHtml = $sce.trustAsHtml;
    }

})();
