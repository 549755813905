(function () {
    'use strict';

    /**
     * @ngdoc factory
     * @name quotesService
     *
     * # quotesService
     *
     * @description
     * backend API integration for quotes related requirements
     */

    angular.module('agentPortal')
        .factory('quoteGridConfigService', quoteGridConfigService);

        quoteGridConfigService.$inject = ['$filter', 'portalService', '$q', 'agentService', 'utilService'];

    function quoteGridConfigService($filter, portalService, $q, agentService, utilService) {

        const orderByMap = {
            quoteNumber: 'quote_number', 
            primaryTraveler: 'primary_traveler', 
            travelers: 'travelers', 
            product: 'product', 
            quoteDate: 'quote_date', 
            agentName: 'agent_name', 
            destination: 'destination', 
            departureDate: 'departure_date', 
            tripCost: 'trip_cost',
            submissionChannel: 'submission_channel',
            saveMethod: 'save_method',
            totalPremium: 'total_premium'
        }

        let gridConfig = null;
        let cogFunctions = null;

        return {
            getQuoteGridConfig: getQuoteGridConfig,
            appendAdditionalQuoteGridDate: appendAdditionalQuoteGridDate
        };


    /**
     * @description
     * Returns Quote Grid configuration
     */
    function getQuoteGridConfig(agent, actions) {
        const deferred = $q.defer();

        let gridConfigSetup;
        if (agent.isSuperUser || agent.isAmbassador) {
            gridConfigSetup = {
                noDataMessage: 'No quotes found',
                allowMultiSelect: true,
                hasActions: true,
                columns: [
                    { header: 'Quote #', binding: 'quoteNumber', preferredWidth: '10%', href: 'quotes/view/{{row.quoteNumber}}' },
                    { header: 'Primary Traveler', binding: 'primaryTraveler', preferredWidth: '20%' },
                    { header: 'Product', binding: 'product', preferredWidth: '1%' },
                    { header: 'Quote Date', binding: 'quoteDate', preferredWidth: '1%' },
                    { header: 'Premium', binding: 'totalPremium', filter: 'dollar', isCurrency: true, preferredWidth: '1%' },
                    { header: 'Agent Name', binding: 'agentName', preferredWidth: '1%' },
                    { header: 'Departure Date', binding: 'departureDate', preferredWidth: '1%' },
                    { header: 'Trip Cost', binding: 'tripCost', filter: 'dollar', isCurrency: true, preferredWidth: '1%' },
                    { header: 'Destination', binding: 'destination', preferredWidth: '20%' },
                    { header: 'Travelers', binding: 'travelers', preferredWidth: '1%' },
                    { header: 'Submission Channel', binding: 'submissionChannel', filter: 'submission', preferredWidth: '1%' },
                    { header: 'Save Method', binding: 'saveMethod', filter: 'saveMethod', preferredWidth: '1%' }
                ],
                actionList: [
                    { label: 'View', icon: 'fa fa-eye', href: 'quotes/view/{{row.quoteNumber}}' }
                ],
                defaultOrderBy: 'departure_date',
                defaultOrder: false,
                rowIdentifier: 'quoteNumber',
                filter: $filter('quotesFilter')
            };
        }
        else {
            gridConfigSetup = {
                noDataMessage: 'No quotes found',
                allowMultiSelect: true,
                hasActions: true,
                columns: [
                    { header: 'Quote #', binding: 'quoteNumber', preferredWidth: '10%', href: 'quotes/view/{{row.quoteNumber}}' },
                    { header: 'Primary Traveler', binding: 'primaryTraveler', preferredWidth: '20%' },
                    { header: 'Product', binding: 'product', preferredWidth: '1%' },
                    { header: 'Quote Date', binding: 'quoteDate', preferredWidth: '1%' },
                    { header: 'Premium', binding: 'totalPremium', filter: 'dollar', isCurrency: true, preferredWidth: '1%' },
                    { header: 'Agent Name', binding: 'agentName', preferredWidth: '1%' },
                    { header: 'Departure Date', binding: 'departureDate', preferredWidth: '1%' },
                    { header: 'Trip Cost', binding: 'tripCost', filter: 'dollar', isCurrency: true, preferredWidth: '1%' },
                    { header: 'Destination', binding: 'destination', preferredWidth: '20%' },
                    { header: 'Travelers', binding: 'travelers', preferredWidth: '1%' },
                    { header: 'Submission Channel', binding: 'submissionChannel', filter: 'submission', preferredWidth: '1%' },
                    { header: 'Save Method', binding: 'saveMethod', filter: 'saveMethod', preferredWidth: '1%' }
                ],
                actionList: [
                    { label: 'View', icon: 'fa fa-eye', href: 'quotes/view/{{row.quoteNumber}}' }
                ],
                defaultOrderBy: 'departure_date',
                defaultOrder: false,
                rowIdentifier: 'quoteNumber',
                filter: $filter('quotesFilter')
            };
        }

        gridConfigSetup.dateFilters = getDateFilters();
        gridConfigSetup.agent = agent;
        gridConfigSetup.getCustomFilters = getCustomFilters;
        gridConfigSetup.orderByMap = orderByMap;
        gridConfigSetup.getOrderBy = getOrderBy;
        gridConfigSetup.getSortOrder = getSortOrder;
        gridConfigSetup.getDateFilter = getDateFilter;
        gridConfigSetup.filteredExpired = {name: 'None', value: 'none'};;
        gridConfigSetup.filteredDate = 'All';
        gridConfigSetup.searchText = null;
        gridConfigSetup.filteredPackageIds = '';
        gridConfigSetup.cogFunctions = actions;
        var gridInitPromises = [];

        // Get expired messages for dropdown filter
        gridInitPromises.push(portalService.getExpiredMessages().then(function (messages) {
            gridConfigSetup.expiredMessages = [{name: 'None', value: 'none'}, {name: 'All', value: 'all'}].concat(messages);
        }));

        // Get agents for dropdown filter
        if (agent.isSuperUser) {
            gridInitPromises.push(agentService.fetchAgents(agent.agencyCode).then(function (results) {
                if (results) {
                    gridConfigSetup.agents = results;
                }
            }));
        }
        
        //Get products for dropdown filter
        gridInitPromises.push(portalService.loadAgencyProductsForDropdown(true).then(function (packageOptions) {
            gridConfigSetup.packageOptions = packageOptions;
        }));

        $q.all(gridInitPromises).then(() => {
            gridConfig = gridConfigSetup;
            return deferred.resolve(gridConfigSetup);
        });

        return deferred.promise;
    }

    function appendAdditionalQuoteGridDate(quotes) {
        for (var i = 0; i < quotes.length; i++) {
            setQuoteActions(quotes[i]);
            setQuoteRemoveData(quotes[i]);
        }
    }

    /**
     * @description
     * sets actions for quotes grid's rows 
     */
    function setQuoteActions(quote) {
        quote.actions = [];
        quote.actions.push({ label: 'Remove', click: gridConfig.cogFunctions.removeQuote, icon: 'fa fa-trash-o', href: '#' });
    };

    /**
     * @description
     * sets data needed for quote removal on quotes grid's rows 
     */
    function setQuoteRemoveData(quote) {
        quote.removeData = {};
        quote.removeData.emailAddress = quote.email;
        quote.removeData.eventId = quote.eventNumber;
        quote.removeData.quoteId = quote.quoteNumber;
    };

    /**
     * @description
     * grid implementation - date filters 
     */
    function getDateFilters() {
        return [
            { interval: 'All', name: 'All' },
            { interval: '0', name: 'Today' },
            { interval: '1', name: 'This Week' },
            { interval: '2', name: 'This Month' },
            { interval: '3', name: 'Past Month' },
            { interval: '4', name: 'YTD' }
        ];
    }

    /**
     * @description
     * grid implementation - custom filters
     */
    function getCustomFilters(vm) {
        return [
            // So if the user is a super user, or an ambassador then we allow them not to pass an agent code or and filter by agent code, all other users pass there agent code
            { key: 'agentCode', value: (gridConfig.agent.isSuperUser || gridConfig.agent.isAmbassador) ? (gridConfig.filteredAgent ? gridConfig.filteredAgent.agentCode : null) : gridConfig.agent.agentCode },
            { key: 'ratingId', value: gridConfig.filteredPackageIds },
            { key: 'date', value: gridConfig.filteredDate },
            { key: 'expiredReason', value: gridConfig.filteredExpired.value }
        ];
    };

    function getOrderBy() {
        return orderByMap[gridConfig.orderby];
    }

    function getSortOrder() {
        return gridConfig.reverse ? 'desc' : 'asc';
    }

    function getDateFilter() {
        var dateRange = utilService.getDateRange(gridConfig.filteredDate);

            var dateFilter;
            if (dateRange != null) {
                dateFilter = {
                    startDate: dateRange.startDate.format('MM/DD/YYYY'),
                    endDate: dateRange.endDate.format('MM/DD/YYYY')
                };
            }
            else {
                dateFilter = {
                    startDate: null,
                    endDate: null
                };
            }

            return dateFilter;
    }
}
})();
