(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name bhtpPopupMessage
     *
     * # bhtpPopupMessage
     *
     * @description
     * generic popup message displaying directive
     */
    angular.module('agentPortal')
        .directive('bhtpPopupMessage', [bhtpPopupMessageDirective]);

    function bhtpPopupMessageDirective() {
        return {
            restrict: 'EA',
            transclude: true,
            scope: {
                modalId: '@',
                title: '@',
                message: '=',
                iconClass: '@',
                onContinue: '&'
            },
            templateUrl: 'app/layout/popupmessage.html',
            link: function (scope, element, attrs) {
                scope.$watch(
                    'message',
                    function (message) {
                        scope.messagesToDisplay = [];

                        if (!message) {
                            return;
                        }

                        var messagesToFormat = !Array.isArray(message) ? [ message ] : message;
                        var separator = '|'.repeat(6);
                        messagesToFormat.map((m) => {
                            scope.messagesToDisplay.push(...String(m).replace(/\\r\\n|\\r|\\n|(<br ?\/?>)/gi, separator).split(separator));
                        });
                    },
                    true
                );

                scope.$watch(
                    'onContinue',
                    function (onContinue) {
                        scope.closeAndContinue = function closePopupAndContinue() {
                            // onContinue is an angularjs wrapper around the function passed in.
                            // first invocation is on the wrapper to get the actual function to execute.
                            if (onContinue && onContinue()) {
                                // () = get the function from the wrapper,
                                // ()() = execute the function returned from the wrapper.
                                onContinue()();
                            }
                        };

                        $('#popupmsg').off('hide.bs.modal');
                        $('#popupmsg').on('hide.bs.modal', function (e) {
                            scope.closeAndContinue();
                        });
                    },
                    true
                );
            }
        };
    }
}());
