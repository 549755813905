(function () {
    'use strict';

    angular
        .module('agentPortal')
        .factory('agentInformationService', agentInformationService);

    agentInformationService.$inject = ['portalService', '$resource', '$q', 'intentService', 'globalSettingsService'];

    function agentInformationService(portalService, $resource, $q, intentService, globalSettingsService) {

        var updateTimezoneUrl = globalSettingsService.apiBaseUrl() + '/v1/Agents/Timezone'

        var service = {
            loadAgentInformation: loadAgentInformation
        };

        return service;

        function loadAgentInformation(showlabels) {
            if (showlabels == null) {
                showlabels = true;
            }

            return portalService.loadConfig().then(function (config) {
                //Now get the agent's profile from agent service!
                if (showlabels) {
                    intentService.setIntent("Authorizing...");
                }

                var profile = portalService.getAgentProfileFromLocalStorage();

                if (profile) {
                    return portalService.initializeAgent(profile.user_id).then(function (agent) {
                        if (agent.agentId) {
                            var agentData = { agent: agent, config: config };

                            // fire off the timezone update.  if it fails, it shouldn't
                            //  stop the agent from being able to use the agent portal...
                            updateTimezone(agent);

                            // resolve the agent profile.
                            intentService.resetIntent();
                            return agentData;
                        } else {
                            intentService.resetIntent();
                        }
                    }, function (error) {
                        intentService.resetIntent();
                        console.warn("Failed while retrieving agent information %o", error);
                    });
                }
            }, function (error) {
                intentService.resetIntent();
                console.warn("Failed while loading configuration %o", error);
            });
        }

        function updateTimezone(agent) {
            var timezoneName = jstz.determine().name().replace("/", "_");
            var requestData = {
                newTimeZone: timezoneName
            };

            var updateTimezoneApi = $resource(updateTimezoneUrl, { },
                {
                    patch: { method: 'PATCH' }
                });

            return updateTimezoneApi.patch(requestData)
                    .$promise;
        }
    }

})();
