(function () {
    'use strict';

    var testModule = angular.module('agent-portal-test', []);

    testModule.provider('ambassadorInformationSessionStorage', function mockAmbassadorInformationSessionStorageProvider() {
        this.$get = function () {
            // TODO: if angularjs tests are written against ambassadorInformationSessionStorage, implement the mock object methods here.
            return {};
        };
    });

    testModule.provider('serviceSetup', function mockServiceSetupProvider() {
        this.$get = function () {
            return mockServiceSetup;
        };
    });

    testModule.provider('globalSettingsService', function mockGlobalSettingsService() {
        this.$get = function () {
            return mockGlobalSettingsService;
        };
    });
})();

var mockServiceSetup = {
    injectAuthToken: function (token) { }
};

var mockGlobalSettingsService = {
    apiBaseUrl: function () {
        return 'api';
    },
    clientsApiBaseUrl: function () {
        return 'clients';
    }
};