
(function () {
    'use strict';

    angular
      .module('components.claims')
      .directive('claimConfirmation', claimConfirmation);

    claimConfirmation.$inject = [];

    function claimConfirmation() {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/vacationguard/components/claims/claimconfirmation.html',
            controller: ClaimConfirmationController,
            controllerAs: 'confirmationCtrl',
            bindToController: true,
            link: link
        };

        function link(scope, element, attrs) {
            scope.$watch(attrs.bhClaim, function (claim) {
                scope.confirmationCtrl.claim = claim;
            });
        }

        return directive;
    }

    ClaimConfirmationController.$inject = [];

    function ClaimConfirmationController() {
        var vm = this;

        vm.claim = null;
    }

})();

