(function () {
    'use strict';

    angular
        .module('app.core')
        .service('policySearch', PolicySearch);

    PolicySearch.$inject = ['Rx', 'dataservice'];

    function PolicySearch(Rx, dataservice) {
        this.dataservice = dataservice;

        this.search = {
            policyNumber: null,
            postalCode: null,
            emailAddress: null
        };
        this.searchSubject = new Rx.ReplaySubject();
        this.searchSubject.onNext(this.search);
    }

    PolicySearch.prototype.get = function getPolicySearch(search) {
        return this.search;
    };

    PolicySearch.prototype.set = function setPolicySearch(search) {
        this.search = search;
        this.searchSubject.onNext(this.search);
    };

})();
