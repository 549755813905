/* global $:false */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .controller('PaymentFormController', PaymentFormController);

    PaymentFormController.$inject = ['$sce', '$state', '$timeout', 'quotes', '$q', '$scope'];

    function PaymentFormController($sce, $state, $timeout, quotes, $q, $scope) {

        var vm = this;
        vm.title = 'Enter Payment Info';

        vm.quote = quotes.getCurrentQuote();
        vm.reloadIframe = function () {
            vm.errors = [];
            $state.go('quoteBHTP.payment', {}, { reload: true });
        };

        init();

        function init() {
            vm.quoting = false;
            vm.iframeSrc = $sce.trustAsResourceUrl(getIframeUrl(basePaymentUrl));
            setupResponseListener();
        }

        function getIframeUrl(url) {
            var queryParams = '?';

            // simplify only accepts a single line address
            var address = sortOutTheAddress(vm.quote);

            queryParams += 'address=' + setQueryParam(address.address);
            queryParams += '&city=' + setQueryParam(address.city);
            queryParams += '&state=' + setQueryParam(address.stateOrProvince);
            queryParams += '&zip=' + setQueryParam(address.postalCode);
            queryParams += '&email=' + setQueryParam(address.email);
            queryParams += '&name=' + setQueryParam(address.name);
            queryParams += '&saveTokenForPayments=false&buttonText=Complete%20Purchase&buttonColor=%232C6EB6';
            queryParams += '&fontFamily=' + setQueryParam('"Helvetica Neue", Helvetica, Arial, sans-serif');

            url += queryParams;
            return url;

            function setQueryParam(field) {
                return encodeURIComponent(field || '');
            }
        }

        function sortOutTheAddress(quote) {
            var address = {};
            if (!quote.isPolicyBuyer && quote.policyBuyer && isCompleteAddress(quote.policyBuyer.address)) {
                address.address = quote.policyBuyer.address.address1;
                address.city = quote.policyBuyer.address.city;
                address.stateOrProvince = quote.policyBuyer.address.stateOrProvince;
                address.postalCode = quote.policyBuyer.address.postalCode;
                address.email = quote.policyBuyer.email;
                address.name = quote.policyBuyer.firstName + ' ' + quote.policyBuyer.lastName;
            } else if (quote.primaryTraveler) {
                address.address = quote.primaryTraveler.address.address1;
                address.city = quote.primaryTraveler.address.city;
                address.stateOrProvince = quote.primaryTraveler.address.stateOrProvince;
                address.postalCode = quote.primaryTraveler.address.postalCode;
                address.email = quote.primaryTraveler.email;
                address.name = quote.primaryTraveler.firstName + ' ' + quote.primaryTraveler.lastName;
            } else {
                address.address = quote.address1;
                address.city = quote.city;
                address.stateOrProvince = quote.state;
                address.postalCode = quote.postalCode;
                address.email = quote.email;
                address.name = quote.firstName + ' ' + quote.lastName;
            }
            return address;
        }

        function isCompleteAddress(address) {
            // State and City can be inferred from postal code in the payment form
            return Boolean(address && address.address1 && address.postalCode);
        }

        function setupResponseListener() {
            window.onmessage = windowListener;

            $scope.$on('$destroy', function () {
                teardownResponseListener();
            });
        }

        function teardownResponseListener() {
            /* we need to remove the window message listener in case another
               purchase comes through the agent portal for a none vacation guard
               package.
            */
            window.onmessage = function () { };
        }

        function windowListener(e) {
            if (e.data) {
                var key = e.message ? 'message' : 'data';
                var data = e[key];
                if (data) {
                    if (vm.quote && data.card) {
                        // simplify only returns a single address line despite calling it addressLine1
                        vm.quote.billingEmail = data.email;
                        vm.quote.paymentInformation = {
                            paymentMethod: 'Compliant',
                            merchantProfile: 'bhtp',
                            paymentToken: data.cardToken,
                            last4: data.card.last4,
                            nameOnCard: data.card.name,
                            address: data.card.addressLine1,
                            city: data.card.addressCity,
                            zip: data.card.addressZip,
                            state: data.card.addressState,
                            cardType: data.card.type,
                            expirationDate: data.card.expMonth + '/' + data.card.expYear
                        };
                        completePurchase();
                    }
                }
            }
        }

        function completePurchase() {
            vm.quoting = true;
            quotes.purchaseQuote(vm.quote)
                .then(function (rsp) {
                    vm.quoting = false;
                    switch (rsp.status) {
                        case 200:
                            vm.quote.policyNumber = rsp.data.policyNumber;
                            $state.go('receipt');
                            break;
                        default:
                            vm.errors = [];
                            if (rsp.data.messages) {
                                for (var i = 0; i < rsp.data.messages.length; i++) {
                                    vm.errors.push(rsp.data.messages[i].text);
                                }
                            }
                    }
                });
        }
    }
})();
