(function () {
    'use strict';

    angular
        .module('app.quotes')
        .factory('quotes', quotes);

    quotes.$inject = ['$q', 'dataservice', '$localStorage', 'agents', 'moment', 'partnerCustomerService', 'ambassadorInformationSessionStorage'];

    function quotes($q, dataservice, $localStorage, agents, moment, partnerCustomerService, ambassadorInformationSessionStorage) {
        var quoteResults;

        var service = {
            getSmartQuotes: getSmartQuotes,
            setCurrentPackage: setCurrentPackage,
            getCurrentPackage: getCurrentPackage,
            setCurrentQuote: setCurrentQuote,
            getCurrentQuote: getCurrentQuote,
            getCurrentQuoteOnReload: getCurrentQuoteOnReload,
            createBhtpQuoteFromQuickQuote: createBhtpQuoteFromQuickQuote,
            getQuoteResponse: getQuoteResponse,
            setQuoteResponse: setQuoteResponse,
            buildBHTPQuote: buildBHTPQuote,
            getQuoteResult: getQuoteResult,
            getSingleQuote: getSingleQuote,
            purchaseQuote: purchaseQuote,
            getQuoteByQuoteNumber: getQuoteByQuoteNumber
        };
        return service;

        /*
         * quickQuote: {
         *      state: 'WI',
         *      age: 28,
         *      totalTripCost: 500,
         *      initialTripDepositDate: '07/01/2015,
         *      destinationCountry: 'US',
         *      departureDate: '08/01/2015,
         *      returnDate: '08/09/2015
         * }
         */
        /**
         * @description
         * takes a quick quote object and and array of packages and retrieves a quote
         * for each of the packages based on the quick quote information
         */
        function getSmartQuotes(quickQuote, packages) {
            var partnerQuotes = [];
            var bhtpQuotes = [];

            angular.forEach(packages, function (pkg) {
                if (pkg.partnerPackage === true) {
                    partnerQuotes.push(createPartnerQuoteFromQuickQuote(quickQuote, pkg));
                } else {
                    bhtpQuotes.push(createBhtpQuoteFromQuickQuote(quickQuote, pkg));
                }
            });

            quoteResults = [];

            var promises = [].concat(
                getPartnerQuotes(partnerQuotes),
                getBhtpQuotes(bhtpQuotes)
            );

            return $q.all(promises)
                .then(function () {
                    return getQuotePrice(packages);
                })
                .catch(function (errors) {
                    return $q.reject(errors);
                });
        }

        function getQuotePrice(packages) {
            for (var i = 0; i < packages.length; i++) {
                for (var j = 0; j < quoteResults.length; j++) {
                    var result = quoteResults[j];
                    if (packages[i].packageName === result.packageName) {
                        if (!result.messages || result.messages.length === 0) {
                            if (packages[i].partnerPackage) {
                                packages[i].premium = result.premium;
                                packages[i].coverages = result.coverages;
                            } else {
                                packages[i].premium = result.baseQuoteAmount + result.fees;
                                packages[i].coverages = [].concat(
                                    result.coverages.included,
                                    result.coverages.optional
                                );
                            }

                            packages[i].validationFailed = false;
                            packages[i].hasPackageError = false;
                            packages[i].messages = [];
                        }
                        else {
                            packages[i].premium = null;
                            packages[i].coverages = [];
                            packages[i].validationFailed = true;
                            packages[i].messages = result.messages;
                        }

                        break;
                    }
                }
            }

            return packages;
        }

        function getQuoteResult(packageName) {
            if (quoteResults) {
                for (var j = 0; j < quoteResults.length; j++) {
                    var result = quoteResults[j];
                    if (packageName === result.packageName) {
                        return result;
                    }
                }
            }

            return null;
        }

        function createBhtpQuoteFromQuickQuote(quickQuote, pkg) {
            var newQuote = {
                policy: {
                    packageName: pkg.packageName,
                    isQuickQuote: true,
                    departureDate: quickQuote.departureDate,
                    returnDate: quickQuote.returnDate,
                    tripDepositDate: quickQuote.tripDepositDate,
                    destinationCountry: quickQuote.destination
                },
                travelers: [
                    {
                        isPrimary: true,
                        age: quickQuote.age,
                        tripCost: quickQuote.totalTripCost,
                        address: {
                            stateOrProvince: quickQuote.state
                        }
                    }
                ],
                isPartnerProduct: false,

                // support for other code that requires the package name at the root.
                packageName: pkg.packageName
            };

            newQuote.primaryTraveler = newQuote.travelers[0];

            return newQuote;
        }

        function createPartnerQuoteFromQuickQuote(quickQuote, pkg) {
            return {
                packageName: pkg.packageName,
                isQuickQuote: true,
                state: quickQuote.state,
                age: quickQuote.age,
                departureDate: quickQuote.departureDate,
                returnDate: quickQuote.returnDate,
                tripDepositDate: quickQuote.tripDepositDate,
                totalTripCost: quickQuote.totalTripCost,
                isPartnerProduct: true
            };
        }

        function getBhtpQuotes(bhtpQuotes) {
            if (bhtpQuotes.length > 0) {
                return agents.getCurrentAgent()
                    .then(function (agent) {
                        var promises = [];
                        for (var i = 0; i < bhtpQuotes.length; i++) {
                            bhtpQuotes[i].policy.agentCode = agent.agentCode;
                            bhtpQuotes[i].policy.agencyCode = agent.agencyCode;
                            promises.push(dataservice.getBhtpQuotes(bhtpQuotes[i])
                                .then(addQuoteToResults));
                        }

                        return $q.all(promises);
                    });
            }
        }

        function getPartnerQuotes(partnerQuotes) {
            if (partnerQuotes.length > 0) {
                return agents.getCurrentAgent()
                    .then(function (agent) {
                        for (var i = 0; i < partnerQuotes.length; i++) {
                            partnerQuotes[i].agentCode = agent.agentCode;
                            partnerQuotes[i].agencyCode = agent.agencyCode;
                        }

                        return dataservice.getPartnerQuotes(partnerQuotes)
                            .then(function (quotes) {
                                for (var i = 0; i < quotes.length; i++) {
                                    addQuoteToResults(quotes[i]);
                                }
                            });
                    });
            }
        }

        function getSingleQuote(quote, forPartner) {
            return agents.getCurrentAgent()
                .then(function (agent) {
                    quote.agentCode = agent.agentCode;
                    quote.agencyCode = agent.agencyCode;
                    return dataservice.getSingleQuote(quote, forPartner);
                });
        }

        function purchaseQuote(quote) {
            quote.isQuickQuote = false;

            if (quote.paymentInformation.paymentMethod !== 'Compliant') {
                quote.paymentInformation.address = null;
                quote.paymentInformation.city = null;
                quote.paymentInformation.state = null;
                quote.paymentInformation.zip = null;
                quote.paymentInformation.nameOnCard = null;
                quote.paymentInformation.cardNumber = null;
                quote.paymentInformation.expirationDate = null;
                quote.sameAsHomeAddress = false;
            }

            return agents.getCurrentAgent()
                .then(function (agent) {
                    if (!quote.agentCode) {
                        quote.agentCode = agent.agentCode;
                    }
                    
                    quote.agencyCode = agent.agencyCode;
                    return dataservice.purchaseQuote(quote);
                })
                .catch(function (err) {
                    return err;
                });
        }

        function addQuoteToResults(quote) {
            quoteResults.push(quote);
        }

        function buildBHTPQuote(quote, coverages, quickQuote) {
            var newQuote = {
                policy: {},
                travelers: [],
                coverages: [],
                isPartnerProduct: false
            };

            if (quote.policy) {
                newQuote.policy = quote.policy;
                newQuote.policy.isQuickQuote = quickQuote;
            }

            var travelers = [];
            quote.primaryTraveler.isPrimary = true;
            travelers.push(quote.primaryTraveler);
            if (quote.additionalTravelers) {
                for (var t = 0; t < quote.additionalTravelers.length; t++) {
                    travelers.push(quote.additionalTravelers[t]);
                }
            }

            newQuote.travelers = validateBHTPTravelers(travelers);

            newQuote.coverages = validateCoverages(coverages, quote);

            if (quote.paymentInformation) {
                newQuote.paymentInformation = quote.paymentInformation;
            }

            return newQuote;
        }

        function validateBHTPTravelers(travelers) {
            var newTravelers = [];

            if (travelers) {
                for (var i = 0; i < travelers.length; i++) {
                    var traveler = travelers[i];
                    if (traveler.age == null && traveler.birthDate == null) {
                        continue;
                    }

                    if (traveler.isPrimary && traveler.address == null) {
                        continue;
                    }

                    newTravelers.push(traveler);
                }
            }

            return newTravelers;
        }

        function validateCoverages(coverages, quote) {
            var newCoverages = [];
            for (var j = 0; j < coverages.length; j++) {
                var coverage = coverages[j];
                if (coverage.selected) {
                    var newCoverage = { ratingId: coverage.ratingId };
                    if (coverage.selectedLimit) {
                        newCoverage.coverageLimit = coverage.selectedLimit;
                    }

                    if (!coverage.selectedLimit && coverage.limits.length > 1) {
                        continue;
                    }

                    if (newCoverage.ratingId !== 'CRCC' ||
                        (quote.policy.rentalCarReturnDate !== null &&
                        quote.policy.rentalCarPickupDate !== null)) {
                        newCoverages.push(newCoverage);
                    }
                }
            }

            return newCoverages;
        }

        function getQuoteByQuoteNumber(quoteNumber) {
            return agents.getCurrentAgent()
                .then(function (agent) {
                    return dataservice.getQuoteByQuoteNumber(quoteNumber, agent).then(function(quote) {
                        if (quote !== null && quote.policy !== null) {
                            var loadedQuote = {};
                            loadedQuote.bookingDate = quote.policy.initialBookingDate !== null ?
                                moment(quote.policy.initialBookingDate).format('MM/DD/YYYY') : null;
                            loadedQuote.destination = quote.policy.destinationCountry;
                            loadedQuote.useYr = quote.policy.useYear;
                            loadedQuote.startDate = quote.policy.useYearStartDate !== null ?
                                moment(quote.policy.useYearStartDate).format('MM/DD/YYYY') : null;
                            loadedQuote.resID = quote.policy.resID;
                            loadedQuote.departureDate = quote.policy.departureDate !== null ?
                                moment(quote.policy.departureDate).format('MM/DD/YYYY') : null;
                            loadedQuote.returnDate = quote.policy.returnDate !== null ?
                                moment(quote.policy.returnDate).format('MM/DD/YYYY') : null;
                            loadedQuote.totalTripCost = quote.policy.tripCost;
                            loadedQuote.quoteNumber = quote.policy.quoteNumber;

                            var traveler = quote.travelers[0];
                            loadedQuote.firstName = traveler.firstName;
                            loadedQuote.lastName = traveler.lastName;
                            loadedQuote.email = traveler.email;

                            if (quote.policy.policyAddress && quote.policy.policyAddress !== null) {
                                loadedQuote.address1 = quote.policy.policyAddress.address1;
                                loadedQuote.address2 = quote.policy.policyAddress.address2;
                                loadedQuote.city = quote.policy.policyAddress.city;
                                loadedQuote.postalCode = quote.policy.policyAddress.postalCode;
                                loadedQuote.state = quote.policy.policyAddress.stateOrProvince;
                            }

                            return partnerCustomerService
                                .getById(quote.policy.primaryTraveler).then(function (customer) {
                                if (customer && customer.phoneNumbers.length > 0) {
                                    loadedQuote.phone = customer.phoneNumbers[0].phoneNumber;
                                }

                                return loadedQuote;
                            });
                        }

                        return null;
                    });
                });
        }

        //#region Client Side Storage
        function setCurrentPackage(pkg, skipPremumClearing) {
            $localStorage.currentPackage = pkg;
            var storageQuote = $localStorage.quote;
            if (!skipPremumClearing && storageQuote && pkg && storageQuote.packageName && storageQuote.packageName.toLowerCase() !== pkg.packageName.toLowerCase()) {
                    // Converting to lower because bhtp-quote is having package name in lower and other one is normal case
                    storageQuote.premium = null;
            }
        }

        function getCurrentPackage() {
            if ($localStorage.currentPackage) {
                return $localStorage.currentPackage;
            }

            return null;
        }


        function setCurrentQuote(quote) {
            if (quote && ambassadorInformationSessionStorage.getAgentCode()) {
                quote.agentCode = ambassadorInformationSessionStorage.getAgentCode();
            }

            $localStorage.quote = quote;
        }

        function getCurrentQuote() {
            if ($localStorage.quote) {
                return $localStorage.quote;
            }

            return {};
        }

        function getCurrentQuoteOnReload() {
            // return the stored quote if the quote and package both have a valid matching package name
            if ($localStorage.quote && $localStorage.quote.packageName && $localStorage.currentPackage && $localStorage.currentPackage.packageName &&
                    $localStorage.quote.packageName.toLowerCase() === $localStorage.currentPackage.packageName.toLowerCase()) {
                return $localStorage.quote;
            }

            return {};
        }

        function setQuoteResponse(quote) {
            $localStorage.quoteResponse = quote;
        }

        function getQuoteResponse() {
            if ($localStorage.quoteResponse) {
                return $localStorage.quoteResponse;
            }

            return null;
        }
        //#endregion
    }

})();
