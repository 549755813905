(function () {
    'use strict';

    angular
        .module('agentPortal')
        .factory('sendQuoteFactory', ['$rootScope', '$uibModal', 'quotesService', 'utilService', 'gatewaySaveQuoteService', 'gatewayEmailService', 'portalService', sendQuoteFactory]);

    function sendQuoteFactory($rootScope, $modal, quotesService, utilService, gatewaySaveQuoteService, gatewayEmailService, portalService) {

        var service = {
            sendQuote: sendQuote,
            sendQuickQuote: sendQuickQuote
        };

        return service;

        function sendQuote(emailQuote) {
            openEmailQuoteModal(emailQuote);
        };

        function sendQuickQuote(modalQuotes, cta, quoteMaps) {
            openEmailQuickQuoteModal(modalQuotes, cta, quoteMaps);
        }

        async function onSendQuickQuote(quickQuoteRequest, quoteMaps) {
            const quotes = [];
            // Create an array of the selected quotes from the modal
            for (let i = 0; i <= quickQuoteRequest.quotes.length - 1; i++) {
                quotes.push(quoteMaps[quickQuoteRequest.quotes[i].ratingId])
            }

            // Grab the agent to stamp on the quote note
            let agent = await portalService.getAgentByInternalId();

            // Save the event and quotes being emailed
            let quoteSaveResponse;
            if (quickQuoteRequest.eventId) {
                quoteSaveResponse = await gatewaySaveQuoteService.updateEvent(quickQuoteRequest, quotes);
            } else {
                quoteSaveResponse = await gatewaySaveQuoteService.saveEvent(quickQuoteRequest, quotes);
            }
            
            // Assign the ids of the saved event/quotes
            quickQuoteRequest.eventId = quoteSaveResponse.event.id;

            for (let i = 0; i <= quoteSaveResponse.event.quotes.length - 1; i++) {
                const quoteToUpdate = quotes.find((q) => q.ratingId === quoteSaveResponse.event.quotes[i].planId);
                quoteToUpdate.id = quoteSaveResponse.event.quotes[i].id;
            }

            // Create email request
            let emailQuote = {
                primaryEmail: quickQuoteRequest.primaryTraveler.emailGroup.email,
                comment: quickQuoteRequest.message
            };
            
            // Email the saved quotes with the message
            return await gatewayEmailService.convertAndEmailQuickQuotes(quickQuoteRequest, quotes, emailQuote, agent);
        }

        function openEmailQuickQuoteModal(modalQuotes, cta, quoteMaps) {
            $modal.open({
                templateUrl: 'app/quotes/sendQuickQuoteModal.html',
                backdrop: true,
                windowClass: 'modal',
                controller: 'sendQuickQuoteController',
                controllerAs: 'vm',
                resolve: {
                    onSendQuote: function() {
                        return onSendQuickQuote;
                    },
                    quickQuoteRequest: function() {
                        return cta;
                    },
                    packages: function() {
                        return modalQuotes;
                    },
                    quoteMaps: function() {
                        return quoteMaps;
                    }
                }
            });
        }

        function openEmailQuoteModal(quote) {
            $modal.open({
                templateUrl: 'app/quotes/sendQuoteModal.html',
                backdrop: true,
                windowClass: 'modal',
                controller: 'sendQuoteController',
                resolve: {
                    quote: function () {
                        return quote;
                    }
                }
            });
        }
    }
})();