(function () {
    var myAppModule = angular.module('agentPortal');

    /**
     * @ngdoc service
     * @name requestOptions
     *
     * # requestOptions
     *
     * @description
     * provides authentication and other request information based on the environment
     */
    myAppModule
        .factory('requestOptions', ['$rootScope', 'storage', requestOptions]);
    function requestOptions($rootScope, storage) {
        return {
            getBaseUrl: function() {
                return $rootScope.config.CLIENT_API_BASE_URL;
            },
            getEligibilityBaseUrl: function () {
                return $rootScope.config.CLIENT_ELIGIBILITY_BASE_URL;
            },
            getAuthToken: function () {
                var token = localStorage.getItem('idToken');
                if (token) {
                    return token;
                }

                return null;
            },
            getOrigin: function() {
                return 'Agent';
            },
            getContentType: function() {
                return 'application/json';
            }
        };
    }
})();