(function () {
    'use strict';

    angular
        .module('app.quotes')
        .controller('PurchaseReceiptController', PurchaseReceiptController);

    PurchaseReceiptController.$inject = ['$window', '$q', 'dataservice', 'quotes', 'agents'];

    function PurchaseReceiptController($window, $q, dataservice, quotes, agents) {
        var vm = this;

        vm.title = 'Receipt';
        vm.errors = [];

        init();

        function init() {
            vm.quote = quotes.getCurrentQuote();
            vm.currentPackage = quotes.getCurrentPackage();
            vm.currentQuoteResponse = quotes.getQuoteResponse();

            quotes.setQuoteResponse(null);
            quotes.setCurrentPackage(null);
            quotes.setCurrentQuote(null);
        }
    }
})();
