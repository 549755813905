/* jshint global_api_url,global_clientsApi_url,angular */
(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name globalSettingsService
     *
     * # globalSettingsService
     *
     * @description
     * allows injection of global values, and better test mocking for these global values.
     * Global values come from the mvc layout view.
     *
     * This is added to the agentPortal and app.Core modules.
     */
    angular.module('agentPortal')
        .service('globalSettingsService', [globalSettingsService]);

    /**
     * VacationGuard support, until merged in fully.
     */
    angular.module('app.core')
        .service('globalSettingsService', [globalSettingsService]);


    function globalSettingsService() {
        var $this = this;

        $this.apiBaseUrl = function () {
            return global_api_url;
        };

        $this.clientsApiBaseUrl = function () {
            return global_clientsApi_url;
        };

        $this.agentsGatewayBaseUrl = function () {
            return global_agentsGatewayApi_url;
        };

        $this.vacationRentalPhone = function() {
            return global_vacationrental_phone;
        }

        $this.mainLogoFile = function() {
            return global_mainLogoFile;
        }

        $this.baseCdnUrl = function() {
            return global_baseCdnUrl;
        }

        $this.faviconUrl = function() {
            return global_faviconUrl;
        }

        $this.authLogoUrl = function() {
            return global_authLogoUrl;
        }
        
        return $this;
    }
})();