(function () {
    'use strict';

    /**
     * Quick Quote Directive
     *
     * Pass in redirectTo if you want the directive to redirect the user to a different page when clicking Get A Quote.
     * The CTA will be saved into session with the key "quickquote"
     *
     * If this directive is on a page, and you would like to load the cta from session, pass it in via quoteCta.
     * The data will be filled in the UI and the controller
     *
     * onResetPackages accepts a function, and is called when data points change in the UI
     *
     * onGetAQuote is the function that gets called when the user clicks Get A Quote and the redirectTo has not been defined.
     */

    angular
        .module('agentPortal')
        .directive('quickQuoteDirective', quickQuoteDirective);

    function quickQuoteDirective() {
        return {
            restrict: 'EA',
            scope: {
                redirectTo: '@',
                quoteCta: '=',
                tempQuote: '=',
                currentState: '=',
                currentAgentCode: '=',
                onResetAllPackages: '=',
                onGetAQuote: '=',
                agent: '=',
                packages: '=',
                agentAndState: '='
            },
            templateUrl: 'app/directives/quickquote/quickQuoteDirective.html',
            controller: quickQuoteController,
            controllerAs: "vm",
            bindToController: true
        }
    }

    quickQuoteController.$inject = ['$rootScope', '$stateParams', '$state', '$location', 'settings', 'quickQuotesService',
        'customersService', 'format', 'portalService', '$q', '$interval', '$timeout', 'eligibilityService', 'storage', 'utilService'];

    function quickQuoteController($rootScope, $stateParams, $state, $location, settings, quickQuotesService, customersService, format, portalService, $q, $interval, $timeout, eligibilityService, storage, utilService) {
        var vm = this;

        var storageKey = "quickquote";

        vm.state = {};
        vm.state.customerId = null;
        vm.state.customer = null;
        vm.state.readOnly = false;
        vm.showProductTable = false;

        vm.ctaLoaded = false;

        /**
        * @description
        * initialize quick quote directive controller
        */
        function init() {
            vm.errors = settings.errors;

            var promises = [];

            if (!vm.state.packageStateConfig) {
                vm.state.packageStateConfig = eligibilityService.getPackageStateConfiguration();
            }

            if ($stateParams.customerId) {
                vm.state.readOnly = true;
                vm.state.customerId = $stateParams.customerId;
                customersService.getById(vm.state.customerId).then(function (customer) {
                    vm.state.customer = customer;
                    vm.state.cta = quickQuotesService.getCombinedQuoteRequest(vm.state.customer);
                    vm.ctaLoaded = true;
                    if (customer && customer.address && customer.address.stateOrProvince) {
                        vm.currentState = customer.address.stateOrProvince;
                        vm.agentAndState.state = vm.currentState;
                    }
                });
            }
            else {
                vm.state.cta = quickQuotesService.getCombinedQuoteRequest(null);
                vm.ctaLoaded = true;
            }

            if (vm.quoteCta) {
                vm.state.cta = vm.quoteCta;
                vm.ctaLoaded = true;
                vm.currentState = vm.quoteCta.residenceState;
                vm.agentAndState.state = vm.currentState;
            }
        }

        vm.onStateChanged = function (newState) {
            vm.currentState = newState;
            vm.agentAndState.state = newState;
        }

        vm.onAgentCodeChanged = function (newAgentCode) {
            vm.currentAgentCode = newAgentCode;
            vm.agentAndState.agentCode = newAgentCode;
        }

        vm.onServiceCall = function (inProgress) {
            if (inProgress === true) {
                $rootScope.$broadcast('showOverlay');
            }
            else {
                $rootScope.$broadcast('hideOverlay');
            }
        }

        vm.onTempQuoteChanged = function (tempQuote) {
            vm.tempQuote = tempQuote;
        }

        vm.onServiceError = function (errorMessage) {

        }

        vm.onValidQuote = function (smartQuote) {
            var errMessageTitle = 'No matches found...';
            var errMessage = 'The trip information you provided unfortunately does not generate a match for an eligible travel insurance plan at this time. If you feel you have incorrectly input your travel details, please try again.  Otherwise, we hope you will consider Berkshire Hathaway Travel Protection for your future trips.';
            if (smartQuote.response.length == 0) {
                utilService.showPopup(errMessageTitle, errMessage);
                return;
            }

            var listOfSameAsIds = [];
            smartQuote.response.forEach(quote => {
                // Code 2027 is State Block: We’re sorry, but New York residents are not eligible to buy this product
                if (!quote.messages || !quote.messages.some(m => m.code == "2027")){
                    listOfSameAsIds = listOfSameAsIds.concat(quote.package.sameAs);
                }
            });

            smartQuote.response = smartQuote.response.filter(quote => {
                return !listOfSameAsIds.includes(quote.package.ratingId)
            });

            var quotes = [];
            var packages = [];
            for (var i = 0; i < smartQuote.response.length; i++) {
                var processedQuote = smartQuote.response[i];
                if (processedQuote) {
                    var coverages = [];
                    if (processedQuote.coverages) {
                        if (processedQuote.coverages['included']) {
                            for (var j = 0; j < processedQuote.coverages['included'].length; j++) {
                                coverages.push(processedQuote.coverages['included'][j]);
                            }
                        }

                        if (processedQuote.coverages['optional']) {
                            for (var j = 0; j < processedQuote.coverages['optional'].length; j++) {
                                coverages.push(processedQuote.coverages['optional'][j]);
                            }
                        }
                    }

                    var addPackage = true;
                    if (processedQuote.package) {
                        var isInternational = false;
                        if (vm.state.cta.destinationCountry && vm.state.cta.destinationCountry.isoCode2 !== 'US') {
                            isInternational = true;
                        }

                        if ((processedQuote.package.availability === 'International' && !isInternational) ||
                            processedQuote.package.availability === 'Domestic' && isInternational) {
                            addPackage = false;
                        }
                    }

                    // remove any packages that had API errors
                    if (processedQuote.messages && processedQuote.messages.length > 0) {
                        addPackage = false;
                    }

                    if (addPackage) {
                        processedQuote.package.coverages = coverages;
                        quotes.push(angular.copy(processedQuote));
                        packages.push(angular.copy(processedQuote.package));
                    }
                }
            }

            if (quotes.length == 0) {
                utilService.showPopup(errMessageTitle, errMessage);
                return;
            }

            var quickQuoteData = {
                cta: vm.state.cta,
                packages: packages,
                quotes: quotes
            }

            if (vm.redirectTo) {
                storage.set(storageKey, quickQuoteData);
                $state.go(vm.redirectTo);
            }
            else if (vm.onGetAQuote) {
                vm.onGetAQuote(quickQuoteData)
            }
        }

        /**
         * @description
         * runs the function passed into the directive for resetting pacakges if one is defined
         */
        vm.resetAllPackages = function () {
            if (vm.onResetAllPackages) {
                vm.onResetAllPackages();
            }
        }

        init();
    }
})();
