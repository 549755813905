(function () {
    'use strict';
    /**
     * @ngdoc factory
     * @name policiesService
     *
     * # policiesService
     *
     * @description
     * backend API integration for policies page
     */
    angular.module('agentPortal')
        .factory('policiesService', ['$rootScope', 'portalService', '$resource', '$q', 'customersService', 'settings', 'utilService', '$uibModal', 'paymentMethods', 'apiUtilService', 'globalSettingsService', 'gatewayPolicyDocumentService', policiesService]);

    function policiesService($rootScope, portalService, $resource, $q, customersService, settings, utilService, $modal, paymentMethods, apiUtilService, globalSettingsService, gatewayPolicyDocumentService) {
        var policyResendByIdUrl = globalSettingsService.apiBaseUrl() + '/v1/Agents/:agentCode/Policy/resend';
        var cancelPolicyUrl = globalSettingsService.apiBaseUrl() + '/v1/Policy/CancelConfirm';
        var getPolicyByPolicyNumberUrl = globalSettingsService.apiBaseUrl() + '/v1/agents/:agentCode/policy/:policyNumber';
        var queryPoliciesPagedUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/agency/:agencyCode/policies';

        return {
            getDateFilters: getDateFilters,
            getStatusList: getStatusList,
            getPolicyByPolicyNumber: getPolicyByPolicyNumber,
            getForCustomer: getForCustomer,
            setPolicyActions: setPolicyActions,
            cancelPolicy: cancelPolicy,
            confirmCancelPolicy: confirmCancelPolicy,
            resendPolicyDocs: resendPolicyDocs,
            loadPolicySummaries: loadPolicySummaries,
            getPolicyDocument: getPolicyDocument,
            getPresignedPolicyDocument: getPresignedPolicyDocument
        };

        /**
         * @description
         * loads policy information by given policy number
         */
        function getPolicyByPolicyNumber(policyNumber) {
            var deferred = $q.defer();

            portalService.getAgentByInternalId().then(function (agent) {
                $resource(getPolicyByPolicyNumberUrl, { agentCode: agent.agentCode, policyNumber: policyNumber })
                    .get()
                    .$promise
                    .then(function (policyResponse) {
                        if (!policyResponse) {
                            deferred.reject('An error occurred while attempting to load policy ' + policyNumber + '.');
                        }
                        else if (apiUtilService.areThereApiErrorMessages(policyResponse.messages)) {
                            deferred.reject(policyResponse.messages[0]);
                        }
                        else {
                            var policyContainer = policyResponse.response;

                            utilService.sendPrimaryTravelerToZeroIndex(policyContainer.travelers);

                            deferred.resolve(policyContainer);
                        }
                    },
                    function (error) {
                        deferred.reject(error);
                    });
            });

            return deferred.promise;
        }

        /**
         * @description
         * loads policy information by given customer id
         */
        function getForCustomer(customerId) {
            return portalService.getAgentByInternalId().then(function (agent) {
                return $resource(customerPoliciesUrl, { agentId: agent.agentId, customerId: customerId }).query().$promise;
            });
        }
        /**
         * @description
         * returns various time-ranges for date related filtering
         */
        function getDateFilters() {
            return [
                { interval: 'All', name: 'All' },
                { interval: '0', name: 'Today' },
                { interval: '1', name: 'This Week' },
                { interval: '2', name: 'This Month' },
                { interval: '3', name: 'Past Month' },
                { interval: '4', name: 'YTD' }
            ];
        }

        /**
         * @description
         * loads filters for status list - currently hardcoded
         */
        function getStatusList() {
            return [
                { value: '', name: 'All' },
                { value: 'Active', name: 'Active' },
                { value: 'Effective', name: 'Effective' },
                { value: 'Expired', name: 'Expired' },
                { value: 'Cancelled', name: 'Cancelled' }
            ];
        }
        /**
         * @description
         * Sets the policy actions for an item in a grid
         */
        function setPolicyActions(policy, cancelFunction) {
            policy.actions = [];

            // An ambassador cannot edit or cancel policy if payment method is 'invoice' or 'prepaid'
            // All other users can do that but not if the payment method is 'prepaid'
            if (policy.transactionPaymentMethods && policy.transactionPaymentMethods.length > 0) {
                var hasPrepaidMethod = false;
                var hasInvoiceMethod = false;

                for (var i = 0; i < policy.transactionPaymentMethods.length; i++) {
                    switch (policy.transactionPaymentMethods[i].toLowerCase()) {
                        case paymentMethods.invoice:
                            hasInvoiceMethod = true;
                            break;
                        case paymentMethods.prepaid:
                            hasPrepaidMethod = true;
                    }
                }

                if ((hasPrepaidMethod || hasInvoiceMethod)) {
                    // removes these buttons for any user for prepaid and invoice
                    policy.canBeCancelled = false;
                    policy.canBeEdited = false;
                }
            }

            if (policy.fulfillmentMethod === "Email") {
                policy.actions.push({ label: "Resend", icon: "fa fa-paper-plane-o", href: '#', click: resendPolicyDocs });
            }

            // For now, only add if action is allowed.
            if (policy.canBeEdited === true) {
                policy.actions.push({ label: "Edit", icon: "fa fa-pencil-square-o", href: 'policies/edit/' + policy.policyNumber, disabled: policy.canBeEdited != true });
            }

            if (policy.canBeCancelled === true) {
                policy.actions.push({ label: "Cancel", click: cancelFunction, icon: "fa fa-times", href: '#', disabled: policy.canBeCancelled != true });
            }
        }
        /**
         * @description
         * cancels policy by id as long as agent has authorization to do so
         */
        function cancelPolicy(policyNumber) {
            var deferred = $q.defer();
            portalService.getAgentByInternalId()
                .then(function (agent) {
                    var cancelApi = $resource(cancelPolicyUrl,
                        {
                            policyNumber: policyNumber
                        },
                        {
                            cancelPolicy: { method: 'POST' }
                        });

                    var cancelData = {
                        agentCode: agent.agentCode,
                        agencyCode: agent.agencyCode,
                        policyNumber: policyNumber
                    };

                    cancelApi.cancelPolicy(cancelData)
                        .$promise
                        .then(function (cancelResult) {
                            if (!cancelResult) {
                                deferred.reject('An error occurred while attempting to cancel policy ' + policyNumber + '.');
                            }
                            else if (apiUtilService.areThereApiErrorMessagesExceptProvidedCodes(cancelResult.messages, apiUtilService.refundByCheckErrorCodes())) {
                                deferred.reject(cancelResult.messages[0]);
                            }
                            else if (cancelResult && cancelResult.response && !cancelResult.response.cancelSuccessful) {
                                deferred.reject('The policy ' + policyNumber + ' could not be cancelled.');
                            }
                            else {
                                deferred.resolve(cancelResult);
                            }
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                        );
                });

            return deferred.promise;
        }

        function confirmCancelPolicy(policyDetails, success) {
            var modalInstance = $modal.open({
                templateUrl: 'app/policies/cancelModal.html',
                resolve: {},
                controller: ['$scope', '$uibModalInstance', function ($scope, $modalInstance) {
                    $scope.vm = policyDetails;

                    $scope.vm.getCancelText = function () {
                        var text = "";
                        if ($scope.vm.canBeRefunded === true) {
                            text = $scope.vm.policyNumber + " is within the free-look period and will be eligible for a full refund. Are you sure you wish to continue?";
                        } else {
                            text = $scope.vm.policyNumber + " is outside of the free-look period and will not be eligible for any refund. Are you sure you wish to continue?";
                        }

                        return text;
                    };
                    $scope.vm.cancelText = $scope.vm.getCancelText();

                    $scope.ok = function () {
                        cancelPolicy(policyDetails.policyNumber).then(
                            function (result) {
                                // did any continuable errors occur during cancel?
                                var refundErrors = apiUtilService.getApiErrorMessagesWithProvidedCodes(result.messages, apiUtilService.refundByCheckErrorCodes());

                                if (refundErrors && Array.isArray(refundErrors) && refundErrors.length > 0) {
                                    utilService.showPopup(
                                        "Error",
                                        refundErrors,
                                        null,
                                        function refundErrorContinue(){
                                            if (success) {
                                                success(result);
                                            }
                                        }
                                    );

                                    return;
                                }

                                if (success) {
                                    success(result);
                                }
                            },
                            function (error) {
                                if (error != null) {
                                    utilService.showPopup("Error", "An error occurred during Cancel Policy");
                                }
                            }
                        );
                        $modalInstance.dismiss('close');
                    };

                    $scope.cancel = function () {
                        $modalInstance.dismiss('cancel');
                    };
                }]
            });
        };

        function resendPolicyDocs(policyDetails) {
            // policyDetails is an 'action' in the case of a cog, or a policy in the case of the details page
            var policyId = (policyDetails.policyId) ? policyDetails.policyId : policyDetails.id;

            portalService.getAgentByInternalId().then(function (agent) {
                var resendApi = $resource(policyResendByIdUrl, { agentCode: agent.agentCode }, { resend: { method: 'POST' } });
                var resendData = {
                    policyId: policyId
                };

                resendApi.resend(resendData)
                    .$promise
                    .then(function (resendResult) {
                        if (!resendResult) {
                            utilService.showPopup("Resend Policy", "There was a problem sending email. Please try again later.");
                        }
                        else if (apiUtilService.areThereApiErrorMessages(resendData.messages)) {
                            utilService.showPopup(resendData.messages[0]);
                        }
                        else {
                            utilService.showMessagePopup("Resend Policy", "Your policy documents have been sent. They should arrive in your email shortly.");
                        }
                    },
                    function (error) {
                        utilService.showPopup("Resend Policy", "There was a problem sending email. Please try again later.");
                    });
            });
        };

        /**
         * @description
         * retrieves policies for a given agent (i.e., logged in user)
         */
        function loadPolicySummaries(agentCode, status, packageIdsCsv, pageNumber, orderBy, direction, dateSelected, customerId, searchText) {
            var now = moment();
            var deferred = $q.defer();
            var startDate = '';
            var endDate = '';

            if (dateSelected && dateSelected.startDate) {
                startDate = dateSelected.startDate;
            }

            if (dateSelected && dateSelected.endDate) {
                endDate = dateSelected.endDate;
            }

            // convert package ids from csv to array.
            var packageIds = packageIdsCsv ? packageIdsCsv.split(',') || [] : [];

            portalService.getAgentByInternalId().then(function (agent) {
                var searchCriteria = {
                    agencyCode: agent.agencyCode,
                    agencyName: agent.agencyName,
                    agentCode: agentCode,
                    packageIds: packageIds,
                    pageNumber: pageNumber,
                    orderBy: orderBy,
                    direction: direction,
                    startDate: startDate,
                    endDate: endDate,
                    pageSize: 10,
                    customerId: customerId || null,
                    searchValue: searchText,
                    status: status
                };

                var policiesApi = $resource(queryPoliciesPagedUrl, { agencyCode: agent.agencyCode }, { post: { method: 'POST' } });

                policiesApi.post(searchCriteria)
                    .$promise
                    .then(function (policySummaryResponse) {
                        if (!policySummaryResponse) {
                            deferred.reject('An error occurred while attempting to load policy summaries.');
                        }
                        else if (apiUtilService.areThereApiErrorMessages(policySummaryResponse.messages)) {
                            deferred.reject(policySummaryResponse.messages[0]);
                        }
                        else {
                            deferred.resolve(policySummaryResponse.response);
                        }
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                    );

            }, function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getPolicyDocument(policyNumber) {
            return gatewayPolicyDocumentService.getPolicyDocument(policyNumber);
        }

        function getPresignedPolicyDocument(policyNumber, presignedRequest) {
            return gatewayPolicyDocumentService.getPresignedPolicyDocument(policyNumber, presignedRequest);
        }
    }
})();
