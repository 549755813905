(function () {

    angular
        .module('app.quotes')
        .directive('packageBreakdown', packageBreakdown);

    packageBreakdown.$inject = ['packageDisplay'];

    function packageBreakdown(packageDisplay) {
        var directive = {
            restrict: 'EA',
            scope: {
                packages: '=',
                selectedState: '=',
                quotedState: '=',
                quoting: '=',
                quote: '='
            },
            templateUrl: 'app/quotes/packagebreakdown.html',
            replace: true,
            bindToController: true,
            controllerAs: 'vm',
            controller: PackageBreakdownController,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var vm = scope.vm;

            scope.$watch(attrs.packages, function (packages) {
                if (packages) {
                    setupPackageDisplay(packages);
                }
            }, true);

            function setupPackageDisplay(packages) {
                var allCoverages = [];
                vm.packageNames = [];
                vm.coverageGroups = {};
                vm.packageMaps = {};

                for (var i = 0; i < packages.length; i++) {
                    var pkg = packages[i];
                    var pkgData = packageDisplay.refinePackageData(pkg, allCoverages);

                    // Add current package to package map
                    if (pkg.packageName) {
                        vm.packageMaps[pkg.packageName] = pkgData;
                        vm.packageNames.push(pkg.packageName);
                    }
                }

                vm.coverageGroups = packageDisplay.groupCoverages(allCoverages);
            }
        }
    }

    PackageBreakdownController.$inject = ['$state', 'quotes', 'agents'];

    function PackageBreakdownController($state, quotes, agents) {
        var vm = this;

        vm.buyPackage = buyPackage;

        function buyPackage(pkg, selectedState) {
            if (!pkg.hasPackageError && !pkg.validationFailed) {
                quotes.setCurrentPackage(pkg);
            }

            if (pkg.partnerPackage) {
                vm.quote.packageName = pkg.packageName;
                vm.quote.premium = vm.packageMaps[pkg.packageName].package.premium;
                vm.quote.isPartnerProduct = true;
                quotes.setCurrentQuote(vm.quote);

                $state.go('quote', { packageName: pkg.packageName });
            }
            else {
                var bhtpQuote = quotes.createBhtpQuoteFromQuickQuote(vm.quote, pkg);
                bhtpQuote.isPartnerProduct = false;
                quotes.setCurrentQuote(bhtpQuote);

                var quoteResult = quotes.getQuoteResult(bhtpQuote.policy.packageName);
                if (quoteResult !== null) {
                    quotes.setQuoteResponse(quoteResult);
                }

                $state.go('quoteBHTP.' + pkg.packageName.toLowerCase() + 'Quick');
            }
        }
    }

})();
