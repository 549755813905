(function () {
    'use strict';

    angular
        .module('app')
        .filter('datelocal', datelocal);

    datelocal.$inject = ['moment'];

    function datelocal(moment) {
        return function (input, timezone) {
            if (timezone) {
                return moment(input).tz(timezone).format('MM/DD/YYYY');
            } else {
                return moment(input).format('MM/DD/YYYY');
            }
        };
    }

})();
