(function () {
    'use strict';

    angular
        .module('app.quotes')
        .controller('QuickQuoteController', QuickQuoteController);

    QuickQuoteController.$inject = ['$q', 'dataservice', 'quotes', 'moment', 'agents'];

    function QuickQuoteController($q, dataservice, quotes, moment, agents) {
        var vm = this;
        vm.title = 'Get a Quote';
        vm.uibDateFormat = "MM/dd/yyyy";
        vm.momentDateFormat = "MM/DD/YYYY"; 
        vm.quoting = false;
        vm.quote = { };

        vm.datePickers = {
            departureDate: false,
            returnDate: false,
            tripDepositDate: false
        };

        vm.today = moment().format('YYYY-MM-DD');

        vm.tripDepositDateOptions = {
            maxDate: new Date()
        };
        vm.departureDateOptions = { minDate: new Date() };
        vm.returnDateOptions = { minDate: new Date() };

        //* Functions exposed to View
        vm.openDatePicker = openDatePicker;
        vm.submitForm = submitForm;

        init();

        vm.stringDate = function ($event, source) {
            if (vm.quote[source + 'Full']) {
                // if it came from the datepicker, its a date and we want a string version of it
                if (angular.isDate(vm.quote[source + 'Full'])) {
                    vm.quote[source] = moment(vm.quote[source + 'Full']).format(vm.momentDateFormat);
                }
                else {
                    // if it was typed, we'll want the date version too so the field doesn't get wiped out
                    vm.quote[source] = vm.quote[source + 'Full'];
                    vm.quote[source + 'Full'] = moment(vm.quote[source], vm.momentDateFormat);
                }
            }
        };

        //* Method Declarations
        function init() {
            var promises = [].concat(
                getCountries(),
                getStates(),
                getPackages()
            );

            $q.all(promises)
                .then(function () {
                    // ready function
                });

            quotes.setQuoteResponse(null);
            quotes.setCurrentPackage(null);
        }

        function getCountries() {
            return dataservice.getCountries()
                .then(function (data) {
                    vm.countries = data;
                    return vm.countries;
                });
        }

        function getPackages() {
            return agents.getCurrentAgent()
                .then(function (agent) {
                    return dataservice.getPackageStateConfigs(null, agent)
                        .then(function (data) {
                            vm.packages = data;
                            return vm.packages;
                        });
                });
        }

        function getStates() {
            return dataservice.getStates()
                .then(function (data) {
                    vm.states = data;
                    return vm.states;
                });
        }

        function openDatePicker($event, source) {
            $event.preventDefault();
            $event.stopPropagation();

            // Keep the current state of the selected date picker so we can toggle it on click
            var isOpen = vm.datePickers[source];

            for (var key in vm.datePickers) {
                if (vm.datePickers.hasOwnProperty(key)) {
                    vm.datePickers[key] = false;
                }
            }

            vm.datePickers[source] = !isOpen;
        }

        function submitForm(formValid) {
            if (formValid === true) {
                vm.quoting = true;
                vm.quotedState = vm.quote.state;
                quotes.getSmartQuotes(vm.quote, vm.packages)
                    .then(function (data) {
                        vm.packages = data;
                        vm.quoting = false;
                    }, function (response) {
                        // handle an error response.
                        vm.packages = null;
                        vm.quoting = false;
                    });
            }
        }
    }

})();
