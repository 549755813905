(function () {
    'use strict';

    angular
        .module('app.directives')
        .directive('bhMinDate', bhMinDate)
        .directive('bhMaxDate', bhMaxDate);

    bhMaxDate.$inject = ['moment'];

    function bhMaxDate(moment) {
        return {
            restict: 'A',
            require: 'ngModel',
            link: link
        };

        function link(scope, elem, attrs, ctrl) {
            attrs.$observe('bhMaxDate', function (value) {
                if (value) {
                    validate(ctrl.$viewValue);
                }
            });

            ctrl.$validators.bhMaxDate = function (modelValue, viewValue) {
                return validate(modelValue);
            };

            function validate(value) {
                var valid = true;

                if (value) {
                    var thisDate = moment(new Date(value));

                    if (attrs.bhMaxDate) {
                        var maxDate = moment(new Date(attrs.bhMaxDate));

                        valid = thisDate.diff(maxDate, 'days') <= 0;
                        ctrl.$setValidity('bhMaxDate', valid);
                    }
                }

                return valid;
            }
        }
    }

    bhMinDate.$inject = ['moment'];

    function bhMinDate(moment) {
        return {
            restict: 'A',
            require: '?ngModel',
            link: link
        };

        function link(scope, elem, attrs, ctrl) {
            attrs.$observe('bhMinDate', function (value) {
                if (value) {
                    validate(ctrl.$viewValue);
                }
            });

            ctrl.$validators.bhMinDate = function (modelValue, viewValue) {
                return validate(modelValue);
            };

            function validate(value) {
                var valid = true;

                if (value) {
                    var thisDate = moment(new Date(value));

                    if (attrs.bhMinDate) {
                        var minDate = moment(new Date(attrs.bhMinDate));

                        valid = thisDate.diff(minDate, 'days') >= 0;
                        ctrl.$setValidity('bhMinDate', valid);
                    }
                }

                return valid;
            }
        }
    }

})();
