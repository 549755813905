/* global moment:false, Rx:false, dataLayer:false */
(function () {

    angular
        .module('app.core')
        .constant('moment', moment)
        .constant('packageNames', {
            'timeshareplus': 'timeshareplus',
            'timeshareplusmid': 'timeshareplusmid',
            'timeshareplushigh': 'timeshareplushigh',
            'timesharepluslow': 'timesharepluslow',
            'timeshareannual1': 'timeshareannual1',
            'timeshareannual2': 'timeshareannual2',
            'leisureplus': 'leisureplus',
            'leisurepluslow': 'leisurepluslow',
            'leisureplusflatrate': 'leisureplusflatrate',
            'travelclub': 'travelclub',
            'travelclublow': 'travelclublow',
            'travelclubhigh': 'travelclubhigh',
            'travelclubhighest': 'travelclubhighest',
            'postdeparture': 'postdeparturebasic',
            'postdepartureplus': 'postdepartureplus',
            'securitydepositprotection': 'securitydepositprotection',
            'viplifestyle': 'viplifestyle',
            'leisureplusenc': 'leisureplusenc',
            'postdeparture': 'postdeparture',
            'postdepartureEncompass': 'enc-postdeparture',
            'clubEncompass': 'enc-club',
            'club': 'club',
            'clubgoldEncompass': 'enc-clubgold',
            'clubgold': 'clubgold'
        })
        .constant('Rx', Rx);
})();
