(function () {
    'use strict';

    angular
        .module('app')
        .filter('iif', iif);

    iif.$inject = [];

    function iif() {
        return function (input, trueValue, falseValue) {
            return input ? trueValue : falseValue;
        };
    }

})();
