/* jshint -W074 */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .factory('packageDisplay', packageDisplay);

    packageDisplay.$inject = [];

    function packageDisplay() {

        var service = {
            refinePackageData: refinePackageData,
            groupCoverages: groupCoverages
        };
        return service;

        function refinePackageData(pkg, allCoverages, groupByName) {
            var coverageMap = {};

            if (pkg && pkg.coverages) {
                for (var j = 0; j < pkg.coverages.length; j++) {
                    var coverage = pkg.coverages[j];

                    if (coverage.displayGroup === 'Optional') {
                        coverage.displayGroup = coverage.displayGroupOverride;
                        coverage.displayGroupOrder = coverage.displayGroupOrderOverride;
                    }

                    if (!searchForCoverageInList(coverage, allCoverages, groupByName)) {
                        // Add current coverage to all coverages array
                        allCoverages.push({
                            name: coverage.name,
                            displayGroup: coverage.displayGroup,
                            category: coverage.category
                        });
                    }

                    // Add current coverage to current package's coverage map
                    var coverageKey = groupByName ? coverage.name : coverage.category;
                    coverageMap[coverageKey] = {
                        name: coverage.name,
                        shortName: coverage.shortName,
                        description: coverage.description,
                        limit: coverage.coverageLimitDisplayText,
                        type: coverage.type,
                        selected: coverage.selected
                    };
                }

                mapErrorsToFields(pkg);
            }

            return {
                coverages: coverageMap,
                package: pkg,
                allCoverages: allCoverages
            };
        }

        function searchForCoverageInList(coverage, allCoverages, groupByName) {
            for (var k = 0; k < allCoverages.length; k++) {
                if (allCoverages[k].displayGroup === coverage.displayGroup &&
                    ((!groupByName && allCoverages[k].category === coverage.category) ||
                    (groupByName && allCoverages[k].name === coverage.name))) {

                    return true;
                }
            }

            return false;
        }

        function groupCoverages(allCoverages, groupByName) {
            var coverageGroups = {};
            for (var key in allCoverages) {
                if (allCoverages.hasOwnProperty(key)) {
                    var coverage = allCoverages[key];

                    if (!coverageGroups[coverage.displayGroup]) {
                        coverageGroups[coverage.displayGroup] = {
                            displayGroupOrder: coverage.displayGroupOrder,
                            coverages: []
                        };
                    }

                    if (groupByName) {
                        coverageGroups[coverage.displayGroup].coverages.push(coverage.name);
                    }
                    else {
                        coverageGroups[coverage.displayGroup].coverages.push(coverage.category);
                    }
                }
            }

            angular.forEach(coverageGroups, function (value) {
                value.coverages.sort(function (a, b) {
                    if (a !== b) {
                        if (a > b) { return 1; }
                        if (a < b) { return -1; }
                    }
                    return a - b;
                });
            });

            return coverageGroups;
        }

        function mapErrorsToFields(pkg) {
            if (pkg.messages && pkg.messages.length > 0) {
                var fields = [];
                for (var i = 0; i < pkg.messages.length; i++) {
                    var message = pkg.messages[i];
                    switch (message.code) {
                        case '2713':
                        case '2205':
                            fields.push('Total Trip Cost');
                            break;
                        case '2233':
                            fields.push('Initial Trip Deposit Date');
                            break;
                        case '2229':
                            fields.push('Destination Country');
                            break;
                        case '2230':
                        case '2715':
                            fields.push('Departure Date');
                            break;
                        case '2231':
                        case '2717':
                            fields.push('Return Date');
                            break;
                        case '2914':
                            pkg.hasPackageError = true;
                            break;
                    }
                }

                fields.sort();

                pkg.errorFields = '';
                if (fields.length > 0) {
                    pkg.errorFields = '<ul class="text-left list-unstyled">';
                    for (var f = 0; f < fields.length; f++) {
                        pkg.errorFields += '<li>' + fields[f] + '</li>';
                    }

                    pkg.errorFields += '</ul>';
                }
            }
        }
    }

})();
