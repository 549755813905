(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name quotesController
     *
     * # quotesController
     *
     * @description
     * controller for quotes screen that shows grid of quotes associated with the logged-in user
     */
    angular.module('agentPortal')
        .controller('quotesController', ['quotesService', '$timeout', 'portalService', 'utilService', '$q', '$window', 'loadQuoteFactory', 'quoteGridConfigService', quotesController]);

    function quotesController(quotesService, $timeout, portalService, utilService, $q, $window, loadQuoteFactory, quoteGridConfigService) {
        var vm = this;

        vm.title = 'Quotes';
        vm.ready = false;

        vm.selected = [];
        vm.selectedDeleteIds = [];
        var currentQuotes = [];

        vm.refreshDataFlag = false;
        vm.reloadDataFlag = false;

        vm.quoteActions = {
            removeQuote: confirmRemoveQuote
        };

        /**
         * @description
         * Will initialize the view model and grid configuration
         */
        function init() {
            portalService.getAgentByInternalId().then(function (agent) {
                vm.agent = agent;
                quoteGridConfigService.getQuoteGridConfig(vm.agent, vm.quoteActions).then((config) => {
                    vm.gridConfig = config;

                    $('#delconfirm').on('hidden.bs.modal', function (e) {
                        vm.selectedDeleteIds = [];
                    });
                    
                    vm.ready = true;
                });
            });
        }

        /**
         * @description
         * grid implementation - loading of quotes data
         */
        vm.loadData = function () {
            var deferredPromise = $q.defer();
            var customFilters = vm.gridConfig.getCustomFilters(vm)

            quotesService.refreshQuoteSummaries(
                customFilters[0].value,
                customFilters[1].value,
                vm.gridConfig.currentPage,
                vm.gridConfig.getOrderBy(),
                vm.gridConfig.getSortOrder(),
                vm.gridConfig.getDateFilter(),
                "",
                vm.gridConfig.searchText,
                customFilters[3].value)
                .then(function (results) {
                    vm.gridConfig.totalRecords = results.pagination.totalRows || 0;

                        currentQuotes = results.quotes || [];
                        quoteGridConfigService.appendAdditionalQuoteGridDate(currentQuotes);

                        deferredPromise.resolve(results.quotes);
                }, function (error) {
                    if (error && error.status === 404) {
                        vm.gridConfig.totalRecords = 0;
                        deferredPromise.resolve([]);
                    } else {
                        deferredPromise.reject(error);
                        utilService.parseQuotesApiError(error);
                    }
                });

            return deferredPromise.promise;
        };

        /**
         * @description
         * grid implementation - sets flag to trigger refresh of the grid data, i.e., on filter-change
         */
        vm.refreshData = function () {
            vm.refreshDataFlag = true;
        };

        /**
         * @description
         * grid implementation - sets flag to reload of grid data from the server
         */
        vm.reloadData = function () {
            vm.reloadDataFlag = true;
        };

        /**
         * @description
         * grid implementation - filter-change event handling, triggers refreshData
         */
        vm.filterChanged = function () {
            vm.gridConfig.currentPage = 1;
            vm.refreshData();
        };

        /**
         * @description
         * grid implementation - the search term has changed
         */
        vm.searchChanged = function () {    
            if (vm.timer) {
                $timeout.cancel(vm.timer);
            }
            vm.timer = $timeout(function () {
                vm.filterChanged();
            }, 700);
        }

        /**
         * @description
         * grid implementation - reseting of the filters
         */
        vm.clearFilter = function () {
            vm.gridConfig.currentPage = 1;
            vm.searchText = "";
            vm.selected = [];
            vm.refreshData();
        };

        /**
         * @description
         * removes selected quotes by their ids by calling backend API(s) for soft-delete
         */
        vm.removeQuotes = function (removeQuotes) {
            if (!removeQuotes || removeQuotes.length == 0) {
                return;
            }

            quotesService.removeQuotes(removeQuotes).then(function () {
                vm.clearFilter();
            });
        };

        /**
         * @description
         * sets up quotes to be removed once user confirms his intent to remove the quotes, this function
         * is called from the remove quote button. 
         */
        vm.confirmRemoveSelectedQuotes = function () {
            vm.selectedQuotesToRemove = [];
            if (vm.selected) {
                for (var i = 0; i < vm.selected.length; i++) {
                    const quote = currentQuotes.find(function (q) {
                        return q.quoteNumber === vm.selected[i];
                    });
                    vm.selectedQuotesToRemove.push(quote.removeData);
                }
            }
            $('#delconfirm').modal('toggle');
        };

        /**
         * @description
         * sets up quotes to be removed once user confirms his intent to remove the quotes, this function
         * is called from the remove quote cog. 
         */
        vm.confirmRemoveQuote = function (quote) {
            confirmRemoveQuote(quote);
        };

        /**
         * @description
         * sets up quotes to be removed once user confirms his intent to remove the quotes, this function
         * is called from the remove quote cog. 
         */
        function confirmRemoveQuote (quote) {
            vm.selectedQuotesToRemove = [quote.removeData];
            $('#delconfirm').modal('toggle');
        };

        /**
         * @description
         * Looks at the getQuote response, if valid routes to the purchase path, if the
         * quote contains errors it routes to the quote detail page.
         */
        async function routeQuote(ratedQuoteResponse, error) {
            const errorPath = 'quotes/view/' + vm.editQuoteNumber;
            // This means that we were able to pull back the quote and get a fresh rating.
            // At this point we can drop the quote in the purchase path
            if (ratedQuoteResponse) {
                try {
                    const viewModel = await loadQuoteFactory.getQuoteViewModel(ratedQuoteResponse);

                    loadQuoteFactory.enterPurchasePathWithExistingQuote(
                        viewModel.package,
                        viewModel.customerId,
                        viewModel.quoteNumber,
                        viewModel.event,
                        viewModel.ratingId,
                        viewModel.state,
                        viewModel.passengers,
                        viewModel.coverages, 
                        viewModel.flights);
                        
                // If there are any errors we are going to drop them on the quote details page which will explain the issues with the quote
                } catch (err) {
                    $window.location.href = errorPath;
                }
            // If there are any errors we are going to drop them on the quote details page which will explain the issues with the quote
            } else if (error) {
                $window.location.href = errorPath;
            }
        }

        /**
        * @description
        * Runs rating on quote and handles the response/error
        */
        async function editQuote(quote) {
            try {
                vm.editQuoteNumber = quote.quoteNumber;
                const result = await loadQuoteFactory.getByNumberRated(quote.quoteNumber);
                await routeQuote(result);
            } catch (error) {
                setError(error);
            }
        }

        /**
         * @description
         * Show Error if we cannot get the quote, or route error if quote cannot be rated.
         */
        function setError(error) {
            if (error.event) {
                routeQuote(null, error);
            } else {
                utilService.showPopup('Error', error);
                vm.editQuoteNumber = null;
            }
        }

        init();
    }
})();
