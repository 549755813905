(function () {
    'use strict';

    angular
        .module('agentPortal')
        .factory('messages', messages);

    messages.$inject = ['globalSettingsService'];

    function messages(globalSettingsService) {
        var overrideMessages = {
            '2601': 'Your destination country is not eligible for coverage. Call us at ' + globalSettingsService.vacationRentalPhone() + ' for more details.'
        };

        var service = {
            overrideErrorMessage: overrideErrorMessage
        };
        return service;

        function overrideErrorMessage(errorMessage) {
            var msg = overrideMessages[errorMessage.code];
            // errorMessage.text is our normal error message, errorMessage.message is what comes back on the batch uploader
            return msg || errorMessage.text || errorMessage.message;
        }
    }

})();