(function () {
    'use strict';

    angular
      .module('app.components')
      .directive('premiumBreakdown', premiumBreakdown);

    premiumBreakdown.$inject = [];
    function premiumBreakdown() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/vacationguard/components/premiumbreakdown.html',
            scope: {
                packageName: '=bhPackageName',
                packageSubtitle: '=bhPackageSubtitle',
                docURI: '=bhDocUri',
                premium: '=bhPremium',
                baseQuoteAmount: '=bhBaseQuoteAmount',
                optionalAmount: '=bhOptionalAmount',
                fees: '=bhFees'
            },
            bindToController: true,
            controllerAs: 'premiumBreakdownCtrl',
            controller: PremiumBreakdownController
        };
        return directive;
    }

    PremiumBreakdownController.$inject = ['agents'];
    function PremiumBreakdownController(agents) {
        var premiumBreakdownCtrl = this;
    }
})();
