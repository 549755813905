(function () {
    var myAppModule = angular.module('agentPortal');

    /**
     * @ngdoc service
     * @name authService
     *
     * # authService
     *
     * @description
     * provides authentication, authorization and initial loading of reference data
     */
    myAppModule
        .factory('authService', ['$rootScope', '$q', '$resource', '$http', 'portalService', 'agentInformationService', 'intentService', 'storage', 'serviceSetup', 'globalSettingsService', 'agents', authService]);

    function authService($rootScope, $q, $resource, $http, portalService, agentInformationService, intentService, storage, serviceSetup, globalSettingsService, agents) {

        var loginApi = $resource(globalSettingsService.clientsApiBaseUrl() + '/v1/Auth/Login', {}, {
            post: { method: 'POST' }
        });
        var passwordApi = $resource(globalSettingsService.clientsApiBaseUrl() + '/v1/Auth/ChangePassword', {}, {
            post: { method: 'POST' }
        });

        /**
         * @description
         * right after authentication, this method performs authorization by downloading agent's profile and also calling function to load one-time initial reference data
         */
        function performAuthorizationAndLoad(successCallback, failureCallback, showlabels) {
            if (showlabels == null) {
                showlabels = true;
            }

            agentInformationService.loadAgentInformation(showlabels).then(function (data) {
                successCallback({ agent: data.agent, config: data.config, bhtp: data.bhtp });
            });
        }

        function failedAuthentication(failureCallback) {
            intentService.resetIntent();
            $rootScope.$broadcast('hideOverlay');
            failureCallback("Authentication Failed, Please check credentials and connectivity before trying again.");
        }

        function urlBase64Decode(str) {
            var output = str.replace('-', '+').replace('_', '/');
            switch (output.length % 4) {
                case 0: {
                    break;
                }
                case 2: {
                    output += '==';
                    break;
                }
                case 3: {
                    output += '=';
                    break;
                }
                default: {
                    throw 'Illegal base64url string!';
                }
            }
            return window.atob(output);  //polifyll https://github.com/davidchambers/Base64.js
        }

        return {

            /**
             * @description
             * Signs in using the BHTP API
             */
            signin: function (username, password, successCallback, failureCallback) {
                $rootScope.$broadcast('showOverlay');
                intentService.setIntent("Authenticating...");

                localStorage.removeItem('idToken');
                localStorage.removeItem('profile');

                var authData = {
                    userName: username,
                    password: password
                };

                var promise = loginApi.post(null, authData).$promise;
                var deferred = $q.defer();

                promise.then(function (authResponse) {
                    var authSuccess = false;
                    if (authResponse && authResponse.response && authResponse.response.token) {
                        var token = authResponse.response.token;
                        var splitToken = token.split('.');

                        if (splitToken.length === 3) {
                            var payload = JSON.parse(urlBase64Decode(splitToken[1]));

                            if (payload.AgentCode && payload.AgentCode.length > 0) {
                                serviceSetup.injectAuthToken(token);
                                localStorage.setItem('idToken', token);
                                localStorage.setItem('profile', JSON.stringify(payload));
                                agents.setCurrentAgentCode(payload.AgentCode);
                                $rootScope.$broadcast('hideOverlay');
                                // authentication with auth0.com through bhtp clients api is successful
                                // lets now get agent's profile and load initial reference data
                                authSuccess = true;
                                performAuthorizationAndLoad(successCallback, failureCallback, true);
                                deferred.resolve(); 
                            }
                        }
                    }

                    if (authSuccess !== true) {
                        failedAuthentication(failureCallback);
                        deferred.reject(); 
                    }
                },
                    function (error) {
                        failedAuthentication(failureCallback);
                        deferred.reject(); 
                    });

                return deferred.promise;
            },

            /**
             * @description
             * sends request to reset user's password to auth0.com, which in turn sends user the email for confirming the password-change
             */
            reset: function (username, successCallback, failureCallback) {
                $rootScope.$broadcast('showOverlay');

                var resetData = {
                    email: username
                };

                var promise = passwordApi.post(resetData).$promise;
                var deferred = $q.defer();


                promise.then(function (authResponse) {
                    if (authResponse && authResponse.messages && authResponse.messages.length > 0) {
                        failureCallback('Password reset failed. Please check email and connectivity before trying again.');
                        deferred.reject(); 
                    }
                    else {
                        successCallback();
                        deferred.resolve(); 
                    }
                },
                    function (error) {
                        $rootScope.$broadcast('hideOverlay');
                        failureCallback(error);
                        deferred.reject(); 
                    });

                return deferred.promise;
            },

            /**
             * @description
             * sends request to reset user's password to auth0.com, which in turn sends user the email for confirming the password-change
             */
            performReload: function (successCallback, failureCallback) {
                performAuthorizationAndLoad(successCallback, failureCallback, false);
            }
        };
    }
})();