(function() {
    'use strict';

    angular.module('agentPortal')
        .factory('lookupDataService', ['$resource', '$q', 'portalService', lookupDataService]);

    /**
    * @ngdoc controller
    * @name lookupDataService
    *
    * # lookupDataService
    *
    * @description
    * service to fetch lookup data from server
    */
    function lookupDataService($resource, $q, portalService) {

        /**
         * @description
         * public functions exposed by this service
         */
        return {
            getProviderInfo: getProviderInfo,
            parseRules: parseRules
        };

        /**
         * @description
         * gets the provider information for the specified provider type and search text.
         */
        function getProviderInfo(providerUrl, providerText) {
            if (!providerText || providerText == '')
                return $q.defer().resolve(null);
            return $resource(providerUrl, { query: providerText.replace('&', '') }).query().$promise;
        }

        /**
      * @description
     * parses the rules from the response and puts them in an easily consumable format for angular
     */
        function parseRules(rawRules) {
            var rules = {
                policy: {
                    req: {
                    }, edit: {}
                },
                traveler: {
                    req: {}, edit: {},
                    primary: {
                        req: {}, edit: {},
                    }
                }
            };

            if (rawRules.required) {
                if (rawRules.required.policy__c && rawRules.required.policy__c.selected) {
                    for (var key in rawRules.required.policy__c.selected) {
                        var apiName = makeFirstLetterLowerCase(rawRules.required.policy__c.selected[key].apiName);
                        if (apiName !== undefined) {
                            rules.policy.req[apiName] = true;
                        }
                    }
                }
                if (rawRules.required.traveler__c && rawRules.required.traveler__c.selected) {
                    for (var key in rawRules.required.traveler__c.selected) {
                        var apiName = makeFirstLetterLowerCase(rawRules.required.traveler__c.selected[key].apiName);
                        if (apiName !== undefined) {
                            rules.traveler.req[apiName] = true;
                        }
                    }
                }
                if (rawRules.required.account && rawRules.required.account.selected) {
                    for (var key in rawRules.required.account.selected) {
                        var apiName = makeFirstLetterLowerCase(rawRules.required.account.selected[key].apiName);
                        if (apiName !== undefined) {
                            rules.traveler.primary.req[apiName] = true;
                        }
                    }
                }
            }
            if (rawRules.editable) {
                if (rawRules.editable.policy__c && rawRules.editable.policy__c.nonSelected) {
                    for (var key in rawRules.editable.policy__c.nonSelected) {
                        var apiName = makeFirstLetterLowerCase(rawRules.editable.policy__c.nonSelected[key].apiName);
                        if (apiName !== undefined) {
                            rules.policy.edit[apiName] = false;
                        }
                    }
                }

                if (rawRules.editable.traveler__c) {
                    if (rawRules.editable.traveler__c.nonSelected) {
                        for (var key in rawRules.editable.traveler__c.nonSelected) {
                            var apiName = makeFirstLetterLowerCase(rawRules.editable.traveler__c.nonSelected[key].apiName);
                            if (apiName !== undefined) {
                                rules.traveler.edit[apiName] = false;
                            }
                        }
                    }
                    // If travelers cannot be both added and removed, Any traveler fields that 
                    // would normally be allowed to be editted are disabled
                    if ((rules.canAddTravelers == false || rules.canRemoveTravelers == false) && rawRules.editable.traveler__c.selected) {
                        for (var key in rawRules.editable.traveler__c.selected) {
                            var apiName = makeFirstLetterLowerCase(rawRules.editable.traveler__c.selected[key].apiName);
                            if (apiName !== undefined) {
                                rules.traveler.edit[apiName] = false;
                            }
                        }
                    }
                }

                if (rawRules.editable.account && rawRules.editable.account.nonSelected) {
                    for (var key in rawRules.editable.account.nonSelected) {
                        var apiName = makeFirstLetterLowerCase(rawRules.editable.account.nonSelected[key].apiName);
                        if (apiName !== undefined) {
                            rules.traveler.primary.edit[apiName] = false;
                        }
                    }
                }
            }

            return rules;
        }

        /**
        * @description
        * makes the first character of a string lower case
        */
        function makeFirstLetterLowerCase(rawText) {
            var firstLetter = rawText.substring(0, 1).toLowerCase();
            return firstLetter + rawText.substring(1);
        }

    }
})();