(function () {
    'use strict';

    angular
        .module('components.claims')
        .service('fnol', Fnol);

    Fnol.$inject = ['moment', 'Rx', 'dataservice', 'claim', 'policySearch'];

    function Fnol(moment, Rx, dataservice, claim, policySearch) {
        this.dataservice = dataservice;
        this.moment = moment;
        this.claim = claim;
        this.policySearch = policySearch;

        this.fnol = null;
        this.fnolSubject = new Rx.ReplaySubject();
        this.fnolSubject.onNext(this.fnol);
    }

    Fnol.prototype.submit = function submitFnol(fnol) {
        var self = this;

        // Assign dateTimeOfLoss based on dateOfLoss, format it for Salesforce
        if (fnol.dateOfLoss) {
            fnol.dateTimeOfLoss = this.moment(new Date(fnol.dateOfLoss)).format('YYYY-MM-DD');
        }

        return self.dataservice.createSimpleClaim(fnol)
            .then(function handleSuccess(data) {
                self.claim.set(data);
            });
    };

    Fnol.prototype.set = function setFnol(fnol) {
        this.fnol = fnol;
        this.fnolSubject.onNext(this.fnol);
    };

    Fnol.prototype.reset = function resetFnol(search) {
        this.fnol = {};

        if (!search) {
            search = this.policySearch.get();
        }

        if (search) {
            this.fnol.policyNumber = search.policyNumber;
            this.fnol.postalCode = search.postalCode;
            this.fnol.emailAddress = search.emailAddress;
        }

        this.fnolSubject.onNext(this.fnol);
    };

})();
