(function () {
    'use strict';

    /**
     * @ngdoc factory
     * @name quotesService
     *
     * # quotesService
     *
     * @description
     * backend API integration for quotes related requirements
     */

    angular.module('agentPortal')
        .factory('quotesService', quotesService);

    quotesService.$inject = ['$rootScope', '$resource', '$q', '$state', 'settings', 'customersService', 'lookupDataService', 'utilService', '$uibModal', '$sessionStorage', 'portalService', 'apiUtilService', 'globalSettingsService'];



    function quotesService($rootScope, $resource, $q, $state, settings, customersService, lookupDataService, utilService, $modal, $sessionStorage, portalService, apiUtilService, globalSettingsService) {

        const defaultPageSize = 10;
        // agents/Quotes
        var quickQuoteEmailUrl = globalSettingsService.clientsApiBaseUrl() + '/v1/Agents/EmailQuickQuote';
        var deleteQuotesUrl = globalSettingsService.agentsGatewayBaseUrl() + '/quote/remove';
        var quoteByNumberUrl = globalSettingsService.agentsGatewayBaseUrl() + '/quote';
        var quoteByNumberRatedUrl = globalSettingsService.agentsGatewayBaseUrl() + '/quote/rated';
        var emailQuotesUrl = globalSettingsService.apiBaseUrl() + '/v1/agents/:agentCode/quote/email';
        var queryQuotesPagedUrl = globalSettingsService.agentsGatewayBaseUrl() + '/quote/list';

        return {
            getDateFilters: getDateFilters,
            getProducts: getProducts,
            removeQuotes: deleteQuotes,
            emailQuote: emailQuote,
            getByNumber: getByNumber,
            getByNumberRated: getByNumberRated,
            emailQuickQuote: emailQuickQuote,
            refreshQuoteSummaries: refreshQuoteSummaries,
            getQuotePackageRatingId: getQuotePackageRatingId
        };

        /**
         * @description
         * deletes selected quotes indicated by given quoteIds
         */
        function deleteQuotes(quoteIds) {
            return portalService.getAgentByInternalId()
                    .then(function (agent) {
                        var deleteQuotesApi = $resource(deleteQuotesUrl, { },
                                                { deleteQuotes: { method: 'POST' } });
                return deleteQuotesApi.deleteQuotes(quoteIds).$promise;
            });
        }

        /**
         * @description
         * sends quote details via email
         */
        function emailQuote(quoteNumber, emailAddresses, comment) {
            var data = {
                cCEmails: emailAddresses,
                comment: comment,
                quoteNumber: quoteNumber
            };

            var deferred = $q.defer();
            portalService.getAgentByInternalId()
                .then(function (agent) {
                    var emailQuotesApi = $resource(
                        emailQuotesUrl,
                        {
                            agentCode: agent.agentCode
                        },
                        {
                            email: { method: 'POST' }
                        });

                    emailQuotesApi.email(data)
                        .$promise
                        .then(function (emailQuoteResponse) {
                                if (!emailQuoteResponse) {
                                    deferred.reject('An error occurred while attempting to email the quote.');
                                }
                                else if (apiUtilService.areThereApiErrorMessages(emailQuoteResponse.messages)) {
                                    deferred.reject(emailQuoteResponse.messages[0]);
                                }
                                else {
                                    deferred.resolve(emailQuoteResponse.response);
                                }
                            },
                            function (error) {
                                deferred.reject(error);
                            });
                        },
                        function (error) {
                            deferred.reject(error);
                        });

            return deferred.promise;
        }

        /**
         * @description
         * grid implementation - date filters
         */
        function getDateFilters() {
            return [
                { interval: 'All', name: 'All' },
                { interval: '0', name: 'Today' },
                { interval: '1', name: 'This Week' },
                { interval: '2', name: 'This Month' },
                { interval: '3', name: 'Past Month' },
                { interval: '4', name: 'YTD' }
            ];
        }

        /**
         * @description
         * grid implementation - products for filtering - hard-coded
         */
        function getProducts() {
            return portalService.getPackages().then(function (packages) {
                var respPackages = packages;
                var packages = [];

                packages.push({ value: '', name: 'All Products' });

                for (var i = 0; i < respPackages.length; i++) {
                    var label = respPackages[i].name;
                    if (respPackages[i].subTitle) {
                        label += " " + respPackages[i].subTitle;
                    }
                    packages.push({
                        value: respPackages[i].id,
                        id: respPackages[i].id,
                        name: label,
                        ratingId: respPackages[i].ratingId,
                        subTitle: respPackages[i].subTitle
                    });
                }
                return packages;
            });
        }

    function loadQuoteSummaries(selectedAgentCode, offset, pageSize, searchTerm, orderBy, sortOrder, dateRangeSelected, planIds, selectedCustomerId, showExpired = false, expiredFilter) {
        var deferred = $q.defer();

        portalService.getAgentByInternalId().then(function (agent) {

            let parameters = processParameters(selectedAgentCode, offset, pageSize, agent, searchTerm, orderBy, sortOrder, dateRangeSelected, planIds, selectedCustomerId, showExpired, expiredFilter);

            $resource(queryQuotesPagedUrl, parameters).get()
                .$promise
                .then(function onSuccess(result) {
                    if (!result || !result.quotes) {
                        deferred.reject('An error occurred while attempting to load quotes.');
                    }
                    else {
                        deferred.resolve(result);
                    }
                }, function onError(error) {
                    deferred.reject(error)
                });
        });

        return deferred.promise;
    }

    /**
     * @description
     * Check to see which parameters were provided and packages them up for the query string
     */
    function processParameters(selectedAgentCode, offset, pageSize, agent, searchTerm, orderBy, sortOrder, dateRangeSelected, planIds, selectedCustomerId, showExpired, expiredFilter) {
        let parameters = {
            offset: offset,
            pageSize: pageSize,
            agencyCode: agent.agencyCode,
        };

        if (selectedAgentCode) {
            parameters.agentCode = selectedAgentCode;
        }

        if (searchTerm) {
            parameters.searchTerm = searchTerm;
        }

        if (orderBy) {
            parameters.orderBy = orderBy;
        }

        if (sortOrder) {
            parameters.sortOrder = sortOrder;
        }

        if (dateRangeSelected && dateRangeSelected.startDate) {
            parameters.startDate = moment(dateRangeSelected.startDate).format('YYYY-MM-DD');
        }

        if (dateRangeSelected && dateRangeSelected.endDate) {
            parameters.endDate = moment(dateRangeSelected.endDate).format('YYYY-MM-DD');;
        }

        if (planIds) {
            parameters.planIds = planIds;
        }

        if (selectedCustomerId) {
            // D2C TODO link customer with quotes
            alert(selectedCustomerId);
        }

        if (expiredFilter) {
            parameters.expiredFilter = expiredFilter;
        }

        parameters.includeExpired = showExpired;


        return parameters;
    }

    /**
     * @description
     * retrieves refreshed data for the qoute grid views on the dashboard, customer, and quote tabs, given agent or customer (i.e., logged in user)
     */
    function refreshQuoteSummaries(selectedAgentCode, packageIdsCsv, pageNumber, orderBy, direction, dateRangeSelected, customerId, searchText, expiredReason) {
        const offset = calculateOffset(pageNumber);

        // convert package ids from csv to array.
        var packageIds = packageIdsCsv ? packageIdsCsv.split(',') || [] : [];

        var expiredFilter = undefined;
        var showExpired;

        // Setup expired reason and flag
        if(expiredReason) {
            if (expiredReason === 'none') {
                showExpired = false;
            } else {
                showExpired = true;
                if (expiredReason !== 'all') {
                    expiredFilter = expiredReason;
                }
            }
        }

        return loadQuoteSummaries(selectedAgentCode, offset, defaultPageSize, searchText, orderBy, direction, dateRangeSelected, packageIdsCsv, customerId, showExpired, expiredFilter)
    }

    /**
     * @description
     * Calculates the offset to be sent in with the summaries request.
     */
    function calculateOffset(pageNumber) {
        let offset = 0;

        if(pageNumber > 1){
            offset = (pageNumber - 1) * defaultPageSize
        }

        return offset;
    }

    /**
    * @description
    * retrieves saved quote by given quote Number and runs rating, returns in form of promise
    */
    async function getByNumberRated(quoteNumber, freshRating = false) {
        var deferred = $q.defer();
        const packages = await portalService.getPackages();
        $resource(quoteByNumberRatedUrl, { quoteId: quoteNumber })
            .get()
            .$promise
            .then(function (quoteDetailResponse) {
                // Could not get the quote from the api.
                if (!quoteDetailResponse || !quoteDetailResponse.event) {
                    deferred.reject('An error occurred while attempting to load the quote.');
                }
                // We got a response and there is enough info to check the package
                if (quoteDetailResponse.event.quotes && quoteDetailResponse.event.quotes[0] && quoteDetailResponse.event.quotes[0].planId) {
                    const planId = quoteDetailResponse.event.quotes[0].planId;
                    quoteDetailResponse.package = packages.find(p => p.ratingId === planId);
                    quoteDetailResponse.ratingId = planId;
                    // If we did not find the package inform the user
                    if (!quoteDetailResponse.package || quoteDetailResponse.package.active === false) {
                        deferred.reject('The selected quote references an insurance product that is no longer available.\nPlease start a new quote.');
                    // If we found the package and it is Vacation Guard inform the user
                    } else if (quoteDetailResponse.partnerPackage) {
                        deferred.reject('The selected quote references a Vacation Guard insurance product.\nPlease start a new quote.');
                    }
                }

                // We got back a quote,  but the rating failed for some reason.
                if (apiUtilService.areThereApiErrorMessages(quoteDetailResponse.messages)) {
                    deferred.reject(quoteDetailResponse);
                // We got a quote back from the api and rating was successful
                } else {
                    deferred.resolve(quoteDetailResponse);
                }
            },
            // Error contacting the service
            function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    /**
    * @description
    * retrieves quote by given quote Number, returns in form of promise
    */
    function getByNumber(quoteNumber) {
        var deferred = $q.defer();
        portalService.getAgentByInternalId()
            .then(function (agent) {
                $resource(quoteByNumberUrl, { quoteId: quoteNumber })
                    .get()
                    .$promise
                    .then(function (quoteDetailResponse) {
                            // Could not get the quote from the api
                            if (!quoteDetailResponse && quoteDetailResponse.event) {
                                deferred.reject('An error occurred while attempting to load the quote.');
                            // We got a quote back from the api but it is expired
                            } else if (quoteDetailResponse.event.expirationMessage || quoteDetailResponse.event.expirationDate) {
                                deferred.reject('The selected quote expired on ' + quoteDetailResponse.event.expirationDate +
                                                '\n' + quoteDetailResponse.event.expirationMessage + '\nPlease start a new quote.');
                            } else {
                                // We got a quote back from the api, the rated quote may have messages
                                deferred.resolve(quoteDetailResponse.event);
                            }
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
        });

        return deferred.promise;
    }

    function getQuotePackageRatingId(quote) {
        if (!quote || !quote.selectedPackages || quote.selectedPackages.length < 1 || !quote.selectedPackages[0] || !quote.selectedPackages[0].package) {
            return null;
        }

        return quote.selectedPackages[0].package.ratingId;
    }

    function emailQuickQuote(quickQuoteRequest) {
        var emailQuoteApi = $resource(quickQuoteEmailUrl, { },
                                                { emailQuote: { method: 'POST', isArray: true } });
        return emailQuoteApi.emailQuote(quickQuoteRequest).$promise;
    }
}
})();
