(function () {
    'use strict';

    angular
        .module('components.claims')
        .service('claim', Claim);

    Claim.$inject = ['moment', 'Rx', 'dataservice'];

    function Claim(moment, Rx, dataservice) {
        this.claim = null;
        this.claimSubject = new Rx.ReplaySubject();
        this.claimSubject.onNext(this.claim);
    }

    Claim.prototype.reset = function resetClaim() {
        this.claim = null;
        this.claimSubject.onNext(this.claim);
    };

    Claim.prototype.set = function setClaim(claim) {
        this.claim = claim;
        this.claimSubject.onNext(this.claim);
    };

})();
