(function () {
    'use strict';

    /**
     * @ngdoc config
     * @name $stateProvider
     *
     * # $stateProvider
     *
     * @description
     * routes configuration across the board
     */

    angular.module('agentPortal')
        .config(['$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider', '$locationProvider', routeConfig])
        .config(['$provide', function ($provide) {
            if (window.sessionStorage.DisableHistory) {
                $provide.decorator('$sniffer', ['$delegate', function ($delegate) {
                    $delegate.history = false;
                    return $delegate;
                }])
            }
        }]);

    /*
     * Optional Route Configs:
     *
     * persistState: whether or not to allow the state (i.e. WI) should be kept in memory on that page.
     * preventBack: prevents a user from navigating backwards to the previous page, such as from a receipt page.
     * requiresLogin: requires the app user to be authenticated
     */

    function routeConfig($stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider, $locationProvider, $injector) {

        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get('$state');
            var authenticationNavigationService = $injector.get('authenticationNavigationService');
            authenticationNavigationService.checkToken().then(function (data) {
                if (data) {
                    $state.go('dashboard');
                }
            });
        });
        $urlMatcherFactoryProvider.strictMode(false);
        $locationProvider.html5Mode(true);

        $stateProvider
            .state('dashboard',
            {
                url: '/dashboard',
                templateUrl: 'app/dashboard/dashboard.html',
                data: {
                    requiresLogin: true
                },
            })
            .state('purchase',
            {
                url: '/purchase',
                templateUrl: 'app/purchase/purchase.html',
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('purchasePackage',
            {
                url: '/purchase/package/:ratingId',
                templateUrl: 'app/purchase/purchase.html',
                params: {
                    'sessionId': { value: null }
                },
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('purchaseCustomer',
            {
                url: '/purchase/customer/:customerId/:page?',
                templateUrl: 'app/purchase/purchase.html',
                params: {
                    'sessionId': { value: null }
                },
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('purchasePackageQuote',
            {
                url: '/purchase/package/:ratingId/quote/:quoteId',
                templateUrl: 'app/purchase/purchase.html',
                params: {
                    'sessionId': { value: null }
                },
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('purchasePackageQuotePaged',
            {
                url: '/purchase/package/:ratingId/quote/:quoteId/:page?',
                templateUrl: 'app/purchase/purchase.html',
                params: {
                    'sessionId': { value: null }
                },
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('purchasePackageCustomer',
            {
                url: '/purchase/package/:ratingId/customer/:customerId',
                templateUrl: 'app/purchase/purchase.html',
                params: {
                    'sessionId': { value: null }
                },
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('purchaseBHTP',
            {
                url: '/purchase/ratingId/:ratingId?customerId&quoteId&page',
                templateUrl: 'app/purchase/purchasePath.html',
                params: {
                    'sessionId': { value: null },
                    'loadedQuote': {value: false }
                },
                reloadOnSearch: false,
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('receiptBHTP',
            {
                url: '/receipt/:policyNumber',
                templateUrl: 'app/purchase/receipt.html',
                data: {
                    requiresLogin: true,
                    persistState: true,
                    preventBack: true
                }
            })
            .state('quotes',
            {
                url: '/quotes',
                templateUrl: 'app/quotes/quotes.html',
                controller: 'quotesController',
                data: {
                    requiresLogin: true,
                }
            })
            .state('quotesView',
            {
                url: '/quotes/view/:quoteNumber',
                templateUrl: 'app/quotes/quote.detail.html',
                data: {
                    requiresLogin: true,
                }
            })
            .state('products',
            {
                url: '/products',
                templateUrl: 'app/products/productsV2.html',
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('tramsproducts',
            {
                url: '/products',
                templateUrl: 'app/products/productsV2.html',
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('productsCustomerPage',
            {
                url: '/products/customer/:customerId/:page?',
                templateUrl: 'app/products/productsV2.html',
                data: {
                    requiresLogin: true,
                    persistState: true
                }
            })
            .state('customers',
            {
                url: '/customers',
                templateUrl: 'app/customers/customers.html',
                controller: 'customersController',
                data: {
                    requiresLogin: true
                }
            })
            .state('customersEdit',
            {
                url: '/customers/edit/:customerId',
                templateUrl: 'app/customers/customer.detail.html',
                requiresLogin: true,
                data: {
                    requiresLogin: true
                }
            })
            .state('policiesFilter',
            {
                url: '/policies/:filter?',
                templateUrl: 'app/policies/policies.html',
                data: {
                    requiresLogin: true
                }
            })
            .state('policies',
            {
                url: '/policies',
                templateUrl: 'app/policies/policies.html',
                data: {
                    requiresLogin: true
                }
            })
            .state('policiesEdit',
            {
                url: '/policies/edit/:policyNumber',
                templateUrl: 'app/policyedit/policyedit.html',
                data: {
                    requiresLogin: true,
                }
            })
            .state('policiesEditStep',
            {
                url: '/policies/edit/step/:step?',
                templateUrl: 'app/policyedit/policyedit.html',
                data: {
                    requiresLogin: true,
                }
            })
            .state('policiesEditReceipt',
            {
                url: '/policies/edit/receipt/:policyNumber',
                templateUrl: 'app/receipt/receipt.html',
                controller: 'policyEditReceiptController',
                controllerAs: 'vm',
                data: {
                    requiresLogin: true,
                    preventBack: true
                }
            })
            .state('policiesView',
            {
                url: '/policies/view/:policyNumber',
                templateUrl: 'app/policies/policy.detail.html',
                data: {
                    requiresLogin: true
                }
            })
            .state('login',
            {
                url: '/login',
                templateUrl: 'app/auth/login.html',
                data: {
                }
            })
            .state('loginForgot',
            {
                url: '/login/forgot',
                templateUrl: 'app/auth/forgot.html',
            })
            .state('logout',
            {
                url: '/logout',
                templateUrl: 'app/auth/login.html',
                controller: 'logoutController',
                data: {
                }
            })
            .state('profile',
            {
                url: '/profile',
                templateUrl: 'app/agent/profile.html',
                data: {
                    requiresLogin: true
                }
            })
            .state('contact',
            {
                url: '/contact',
                templateUrl: 'app/contact/contact.html',
                data: {
                    requiresLogin: true
                }
            })
            .state('adminBatchUpload',
            {
                url: '/admin/batchUpload',
                templateUrl: 'app/admin/batchUpload/batchUpload.html',
                data: {
                    requiresLogin: true,
                },
                controller: 'batchUploadController',
                controllerAs: 'vm'
            })
            .state('admin',
            {
                url: '/admin',
                templateUrl: 'app/admin/admin.html',
                data: {
                    requiresLogin: true
                },
                controller: 'admin',
                controllerAs: 'vm'
            })
            .state('partnerProducts',
            {
                url: '/partnerProducts',
                templateUrl: 'app/products/partnerProducts.html',
                data: {
                    requiresLogin: true
                }
            })
            .state('quickquoteCustomerAmbassador',
            {
                url: '/quickquote/customers/:customerId/agent/?',
                templateUrl: 'app/quickquote/quickQuote.html',
                data: {
                    requiresLogin: true
                },
                controller: 'quickQuotesController',
                controllerAs: 'vm'
            })
            .state('quickquoteCustomerPage',
            {
                url: '/quickquote/customer/:customerId/:page',
                templateUrl: 'app/quickquote/quickQuote.html',
                data: {
                    requiresLogin: true,
                    persistState: true
                },
                controller: 'quickQuotesController',
                controllerAs: 'vm'
            })
            .state('quickquoteCustomer',
            {
                url: '/quickquote/customer/:customerId',
                templateUrl: 'app/quickquote/quickQuote.html',
                data: {
                    requiresLogin: true,
                    persistState: true
                },
                controller: 'quickQuotesController',
                controllerAs: 'vm'
            })
            .state('quickquote',
            {
                url: '/quickquote?customerId&agentCode&requestId&action',
                templateUrl: 'app/quickquote/quickQuote.html',
                data: {
                    requiresLogin: true
                },
                controller: 'quickQuotesController',
                controllerAs: 'vm'
            })
            .state('fnolSummary',
            {
                url: '/fnol/summary',
                templateUrl: 'app/fnol/fnol.summary.html',
                data: {
                    requiresLogin: true
                },
                controller: 'fnolSummaryController',
                controllerAs: 'vm'
            })
            // Someday we should include an action, edit, purchase, etc as a parameter.  In this way
            // Sales Force will only ever need to know about on deep link url and the agent portal can direct from there
            .state('ambassadorlaunchpad',
                {
                    url: '/ambassador?customerId&agentCode&requestId&action',
                    templateUrl: 'app/shared/ambassadorLaunchPad.html',
                    data: {
                        requiresLogin: true,
                        persistState: true,
                        preventBack: true
                    },
                    controller: 'ambassadorLaunchpadController',
                    controllerAs: 'vm'
                });
    }
})();
