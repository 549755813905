(function () {

    angular
        .module('app.quotes')
        .directive('paymentSelection', paymentSelection)
        .controller('TermsModalController', TermsModalController);

    function paymentSelection() {
        var directive = {
            restrict: 'EA',
            scope: {
                quote: '=',
                currentQuoteResponse: '=',
                currentPackage: '='
            },
            templateUrl: 'app/vacationguard/quotes/purchase/paymentSelection.html',
            replace: true,
            bindToController: true,
            controllerAs: 'vm',
            controller: PaymentSelectionController
        };
        return directive;
    }

    PaymentSelectionController.$inject = ['dataservice', 'agents', '$uibModal', 'quotes', '$state', 'coverageservice', '$q'];

    function PaymentSelectionController(dataservice, agents, $modal, quotes, $state, coverageservice, $q) {
        var vm = this;
        vm.quoting = false;

        vm.submitForm = submitForm;

        if (vm.quote && !vm.quote.paymentInformation) {
            vm.quote.paymentInformation = {};
        }

        function purchaseQuote() {
            // get quotes
            vm.quoting = true;
            vm.errors = [];

            if (vm.currentPackage.partnerPackage !== true) {
                var coverages = [];
                if (vm.currentQuoteResponse) {
                    coverages = vm.currentQuoteResponse.coverages.optional;
                }

                // copy the BHTP primary traveler into the policy buyer node
                if (vm.quote.isPolicyBuyer) {
                    vm.quote.policyBuyer = vm.quote.primaryTraveler;
                }

                vm.quote = quotes.buildBHTPQuote(vm.quote, coverages, false);
            }
            else {
                // copy the VG quote traveler into the policy buyer node
                if (vm.quote.isPolicyBuyer) {
                    vm.quote.policyBuyer = {
                        firstName: vm.quote.firstName,
                        lastName: vm.quote.lastName,
                        email: vm.quote.email,
                        phone: vm.quote.phone,
                        address: {
                            address1: vm.quote.address1,
                            address2: vm.quote.address2,
                            city: vm.quote.city,
                            stateOrProvince: vm.quote.state,
                            postalCode: vm.quote.postalCode
                        }
                    };
                }
            }

            showCardForm();
        }

        function showCardForm() {
            $state.go('quoteBHTP.payment');
        }

        function openTermsModal() {
            var modalInstance = $modal.open({
                templateUrl: 'app/vacationguard/quotes/purchase/termsModal.html',
                controller: 'TermsModalController',
                controllerAs: 'vm'
            });

            return modalInstance.result;
        }

        function submitForm(formValid) {
            if (formValid === true) {
                openTermsModal()
                .then(function (success) {
                    purchaseQuote();
                });
            }
        }
    }

    TermsModalController.$inject = ['$uibModalInstance', 'moment'];

    function TermsModalController($modalInstance, moment) {

        var vm = this;
        vm.closeModal = closeModal;
        vm.agree = agree;

        function agree() {
            $modalInstance.close(true);
        }

        function closeModal() {
            $modalInstance.dismiss('close');
        }
    }

})();
