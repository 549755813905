(function () {
    'use strict';

    angular
      .module('app.core')
      .service('policy', Policy);

    Policy.$inject = ['Rx', 'dataservice'];
    function Policy(Rx, dataservice) {
        this.dataservice = dataservice;

        this.policy = null;
        this.policySubject = new Rx.ReplaySubject();
        this.policySubject.onNext(this.policy);
    }

    Policy.prototype.searchPolicy = function searchPolicy(policyNumber, postalCode, emailAddress) {
        var self = this;

        return self.dataservice.searchPolicy(policyNumber, postalCode, emailAddress)
            .then(function handleSuccess(policy) {
                policy.actions = parsePolicyActions(policy);

                self.policy = policy;
                self.policySubject.onNext(self.policy);
            });

        function parsePolicyActions(policy) {
            return {
                canBeRefunded: policy.policy.canBeRefunded,
                canBeEdited: policy.policy.canBeEdited,
                canBeCancelled: policy.policy.canBeCancelled,
                canClaim: policy.policy.canClaim
            };
        }
    };

})();
