(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name upcomingPoliciesController
     *
     * # upcomingPoliciesController
     *
     * @description
     * controller to coordinate action for upcoming policies on the dashboard page
     */
    angular.module('agentPortal')
        .controller('upcomingPoliciesController', ['$q', 'policiesService', '$rootScope', 'settings', 'portalService', upcomingPoliciesController]);

    function upcomingPoliciesController($q, policiesService, $rootScope, settings, portalService) {
        var vm = this;

        vm.title = 'Upcoming Policies';
        vm.reloadDataFlag = false;
        vm.refreshDataFlag = false;
        vm.ready = false;

        /**
         * @description
         * reloads upcoming policies from the server
         */
        vm.reloadData = function () {
            vm.reloadDataFlag = true;
        };
        /**
         * @description
         * refresh upcoming policies from the server
         */
        vm.refreshData = function () {
            vm.refreshDataFlag = true;
        };

        function init() {
            portalService.getAgentByInternalId().then(function (agent) {
                vm.agent = agent;
                if (vm.agent.isSuperUser) {
                    vm.gridConfig = {
                        noDataMessage: "No policies found",
                        allowMultiSelect: false,
                        hasActions: true,
                        columns: [
                            { header: "Policy Id", binding: "policyNumber", href: 'policies/view/{{row.policyNumber}}', preferredWidth: '10%' },
                            { header: "Primary Traveler", binding: "primaryTravelerName", preferredWidth: '20%' },
                            { header: "Purchase Date", binding: "purchaseDateForDisplay", preferredWidth: '1%' },
                            { header: "Product", binding: "productNameWithSubtitle", preferredWidth: '20%' },
                            { header: "Departure Date", binding: "departureDateForDisplay", preferredWidth: '1%' },
                            { header: "Submission Channel", binding: "submissionChannel", preferredWidth: '1%' },
                            { header: "Agent Name", binding: "agentName", preferredWidth: '10%' },
                            { header: "Status", binding: "status", preferredWidth: '5%' }
                        ],
                        actionList: [
                            { label: "View", icon: "fa fa-eye", href: 'policies/view/{{row.policyNumber}}' }
                        ],
                        defaultOrderBy: "departureDateIso",
                        defaultOrder: false,
                        rowIdentifier: "policyNumber",
                    };
                }
                else {
                    vm.gridConfig = {
                        noDataMessage: "No policies found",
                        allowMultiSelect: false,
                        hasActions: true,
                        columns: [
                            { header: "Policy Id", binding: "policyNumber", href: 'policies/view/{{row.policyNumber}}', preferredWidth: '10%' },
                            { header: "Primary Traveler", binding: "primaryTravelerName", preferredWidth: '20%' },
                            { header: "Purchase Date", binding: "purchaseDateForDisplay", preferredWidth: '1%' },
                            { header: "Product", binding: "productNameWithSubtitle", preferredWidth: '20%' },
                            { header: "Departure Date", binding: "departureDateForDisplay", preferredWidth: '1%' },
                            { header: "Submission Channel", binding: "submissionChannel", preferredWidth: '1%' },
                            { header: "Status", binding: "status", preferredWidth: '5%' }
                        ],
                        actionList: [
                            { label: "View", icon: "fa fa-eye", href: 'policies/view/{{row.policyNumber}}' }
                        ],
                        defaultOrderBy: "departureDateIso",
                        defaultOrder: false,
                        rowIdentifier: "policyNumber",
                    };
                }

                vm.ready = true;
            })
        }

        /**
         * @description
         * loads active policies from the server
         */
        vm.loadData = function () {
            // leave it blank to return all agent policies, set it to only return a specific agent's policies.
            var agentCode = vm.agent.isSuperUser ? '' : vm.agent.agentCode;

            var dateSelected = {
                startDate: null,
                endDate: null
            };

            var deferredPromise = $q.defer();
            policiesService.loadPolicySummaries(agentCode, 'Active', "", vm.gridConfig.currentPage,
                vm.gridConfig.orderby, vm.gridConfig.reverse ? 'desc' : 'asc', dateSelected,
                "", ""
            ).then(function (results) {
                vm.gridConfig.totalRecords = results.totalRecords || 0;

                if (results.summaries) {
                    for (var i = 0; i < results.summaries.length; i++) {
                        var policy = results.summaries[i];
                        policiesService.setPolicyActions(policy, vm.cancelPolicy);
                    }

                    deferredPromise.resolve(results.summaries);
                }
            }, function (error) {
                deferredPromise.reject(error);
            });

            return deferredPromise.promise;
        };

        /**
         * @description
         * grid implementation - cancel Policy
         */
        vm.cancelPolicy = function (policyDetails) {
            var policy = policyDetails.policyNumber;
            policiesService.confirmCancelPolicy(
                policyDetails,
                function (result) {
                    vm.refreshData();
                    vm.reloadData();
                }
            );
        };

        init();
    }
})();