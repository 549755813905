(function () {
    'use strict';
    angular
        .module('app.components')
        .directive('postalCodeForm', postalCodeForm);
    function postalCodeForm() {
        return {
            restrict: 'EA',
            templateUrl: 'app/directives/postalCodeForm/postalCodeForm.html',
            scope: {
                //only the model, must contain the three pieces this component controls: postalCode, city, stateOrProvince
                address: '=', 
                //optional, allows parent to handle change events
                notifyEventPostalChanged: '&postalChanged', 
                 //bound to the formSubmitted property of the root form
                formSubmitted: "=",
                //since we are calling $setValidity, we need a reference to the component of this controller
                thisComponent: "=" 
            },
            controller: postalCodeFormCtrl,
            controllerAs: 'vm',
            bindToController: true
        };
    }
    postalCodeFormCtrl.$inject = ['dataservice'];
    function postalCodeFormCtrl(dataservice) {
        // Tolerate using 'this' in a non-constructor 
        /* jshint validthis: true */
        var vm = this;
        vm.loading = false;
        vm.postalChanged = function postalChanged() {
            if (vm.address && vm.address.postalCode) {
                vm.thisComponent.postalCode.$setValidity('recognized', true);
                retrieveCityInfo(function (result) {
                    if (result && result.results && result.results.length > 0) {
                        var respCity = result.results[0];
                        vm.address.city = respCity.city;
                        vm.address.stateOrProvince = respCity.stateOrProvinceIso2;
                        //if postal changed function was defined
                        if(vm.notifyEventPostalChanged)
                        {
                            vm.notifyEventPostalChanged();
                        }
                    } else {
                        vm.thisComponent.postalCode.$setValidity('recognized', false);
                        clearCityAndState();
                    }                        
                }, function (err) {
                    clearCityAndState();
                });
            }
        };
        function isPostalCodeValid(code) {
            return /^\d{5}$/.test(code);
        }
        function clearCityAndState() {
            vm.address.city = null;
            vm.address.stateOrProvince = null;
        }
        function retrieveCityInfo(success, error) {
            if (isPostalCodeValid(vm.address.postalCode)) {
                vm.thisComponent.postalCode.$setDirty();
                vm.thisComponent.postalCode.$setValidity('validPostalCode', true);
                vm.loading = true;
                dataservice.retrieveCityInfo(vm.address.postalCode, 'US', function (result) {
                    vm.loading = false;
                    if (success) {
                        success(result);
                    }
                }, function (err) {
                    vm.loading = false;
                    vm.thisComponent.postalCode.$setValidity('recognized', false);
                    if (error) {
                        error(err);
                    }
                });
            } else {
                vm.thisComponent.postalCode.$setValidity('validPostalCode', false);
            }
        }
    }
})();