(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name manageAgentsController
     *
     * # manageAgentsController
     *
     * @description
     * controller for agents listing on manage agents page
     */
    angular.module('agentPortal')
        .controller('manageAgentsController', ['$q', '$filter', '$stateParams', '$timeout', 'agentService', 'settings', 'portalService', 'storage', '$state', manageAgentsController]);

    function manageAgentsController($q, $filter, $stateParams, $timeout, agentService, settings, portalService, storage, $state) {
        var vm = this;
        vm.title = 'Manage Agents';
        vm.filteredStatus = null;
        vm.selected = [];
        vm.refreshDataFlag = false;
        vm.reloadDataFlag = false;
        vm.drpAgentCode = '';
        vm.agent = {};
        vm.ready = false;
        vm.allAgents = [];
        vm.agentsToShow = [];
        vm.pageSize = 10;
        vm.searchText = null;
        vm.selectedAgent = null;

        vm.addAgent = function ()
        {
            $state.go('adminManageAgentsCreate');
        }

        vm.expireAgent = function (agent) {
            vm.selectedAgent = agent;

            vm.modalTitle = "Expire Agent";
            vm.yesButtonText = "Yes";
            vm.noButtonText = "No";
            vm.expireAgentMessage = "Expiring an agent removes their ability to log in and sell policies. You can always undo this later. Are you sure you want to remove " + agent.name + "?";
            $('#popupExpireAgent').modal('toggle');
        }

        $('#popupSuperUserconfirm').on('hidden.bs.modal', function (e) {
            vm.selectedAgent = null;
        });

        vm.expireAgents = function () {
            if (!vm.selectedAgent) {
                return;
            }

            agentService.expireAgent(vm.selectedAgent.agentCode).then(function (result) {
                if (result) {
                    vm.selectedAgent.status = 'Expired';
                    setAgentsActions(vm.selectedAgent);
                    vm.refreshData();
                }
            });
        };

        /**
        * @description
        * open activate agent cofirm dialogs..
        */
        vm.activateAgent = function (agent) {
            vm.selectedAgent = agent;
            vm.modalTitle = "Reactivate Agent";
            vm.yesButtonText = "Yes";
            vm.noButtonText = "No";
            vm.expireAgentMessage = "Are you sure you want to reactivate " + agent.name + "? This will allow them to log in and sell policies.";
            $('#popupActivateAgent').modal('toggle');
        };

        /**
        * @description
        * activate agent to call apiservice
        */
        vm.confirmReactivateAgents = function () {
            if (!vm.selectedAgent) {
                return;
            }

            agentService.activateAgent(vm.selectedAgent.agentCode).then(function (result) {
                if (result) {
                    vm.selectedAgent.status = 'Active';
                    setAgentsActions(vm.selectedAgent);
                    vm.refreshData();
                }
            });
        };


        /**
        * @description
        * grid implementation - refreshes data locally after removing quotes so the removed quotes get excluded from display
        */
        vm.refreshSelectionToExclude = function (entityIds) {
            var newSelection = [];
            for (var index = 0; index < vm.selected.length; index++) {
                var current = vm.selected[index];
                if (entityIds.indexOf(current) === -1) {
                    newSelection.push(current);
                }
            }
            vm.selected = newSelection;
        };

        vm.loadData = function ()
        {
            var dateSelected = {
                startDate: null,
                endDate: null
            };

            vm.gridConfig.dateSelected = dateSelected;
            var deferredPromise = $q.defer();
            var sortDirection = vm.gridConfig.reverse ? 'desc' : 'asc';

            agentService.loadAllAgents()
                .then(function (agents) {
                        vm.allAgents = agents;

                        // default to showing all agents initially
                        vm.agentsToShow = vm.allAgents;

                        vm.gridConfig.totalRecords = agents ? agents.length : 0;

                        agents.forEach(function (agent) {
                            setAgentsActions(agent);
                        });

                        deferredPromise.resolve(agents);

                    }, function (error) {
                        deferredPromise.reject(error);
                });

            return deferredPromise.promise;
        }

        vm.loadDataOrUpdate = function () {
            var promise = null;

            if (!vm.allAgents || vm.allAgents.length < 1) {
                promise = vm.loadData();
                return promise;
            }
            else {
                return vm.updateDataShown();
            }
        };

        vm.updateDataShown = function () {

            // filter by search value or filter status.
            if (vm.searchText || vm.filteredStatus) {
                vm.agentsToShow = vm.filterAgents(vm.allAgents, vm.searchText, vm.filteredStatus)
            }
            else {
                // no filter, show all agents
                vm.agentsToShow = vm.allAgents;
            }

            // apply sorting
            vm.agentsToShow = vm.sortAgents(
                vm.agentsToShow,
                vm.gridConfig.orderby,
                vm.gridConfig.reverse);


            // show the current page.
            var totalPages = Math.ceil(vm.agentsToShow.length / vm.pageSize) || 1;
            if (vm.gridConfig.currentPage > totalPages) {
                vm.gridConfig.currentPage = totalPages;
            }
            else if (vm.gridConfig.currentPage < 1) {
                vm.gridConfig.currentPage = 1;
            }

            var newAgentsToShow = [];
            var firstRecordIndex = (vm.gridConfig.currentPage - 1) * 10;
            var lastRecordIndex = (vm.gridConfig.currentPage) * 10;

            if (lastRecordIndex > vm.agentsToShow.length) {
                lastRecordIndex = vm.agentsToShow.length;
            }

            for (var i = firstRecordIndex; i < lastRecordIndex; i++){
                newAgentsToShow.push(vm.agentsToShow[i]);
            }

            vm.gridConfig.totalPages = totalPages;
            vm.gridConfig.totalRecords = vm.agentsToShow.length;

            var deferred = $q.defer();

            deferred.resolve(newAgentsToShow);

            return deferred.promise;
        };

        vm.filterAgents = function (agents, searchText, statusFilter) {
            var filteredAgents = [];

            if (!agents || agents.length < 1 || (!searchText && !statusFilter)) {
                return filteredAgents;
            }

            searchText = searchText.replace(/([.+-])/gi, '\\$1');

            var searchRegex = searchText ? new RegExp('.*' + searchText + '.*', 'i') : null;
            var statusRegex = statusFilter ? new RegExp('^' + statusFilter + '$', 'i') : null;

            for (var i = 0; i < vm.allAgents.length; i++) {
                var agent = vm.allAgents[i];
                var searchValueMatch = null;
                var statusFilterMatch = null;

                if (searchRegex) {
                    searchValueMatch = searchRegex.exec(agent.name) || searchRegex.exec(agent.email);
                }
                else {
                    // set the match to true, since we aren't filtering on a search value
                    searchValueMatch = true;
                }

                if (statusRegex) {
                    statusFilterMatch = statusRegex.exec(agent.status);
                }
                else {
                    // set the match to true, since we arent' filtering on status
                    statusFilterMatch = true;
                }

                if (searchValueMatch && statusFilterMatch) {
                    filteredAgents.push(agent);
                }
            }

            return filteredAgents;
        };

        vm.sortAgents = function (agents, sortColumn, reverseSort) {
            if (!agents || agents.length < 1 || !sortColumn) {
                return agents || [];
            }

            var sortedAgents = agents.sort(function (thisAgent, nextAgent) {
                // if there is a string value, return it.
                // if not, return -1 (since an empty value is as low as you can get during compare)
                return thisAgent[sortColumn] ? thisAgent[sortColumn].localeCompare(nextAgent[sortColumn]) : -1;
            });

            if (reverseSort) {
                sortedAgents = sortedAgents.reverse();
            }

            return sortedAgents;
        };

        vm.getCustomFilters = function () {
            return [
                { key: "status", value: vm.filteredStatus }
            ];
        }

        var timeout;

        vm.searchChanged = function () {
            if (vm.agent.isSuperUser) {
                if (vm.timer) {
                    $timeout.cancel(vm.timer);
                }
                vm.timer = $timeout(function () {
                    vm.filterChanged();
                }, 700);
            }
            else {
                vm.filterChanged();
            }
        }

        /**
         * @description
         * grid refresh implementation
         */
        vm.refreshData = function () {
            vm.refreshDataFlag = true;
        };

        /**
         * @description
         * grid implementation to detect change of filter, basically invokes refreshData
         */
        vm.filterChanged = function () {
            vm.refreshData();
        };

        /**
         * @description
         * grid implementation for resetting the filters
         */
        vm.clearFilter = function () {
            vm.searchText = null;
            vm.refreshData();
        };

        /**
        * @description
        * Set custom actions based on status
        */
        function setAgentsActions(agent)
        {
            agent.actions = [];
            if (agent && agent.agentCode &&
                (
                    agent.agentCode.toLowerCase() === vm.drpAgentCode.toLowerCase() ||
                    agent.agentCode.toLowerCase() === vm.agent.agentCode.toLowerCase()
                ))
            {
                return;
            }

            if (agent.status == 'Active') {
                agent.actions.push({ label: "Expire", click: vm.expireAgent, icon: "fa fa-ban", href: "#" });
            }
            else if (agent.status == 'Expired') {
                agent.actions.push({ label: "Reactivate", click: vm.activateAgent, icon: "fa fa-refresh", href: "#" });
            }
        }

        function init()
        {
            portalService.getAgentByInternalId().then(function (agent) {
                vm.agent = agent;
                if (!vm.agent.isSuperUser) {
                    $state.go('dashboard');
                }

                portalService.loadDRPAgentForAgency(vm.agent.agencyCode).then(function (drpAgentCode) {
                    vm.drpAgentCode = drpAgentCode;

                    vm.gridConfig = {
                        noDataMessage: "No agents found",
                        allowMultiSelect: false,
                        hasActions: true,
                        columns: [
                            { header: "Name", binding: "name", href: 'admin/manageAgents/view/{{row.agentCode}}', preferredWidth: '20%' },
                            { header: "Agent ID", binding: "agentCode", preferredWidth: '10%' },
                            { header: "Title", binding: "title", preferredWidth: '10%' },
                            { header: "Email", binding: "email", preferredWidth: '10%' },
                            { header: "Phone Number", binding: "phone", preferredWidth: '10%' },
                            { header: "Status", binding: "status", preferredWidth: '10%' }
                        ],
                        actionList: [
                            { label: "View", icon: "fa fa-eye", href: 'admin/manageAgents/view/{{row.agentCode}}' }
                        ],
                        defaultOrderBy: "name",
                        defaultOrder: false,
                        rowIdentifier: "agentCode",
                        agencyCode: vm.agent.agencyCode,
                        currentPage: 1
                    };

                    vm.ready = true;
                });
            });
        }

        init();
    }
})();