/* global $:false */
(function () {
    'use strict';

    angular
        .module('app.quotes')
        .factory('partnerCustomerService', partnerCustomerService);

    partnerCustomerService.$inject = ['agents', '$http', 'globalSettingsService'];

    function partnerCustomerService(agents, $http, globalSettingsService) {
        var customer = null;

        var service = {
            setCustomer: setCustomer,
            getCustomer: getCustomer,
            mapCustomerToQuote: mapCustomerToQuote,
            getById: getById
        };

        return service;

        function setCustomer(newCustomer) {
            customer = newCustomer;
        }

        function getCustomer() {
            return customer;
        }

        function mapCustomerToQuote(quote) {
            if (customer && customer !== null) {
                quote.firstName = customer.firstName;
                quote.lastName = customer.lastName;
                quote.birthDate = customer.birthDate;
                quote.email = customer.emailAddress;

                if (customer.address) {
                    quote.address1 = customer.address.address1;
                    quote.address2 = customer.address.address2;
                    quote.city = customer.address.city;
                    quote.state = customer.address.stateOrProvince;
                    quote.postalCode = customer.address.postalCode;
                }

                if (customer.phoneNumbers && customer.phoneNumbers.length > 0) {
                    quote.phone = customer.phoneNumbers[0].phoneNumber;
                }
            }
        }

        function getById(id) {
            return agents.getCurrentAgent()
                .then(function (agent) {
                    return $http.get(globalSettingsService.apiBaseUrl() + '/middleearthproxy/agents/' + agent.crmId + '/customer/' + id)
                    .then(getCustomerCompleted)
                    .catch(getCustomerFailed);

                    function getCustomerCompleted(response) {
                        return response.data;
                    }

                    function getCustomerFailed(error) {
                        // TODO: Create logger service
                    }
                });
        }
    }
})();
