(function () {
    'use strict';

    var agentPortal = angular.module('agentPortal');

    /**
     * @ngdoc controller
     * @name rootController
     *
     * # ambassadorLaunchpadController
     *
     * @description
     * root controller is parent controller of all the other controllers and gets injected as top-most
     * controller, provides functions to initialize items that belog on rootScope
     */

    agentPortal.controller('ambassadorLaunchpadController', ['ambassadorInformationSessionStorage', '$stateParams', 'authenticationNavigationService', ambassadorLaunchpadController]);

    function ambassadorLaunchpadController(ambassadorInformationSessionStorage, $stateParams, authenticationNavigationService) {

        var vm = this;

        function init() {
            ambassadorInformationSessionStorage.clearSessionData();
            
            // If an agent code was passed in, save that to the session storage to be used for quoting in the case of an ambassador helping out an agent with the sale
            // this is the case where the agent code wasn't being set correctly when coming in through the ambassador portal
            if ($stateParams.agentCode) {
                ambassadorInformationSessionStorage.setAgentCode($stateParams.agentCode)
            }

            authenticationNavigationService.redirectAmbassadorForAction($stateParams);
        };

        init();
    }

})();

