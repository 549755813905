(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name policiesController
     *
     * # policiesController
     *
     * @description
     * controller for policies listing on policies page
     */
    angular.module('agentPortal')
        .controller('policiesController', ['$q', '$filter', '$stateParams', '$timeout', 'policiesService', 'settings', '$uibModal', 'utilService', 'portalService', 'agentService', policiesController]);

    function policiesController($q, $filter, $stateParams, $timeout, policiesService, settings, $modal, utilService, portalService, agentService) {
        var vm = this;
        vm.ready = false;
        vm.title = 'Policies';

        vm.packageOptions = [];
        vm.dateFilters = [];

        vm.filteredPackageIds = '';
        vm.filteredDate = 'All';
        vm.filteredStatus = '';
        vm.filteredSubmissionChannel = '';

        if ($stateParams.filter != null && $stateParams.filter.length > 0) {
            vm.filteredStatus = $stateParams.filter;
        }

        vm.agents = [];
        vm.refreshDataFlag = false;
        vm.reloadDataFlag = false;

        /**
         * @description
         * initialization, retrieves products, date filters and status lists
         */
        function init() {
            getDateFilters();
            getStatusList();

            portalService.getAgentByInternalId().then(function (agent) {
                vm.agent = agent;

                if (vm.agent.isSuperUser) {
                    agentService.fetchAgents(agent.agencyCode).then(function (results) {
                        if (results) {
                            vm.agents = results;
                        }
                    });
                }

                portalService.loadAgencyProductsForDropdown().then(function (packageOptions) {
                    vm.packageOptions = packageOptions;
                });

                setGridColumns();
            });
        }

        /**
         * @description
         * set columns for grid
         */
        function setGridColumns()
        {
            if (vm.agent.isSuperUser) {
                vm.gridConfig = {
                    noDataMessage: "No policies found",
                    allowMultiSelect: false,
                    hasActions: true,
                    columns: [
                        { header: "Policy Id", binding: "policyNumber", href: 'policies/view/{{row.policyNumber}}', preferredWidth: '10%' },
                        { header: "Primary Traveler", binding: "primaryTravelerName", preferredWidth: '20%' },
                        { header: "Purchase Date", binding: "purchaseDateForDisplay", preferredWidth: '1%' },
                        { header: "Product", binding: "productNameWithSubtitle", preferredWidth: '20%' },
                        { header: "Departure Date", binding: "departureDateForDisplay", preferredWidth: '1%' },
                        { header: "Submission Channel", binding: "submissionChannel", preferredWidth: '1%' },
                        { header: "Agent Name", binding: "agentName", preferredWidth: '10%' },
                        { header: "Status", binding: "status", preferredWidth: '5%' }
                    ],
                    actionList: [
                        { label: "View", icon: "fa fa-eye", href: 'policies/view/{{row.policyNumber}}' }
                    ],
                    defaultOrderBy: "departureDateIso",
                    defaultOrder: false,
                    rowIdentifier: "policyNumber",
                    filter: $filter("policiesFilterPurchaseDate")
                };
            }
            else {
                vm.gridConfig = {
                    noDataMessage: "No policies found",
                    allowMultiSelect: false,
                    hasActions: true,
                    columns: [
                        { header: "Policy Id", binding: "policyNumber", href: 'policies/view/{{row.policyNumber}}', preferredWidth: '10%' },
                        { header: "Primary Traveler", binding: "primaryTravelerName", preferredWidth: '20%' },
                        { header: "Purchase Date", binding: "purchaseDateForDisplay", preferredWidth: '1%' },
                        { header: "Product", binding: "productNameWithSubtitle", preferredWidth: '20%' },
                        { header: "Departure Date", binding: "departureDateForDisplay", preferredWidth: '1%' },
                        { header: "Submission Channel", binding: "submissionChannel", preferredWidth: '1%' },
                        { header: "Status", binding: "status", preferredWidth: '5%' }
                    ],
                    actionList: [
                        { label: "View", icon: "fa fa-eye", href: 'policies/view/{{row.policyNumber}}' }
                    ],
                    defaultOrderBy: "departureDateIso",
                    defaultOrder: false,
                    rowIdentifier: "policyNumber",
                    filter: $filter("policiesFilterPurchaseDate")
                };
            }
            vm.ready = true;
        }

        /**
         * @description
         * gets list of status from the server
         */
        function getStatusList() {
            vm.statusList = policiesService.getStatusList();
        }

        /**
         * @description
         * gets date filters in form of time-ranges
         */
        function getDateFilters() {
            vm.dateFilters = policiesService.getDateFilters();
        }

        /**
         * @description
         * retrieves policies from the server
         */
        vm.loadData = function () {
            var dateRange = utilService.getDateRange(vm.filteredDate);

            var dateSelected;
            if (dateRange != null) {
                dateSelected = {
                    startDate: dateRange.startDate.format('YYYY-MM-DD'),
                    endDate: dateRange.endDate.format('YYYY-MM-DD')
                };
            }
            else {
                dateSelected = {
                    startDate: null,
                    endDate: null
                };
            }

            portalService.getAgentByInternalId().then(function (agent) {
                vm.agent = agent;
            });

            vm.gridConfig.dateSelected = dateSelected;

            var deferredPromise = $q.defer();

            policiesService.loadPolicySummaries(vm.getCustomFilters()[3].value, vm.getCustomFilters()[2].value,
                vm.getCustomFilters()[0].value,
                vm.gridConfig.currentPage, vm.gridConfig.orderby, vm.gridConfig.reverse ? 'desc' : 'asc',
                dateSelected, "", vm.searchText
            ).then(function (results) {
                vm.gridConfig.totalRecords = results.totalRecords || 0;

                if (results.summaries) {
                    for (var i = 0; i < results.summaries.length; i++) {
                        var policy = results.summaries[i];
                        policiesService.setPolicyActions(policy, vm.cancelPolicy);
                    }

                    deferredPromise.resolve(results.summaries);
                    }
                }, function (error) {
                    deferredPromise.reject(error);
                    utilService.showPagingPopupForPage("policy", "policies");
            });

            return deferredPromise.promise;
        };

        /**
         * @description
         * custom filters required on policies grid
         */
        vm.getCustomFilters = function () {
            return [
                { key: "packageId", value: vm.filteredPackageIds },
                { key: "date", value: vm.filteredDate },
                { key: "status", value: vm.filteredStatus },
                { key: "agentCode", value: vm.agent.isSuperUser ? (vm.filteredAgent ? vm.filteredAgent.agentCode : null) : vm.agent.agentCode }
            ];
        };

        /**
         * @description
         * grid implementation - for refreshing the grid's data
         */
        vm.refreshData = function () {
            vm.refreshDataFlag = true;
            vm.loadData();
        };

        /**
         * @description
         * grid implementation - for refreshing the grid's data
         */
        vm.reloadData = function () {
            vm.reloadDataFlag = true;
            vm.loadData();
        };

        /**
         * @description
         * grid implementation - filter-change event handling
         */
        vm.filterChanged = function () {
            vm.refreshData();
        };

        var timeout;
        vm.searchChanged = function () {
            if (vm.agent.isSuperUser) {
                if (vm.timer) {
                    $timeout.cancel(vm.timer);
                }
                vm.timer = $timeout(function () {
                    vm.filterChanged();
                }, 700);
            }
            else {
                vm.filterChanged();
            }
        };

        /**
        * @description
        * grid implementation - reseting filter
        */
        vm.clearFilter = function () {
            vm.searchText = "";
            vm.refreshData();
        };
        

        /**
         * @description
         * grid implementation - cancel Policy
         */
        vm.cancelPolicy = function (actions) {
            var policy = actions.policyNumber;
            policiesService.confirmCancelPolicy(
                actions,
                function (result) {
                    vm.refreshData();
                    vm.reloadData();
                }
            );
        }

        init();
    }
})();