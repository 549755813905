(function () {
    'use strict';

    angular
      .module('components.claims')
      .controller('ClaimsController', ClaimsController);

    ClaimsController.$inject = ['$location', 'claim'];

    function ClaimsController($location, claim) {
        var vm = this;
        vm.configuration = null;

        claim.claimConfigurationSubject.subscribe(function (configuration) {
            this.configuration = configuration;
        }.bind(vm));

        activate();

        function activate() {
            vm.policyNumber = $location.search().policyNumber;
            vm.postalCode = $location.search().postalCode;
            vm.emailAddress = $location.search().emailAddress;
        }
    }

})();
