/**
 * @ngdoc filter
 * @name dollarFilter
 *
 * # dollarFilter
 *
 * @description
 * formats currency in pennies to dollars
 */
(function () {
    'use strict';

    angular.module('agentPortal')
        .filter('dollar', [dollarFilter]);

    function dollarFilter() {
        return function (pennies) {
            if (!pennies || isNaN(pennies)) { return ''; }
            let dollars = (pennies / 100).toFixed(2);
            return '$' + dollars
        };
    }
})();