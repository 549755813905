(function() {
    'use strict';

    /**
     * @ngdoc controller
     * @name sendQuoteController
     *
     * # sendQuoteController
     *
     * @description
     * sends quote information via email to provided email addresses.
     */
    angular.module('agentPortal')
        .controller('sendQuoteController', ['portalService', '$scope', '$uibModalInstance', '$timeout', 'quotesService', 'customersService', 'utilService', 'gatewayEmailService', 'quote', sendQuoteController]);

    function sendQuoteController(portalService, $scope, $modalInstance, $timeout, quotesService, customersService, utilService, gatewayEmailService, quote) {

        $scope.quoteSent = false;
        $scope.agent = {};
        $scope.quoteComment = null;

        /**
         * @description
         * sends quote information via email
         */
        $scope.sendQuote = async function () {
            if (!quote.event) {
                quotesService.emailQuote(quote.quoteNumber, getEmailIds(), $scope.quoteComment)
                    .then(function (response) {
                        $scope.quoteSent = true;
                        showMessage("Thank you, the quote has been sent.");
                    }, function (error) {
                        showMessage("There was a problem sending email. Please try again later.");
                    });
            } else {
                if ($scope.quoteComment) {
                    quote.message = $scope.quoteComment;
                }
                
                try {
                    quote.event.quotes[0].selected = true;
                    await gatewayEmailService.emailQuote(quote);
                    var customIcon = "fa-exclamation-circle fa-icon-medium";
                    utilService.showPopup("Quote Sent", 'Quote number is ' + quote.event.quotes[0].id, customIcon);
                } catch (error) {
                    utilService.showPopup("There was a problem sending email. Please try again later.");
                }
            }

            $scope.close();
        };

        /**
         * @description
         * closes send-quote dialog box, i.e., when user clicks on Close or Cancel button
         */
        $scope.close = function () {
            $modalInstance.dismiss('cancel');
        };

        /**
         * @description
         * initialization... loads primary quote holder's data etc.
         */
        var init = function () {
            if (!quote.event) {
                portalService.getAgentByInternalId().then(function (agent) {
                    $scope.agent = agent;

                    if (quote.travelers == null && quote.quoteNumber) {
                        quotesService.getByNumber(quote.quoteNumber)
                            .then(function (fullQuote) {
                                    quote = fullQuote;
                                    showPopup(fullQuote.travelers);
                                }, function (error) {
                                    utilService.showPopup("Error", "Failed to retrieve quote.");
                            });
                    } else if (quote.travelers != null) {
                        showPopup(quote.travelers);
                    } else {
                        utilService.showPopup("Error", "Failed to retrieve required information for the quote.");
                    }
                });
            } else {
                $scope.quoteData = { 
                    primaryemail: quote.email,
                    agentName: quote.agentName,
                    agentCode: quote.agentCode,
                    showMessage: false,
                    message: "",
                    hideAdditional: true,
                    hideCC: true };
            }
        };

        /**
         * @description
         * shows popup for send quote dialog
         */
        var showPopup = function (travelers) {
            var travelerAccountId = $.grep(travelers, function (traveler) {
                    return traveler.isPrimary == true;
                })[0].travelerAccount;

            var customerResource = customersService.getById(travelerAccountId);
            var cctome = true;

            $scope.quoteData = { ccmeto: cctome, primaryemail: null, email: "", showMessage: false, message: "" };

            customerResource.then(function (customer) {
                $scope.quoteData = { ccmeto: cctome, primaryemail: customer.emailAddress, email: "", showMessage: false, message: "" };
                $timeout(function () { $('input[name="email"]').focus(); }, 100);
            }, function (error) {
                utilService.showPopup("Error", "Failed to retrieve primary traveler information.");
            });
        };

        /**
         * @description
         * returns email IDs by splitting user provided CSV-text by commas
         */
        var getEmailIds = function () {
            var emails = [];

            if ($scope.quoteData.email) {
                emails = emails.concat($scope.quoteData.email.split(','));
            }

            if ($scope.quoteData.ccmeto) {
                emails.push($scope.agent.emailAddress);
            }

            return emails;
        };

        /**
         * @description
         * shows message to the user on the dialog box
         */
        var showMessage = function (message) {
            $scope.quoteData.showMessage = true;
            $scope.quoteData.message = message;
            $timeout(function () { $('button[name="close"]').focus(); }, 100);
        };

        init();
    }
})();