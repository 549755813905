(function(){
    "use strict";

    angular.module( 'agentPortal' )
            .directive( 'productTiles', ProductTilesDirective );

    function ProductTilesDirective() {
        return {
            restrict: 'E',
            scope: {
                customerId: '=',
                cta: '=',
                agentCodePassed: '=',
                agent: '=',
                agentAndState: '='
            },
            templateUrl: 'app/products/productTiles.html',
            replace: false,
            bindToController: true,
            controllerAs: 'vm',
            controller: ProductTilesController
        };
    };

    ProductTilesController.$inject = ['$scope', 'storage', '$window', 'format', 'portalService', 'purchaseNavigationService'];
    function ProductTilesController($scope, storage, $window, format, portalService, purchaseNavigationService) {
        var vm = this;
        $scope.vm = vm;
        vm.packages = [];
        vm.packagesByState = [];
        vm.state = null;
        vm.stateName = null;
        vm.pleaseSelectMessage = "Please select a residence state to see products available in that state."
        vm.productsAvailableInMessage = "";
        vm.agentCode = vm.agentCodePassed;

        var isLoadingPackages = false;

        function getAllPackagesResolveAgent() {
            isLoadingPackages = true;

            return vm.getAllPackagesByAgentCode(vm.agentAndState.agent);
        }

        vm.getAllPackagesByAgentCode = function(agentCode) {
            isLoadingPackages = true;

            return portalService.loadPackagesForAgentByState(agentCode).then(function (response) {
                var packagesByState = response.states;
                vm.packagesByState = packagesByState;
                isLoadingPackages = false;

                vm.showPackagesForState();
            }, function (err) {
                isLoadingPackages = false;
            });
        }

        vm.buyPackage = function(pkg)
        {
            var sessionId = new Date().getTime();
            if (vm.cta)
            {
                vm.cta.sessionId = sessionId;
                $window.sessionStorage.setItem('purchaseData', JSON.stringify(vm.cta));
            }

            purchaseNavigationService.navigateToPurchase(pkg, vm.customerId, null, sessionId);
        }

        vm.showPackagesForState = function () {
            if (!vm.agentAndState.state) {
                return;
            }

            vm.packages = [];

            if (vm.packagesByState) {
                var stateWithPackages;
                for (var i = 0; i < vm.packagesByState.length; i++) {
                    if (vm.packagesByState[i].iso2Code == vm.agentAndState.state) {
                        stateWithPackages = vm.packagesByState[i];
                        break;
                    }
                }

                vm.stateName = stateWithPackages && stateWithPackages.state ? stateWithPackages.state : null;

                vm.packages = stateWithPackages.packages.filter(function (currentPackage) {
                    return portalService.isPackageAvailableInAgentPortal(currentPackage);
                });
                vm.productsAvailableInMessage = "Products available in " + vm.stateName + " for agent with code " + vm.agentAndState.agent + ".";
            }
            else {
                vm.pleaseSelectMessage = "There are no products available for agent with code " + vm.agentAndState.agent + " in " + vm.stateName + ".";
            }
        };

        function scheduleWatches(scope) {
            $scope.$watch(function () { return vm.agentAndState; }, function (newValue, oldValue) {
                if (!newValue || (!newValue.state && !newValue.agent)) {
                    return;
                }

                if (newValue.state && (!oldValue || !oldValue.state || newValue.state != oldValue.state)) {
                    // new state value.
                    if (vm.agentAndState && vm.agentAndState.state && vm.packagesByState) {
                        vm.showPackagesForState();
                    }
                }

                if (newValue.agent && (!oldValue || !oldValue.agent || newValue.agent != oldValue.agent)) {
                    // new agent code value. 
                    //Refresh the packages for a given agent code
                    // If they sent in an empty or null agent code we will default to the logged in agent
                    vm.agentAndState.agent = newValue.agent ? newValue.agent : vm.agent.agentCode;
                    vm.getAllPackagesByAgentCode(vm.agentAndState.agent).then(function () {
                        vm.showPackagesForState();
                    });
                }
            }, true);
        }

        function init() {
            scheduleWatches();
            getAllPackagesResolveAgent(vm.agentAndState.agent);
        }

        init();
    }
}());